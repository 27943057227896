.Page {
    flex: 1;
    width: 100%;

    header {
        display: flex;
        flex-direction: row;
        height: 10%;
        align-items: center;

        .pageName {
            flex-grow: 1;
            display: flex;
            align-items: center;
            flex-direction: row;

            .logo {
                width: 6em;
                height: auto;
            }
            .icon {
                width: 2em;
                height: 2em;
                background-color: $secondary;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-weight: bold;
                border-radius: 0.3em;
                margin-right: 1em;
                font-size: 1.2em;

                img {
                    width: 3em;
                    height: 3em;
                    filter: brightness(10);
                }
            }

            strong {
                font-weight: 600;
                font-size: 1.4em;
            }
        }

        .notifications {
            flex: 0.05;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;

            svg {
                transform: scale(1.2);
            }
            span {
                position: absolute;
                width: 20px;
                height: 20px;
                background-color: $primary;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: -8px;
                margin-top: -15px;
                border-radius: 3em;
                font-size: 0.85em;

                &.min {
                    font-size: 0.65em;
                }
            }
        }
        .searchBar {
            flex: 0.2;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            input {
                border: none;
                padding: 1em;
                background-color: #eee;
                border-radius: 0.4em;
                width: 100%;
            }
        }

        @media all and (max-width: 768px) {
            padding-left: 0;
            .searchBar {
                display: none;
            }

            .pageName {
                flex: 0.9;
            }
        }
    }

    > .infos {
        height: 7%;
        width: 100%;
        display: flex;
        align-items: flex-end;

        .tabs {
            padding: 1em 1em 1em 0em;
            display: flex;
            flex-direction: row;
            list-style: none;
            margin: 0;
            width: 80%;
            @media all and (max-width: 1200px) {
                width: 100%;
            }
            li {
                margin-right: 2em;

                @media all and (max-width: 1450px) {
                    margin-right: 2em;
                }
                @media all and (max-width: 1200px) {
                    margin-right: 1em;
                }
                a {
                    color: $third;
                    text-decoration: none;
                    border-radius: 4px;
                    padding: 4px 14px;
                    transition: 0.3s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    overflow: hidden;

                    span {
                        display: inline-flex;
                        background: rgba($third, 0.6);
                        border-radius: 0.2em;
                        color: #fff;
                        font-size: 0.75em;
                        min-width: 2em;
                        padding: 0.2em 0.3em;
                        box-sizing: border-box;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        margin-left: 0.3em;
                    }

                    &.active {
                        background-color: $third;
                        color: #fff;

                        span {
                            background: #fff;
                            color: $third;
                        }

                        &:hover {
                            background-color: $third;
                            color: white;
                        }
                    }
                    &:hover {
                        background-color: #f1f1f1;
                        color: $third;
                    }
                }
            }

            @media all and (max-width: 991px) {
                li {
                    display: none;

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        display: inline;
                    }
                }
            }

            @media all and (max-width: 991px) {
                li {
                    margin-right: 3em;
                    &:nth-child(4) {
                        display: none;
                    }
                }
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            width: 20%;
            justify-content: flex-end;
        }
    }

    > .content {
        background-color: $lightGrey;
        width: 100%;
        height: 80%;
        border-radius: 0.3em 0.3em 0 0;
        padding: 2em;
        box-sizing: border-box;
        position: relative;
        overflow-y: auto;

        .tabContent {
            h3 {
                margin: 0 0 1em 0;
                font-weight: 400;
                color: rgb(59, 59, 59);
                font-size: 1.5em;
            }
        }
    }

    &.dashboard,
    &.reports,
    &.settings {
        > .content {
            height: 90%;
        }
    }
}

.cgu {
    display: flex;
    flex-direction: column;
    padding: 4em;
    text-align: center;
    width: 75vw;
    margin: auto;
    strong {
        font-size: 2em;
    }
}
