.comments {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    background-color: $lightGrey;
    margin-top: 1em;
    border-radius: 0.3em;
    flex-grow: 1;

    &.empty {
        padding: 2em 0;
    }

    .title {
        display: block;
        text-align: center;
        border-bottom: solid 1px rgb(226, 226, 226);
        padding-bottom: 0.2em;
        margin-bottom: 0.2em;
        color: rgb(192, 192, 192);
    }
    .start_event {
        padding: 0.5em 0;
        font-size: 0.9em;
        border-bottom: solid 1px #ececec;
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: rgb(186, 186, 186);
    }
    .listComments {
        .title {
            padding: 0.5em 0;
        }

        .comment {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0.5em 0;
            font-size: 0.9em;
            border-bottom: solid 1px #ececec;

            &:last-child {
                // border: none;
            }

            .infos {
                width: 10%;
                align-items: center;
                padding: 0;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;

                img {
                    width: 2.5em;
                    height: 2.5em;
                    border-radius: 2.5em;
                }
                span {
                    text-align: center;
                    font-size: 0.9em;
                }
            }
            .content {
                width: 90%;
                padding: 0;
                border: none;
            }

            .content {
                .date {
                    display: block;
                    color: rgb(169, 169, 169);
                    font-size: 0.8em;
                }

                p {
                    color: #646464;
                    margin: 0;
                    padding: 0.2em 2em 0.2em 0;
                }
            }
        }

        .empty {
            text-align: center;
            width: 100%;
            display: block;
            margin: 1em 0;
            color: #d7d7d7;
            font-size: 1.4em;
        }
        @media all and (max-width: 1450px) {
            height: 70%;
        }
    }
    .newCommentContainer {
        height: auto;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 65vw;
        z-index: 999999;

        @media all and (max-width: 991px) {
            width: 100vw;
        }

        .editor .menubar {
            background-color: #fff;
            padding: 0.3em 0;

            .menu_group {
                box-shadow: none;
            }
        }

        .editor .ProseMirror {
            min-height: 4.5em;
            height: 4.5em;
            transition: 0.3s;
            background-color: #fff;
            overflow: hidden;
        }

        &.open {
            .editor .ProseMirror {
                height: 10em;
                overflow-y: auto;
            }
        }

        .editor {
            &:focus-within {
                .ProseMirror {
                    height: 10em;
                }
            }
        }

        .addComment {
            position: absolute;
            bottom: 0.5em;
            right: 0.5em;
            background-color: $primary;
            border: none;
            color: #fff;
            display: flex;
            align-items: center;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8em;
            border-radius: 0.4em;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                transform: scale(1.02);
                background-color: $secondary;
            }

            svg {
                margin-left: 0.4em;
                width: 0.8em;
            }
        }

        @media all and (max-width: 1450px) {
            //height:30%;
        }
    }
}
