@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
:root {
  --primary: $primary;
  --secondary: $secondary;
  --third: $third;
}

.header {
  background-color: #fff;
  width: 100%;
  height: 10vh;
}

.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained).MuiButton-outlined.logout {
  color: #e23832 !important;
  border-color: #e23832 !important;
  border: none !important;
}

.navigation {
  flex: 0.08;
  width: 8vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 2em;
  display: none;
}
@media all and (max-width: 1450px) {
  .navigation {
    flex: 0.1;
    width: 10vw;
  }
}
@media all and (max-width: 991px) {
  .navigation {
    flex: 0.2;
    width: 20vw;
  }
}
.navigation .app_icon {
  max-width: 50%;
  margin-top: 2em;
  margin-bottom: 4em;
}
.navigation .menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navigation .menu a {
  margin: 4em 0;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
}
.navigation .menu a img {
  width: 50px;
  height: 50px;
  transition: 0.3s;
}
.navigation .menu a svg {
  color: #9b9b9b;
  transition: 0.3s;
  font-size: 2.4em;
}
.navigation .menu a span {
  color: #9b9b9b;
}
.navigation .menu a:hover svg {
  color: #575267;
}
.navigation .menu a.active svg {
  color: #322c31;
}
.navigation .menu a.active span {
  color: #322c31;
}
@media all and (max-width: 1450px) {
  .navigation .menu a {
    margin: 2.5em 0;
  }
}
@media all and (max-width: 768px) {
  .navigation {
    width: 5em;
  }
}

.account_actions_menu {
  position: absolute;
  top: 6em;
  right: 2em;
  z-index: 4;
}
.account_actions_menu .logout {
  color: #e23832 !important;
}
.account_actions_menu .used_space {
  background-color: #fff;
  padding: 10px 0;
  border-bottom: solid 1px rgb(247, 247, 247);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.account_actions_menu .used_space span {
  color: #9b9b9b;
}
.account_actions_menu .used_space .space {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.account_actions_menu .used_space .space .progress {
  width: 50%;
  height: 5px;
  background-color: rgb(247, 247, 247);
  border-radius: 5px;
  position: relative;
  margin-right: 10px;
}
.account_actions_menu .used_space .space .progress .progress_bar {
  height: 5px;
  background-color: #e23832;
  border-radius: 5px;
}

.app_icon {
  width: 70px;
  margin-right: 2rem;
  margin-top: 3px;
}

.right_user_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.right_user_actions .settings_link {
  color: rgba(50, 44, 49, 0.5) !important;
  transition: 0.3s;
  margin-right: 30px;
}
.right_user_actions .settings_link:hover {
  color: rgba(50, 44, 49, 0.9) !important;
}

.userConnected {
  position: relative;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0.6em;
  border-radius: 0.3em;
}
.userConnected:hover {
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
}
.userConnected img {
  width: 45px;
  height: 45px;
  border-radius: 3em;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: #9b9b9b;
  margin-right: 0.5em;
}
.userConnected .infos {
  display: flex;
  flex-direction: column;
}
.userConnected .infos span {
  color: #d0d0d0;
  font-weight: 300;
}
.userConnected .moreIcon {
  margin-left: 1em;
  transition: 0.3s;
}
.userConnected.open .moreIcon {
  transform: rotate(180deg);
}

.userInfos {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1em 0.4em;
  background-color: #fafafa;
  margin-top: -0.6em;
  margin-bottom: 0.6em;
}
.userInfos img {
  width: 60px;
  height: 60px;
  border-radius: 3em;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: #9b9b9b;
  margin-right: 0.5em;
}
.userInfos .infos {
  display: flex;
  flex-direction: column;
}
.userInfos .infos strong {
  font-size: 1.1em;
}
.userInfos .infos span {
  color: #d0d0d0;
  font-weight: 400;
}

.add_to_project {
  background-color: #e23832 !important;
  width: 200px;
  margin-left: 40px;
  font-size: 1em !important;
  padding: 5px 0 !important;
}
@media all and (max-width: 1450px) {
  .add_to_project {
    width: 220px;
  }
}
@media all and (max-width: 991px) {
  .add_to_project {
    width: 180px;
    font-size: 0.9em !important;
    padding: 5px 0 !important;
  }
}
.add_to_project.Mui-disabled {
  background-color: #e2e2e2 !important;
}

.add_to_project_min {
  background-color: #e23832 !important;
  color: #fff !important;
  font-size: 0.9em !important;
}
.add_to_project_min.Mui-disabled {
  background-color: #e2e2e2 !important;
}

.only_add .overlay {
  background-color: rgba(50, 44, 49, 0.9) !important;
}

.multi_add {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
.multi_add .multi_add_button {
  display: flex;
  flex-direction: row;
}
.multi_add .multi_add_button .add_to_project {
  margin-left: 15px;
}
.multi_add .multi_add_button .add_to_project.meeting {
  width: 250px;
}
.multi_add .multi_add_button .add_to_project.date {
  width: 270px;
}

.Page {
  flex: 1;
  width: 100%;
}
.Page header {
  display: flex;
  flex-direction: row;
  height: 10%;
  align-items: center;
}
.Page header .pageName {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Page header .pageName .logo {
  width: 6em;
  height: auto;
}
.Page header .pageName .icon {
  width: 2em;
  height: 2em;
  background-color: #322c31;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border-radius: 0.3em;
  margin-right: 1em;
  font-size: 1.2em;
}
.Page header .pageName .icon img {
  width: 3em;
  height: 3em;
  filter: brightness(10);
}
.Page header .pageName strong {
  font-weight: 600;
  font-size: 1.4em;
}
.Page header .notifications {
  flex: 0.05;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.Page header .notifications svg {
  transform: scale(1.2);
}
.Page header .notifications span {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #e23832;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -8px;
  margin-top: -15px;
  border-radius: 3em;
  font-size: 0.85em;
}
.Page header .notifications span.min {
  font-size: 0.65em;
}
.Page header .searchBar {
  flex: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.Page header .searchBar input {
  border: none;
  padding: 1em;
  background-color: #eee;
  border-radius: 0.4em;
  width: 100%;
}
@media all and (max-width: 768px) {
  .Page header {
    padding-left: 0;
  }
  .Page header .searchBar {
    display: none;
  }
  .Page header .pageName {
    flex: 0.9;
  }
}
.Page > .infos {
  height: 7%;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.Page > .infos .tabs {
  padding: 1em 1em 1em 0em;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  width: 80%;
}
@media all and (max-width: 1200px) {
  .Page > .infos .tabs {
    width: 100%;
  }
}
.Page > .infos .tabs li {
  margin-right: 2em;
}
@media all and (max-width: 1450px) {
  .Page > .infos .tabs li {
    margin-right: 2em;
  }
}
@media all and (max-width: 1200px) {
  .Page > .infos .tabs li {
    margin-right: 1em;
  }
}
.Page > .infos .tabs li a {
  color: #575267;
  text-decoration: none;
  border-radius: 4px;
  padding: 4px 14px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.Page > .infos .tabs li a span {
  display: inline-flex;
  background: rgba(87, 82, 103, 0.6);
  border-radius: 0.2em;
  color: #fff;
  font-size: 0.75em;
  min-width: 2em;
  padding: 0.2em 0.3em;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 0.3em;
}
.Page > .infos .tabs li a.active {
  background-color: #575267;
  color: #fff;
}
.Page > .infos .tabs li a.active span {
  background: #fff;
  color: #575267;
}
.Page > .infos .tabs li a.active:hover {
  background-color: #575267;
  color: white;
}
.Page > .infos .tabs li a:hover {
  background-color: #f1f1f1;
  color: #575267;
}
@media all and (max-width: 991px) {
  .Page > .infos .tabs li {
    display: none;
  }
  .Page > .infos .tabs li:nth-child(1), .Page > .infos .tabs li:nth-child(2), .Page > .infos .tabs li:nth-child(3), .Page > .infos .tabs li:nth-child(4) {
    display: inline;
  }
}
@media all and (max-width: 991px) {
  .Page > .infos .tabs li {
    margin-right: 3em;
  }
  .Page > .infos .tabs li:nth-child(4) {
    display: none;
  }
}
.Page > .infos .actions {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-content: flex-end;
}
.Page > .content {
  background-color: rgb(247, 247, 247);
  width: 100%;
  height: 80%;
  border-radius: 0.3em 0.3em 0 0;
  padding: 2em;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
}
.Page > .content .tabContent h3 {
  margin: 0 0 1em 0;
  font-weight: 400;
  color: rgb(59, 59, 59);
  font-size: 1.5em;
}
.Page.dashboard > .content, .Page.reports > .content, .Page.settings > .content {
  height: 90%;
}

.cgu {
  display: flex;
  flex-direction: column;
  padding: 4em;
  text-align: center;
  width: 75vw;
  margin: auto;
}
.cgu strong {
  font-size: 2em;
}

.LightBox {
  position: fixed;
  transition: 0.3s;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
}
.LightBox.open {
  opacity: 1;
  visibility: visible;
}
.LightBox.close {
  opacity: 0;
  visibility: hidden;
}
.LightBox .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: -1;
}
.LightBox .close {
  position: fixed;
  right: 2em;
  top: 2em;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.4em;
}
.LightBox .close img {
  width: 1.5em;
}
.LightBox .nav_button {
  position: fixed;
  top: 45vh;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  border: none;
  color: #000;
  font-size: 1.4em;
  transition: 0.3s;
  background-color: transparent;
  opacity: 0.4;
  cursor: pointer;
}
.LightBox .nav_button img {
  width: 2.5em;
}
.LightBox .nav_button:hover {
  opacity: 1;
}
.LightBox .nav_button.previous {
  left: 2vw;
}
.LightBox .nav_button.next {
  right: 2vw;
}
.LightBox .caption {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2em;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9em;
}
.LightBox .pictureContainer {
  overflow: none;
  max-width: 70vw;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LightBox .pictureContainer img {
  height: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
  transition: 0.3s;
}
.LightBox .pictureContainer img:hover {
  transform: scale(1.005);
}
.LightBox .pictureContainer.zoom {
  overflow: auto;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
}
.LightBox .pictureContainer.zoom img {
  width: auto;
  height: 100%;
  max-width: none;
  max-height: none;
}
.LightBox .pictureContainer.zoom img:hover {
  transition: 0s;
  transform: scale(1);
}
.LightBox img {
  max-width: 70vw;
  max-height: 80vh;
  transition: 0.1s;
}

.gallery {
  width: 100%;
  height: 100%;
  position: relative;
}
.gallery > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: relative;
}
.gallery .gallery_item {
  width: 13em;
  height: 9em;
  margin: 0.2em;
  background-color: rgb(227, 227, 227);
  text-align: center;
  border-radius: 0.3em;
  position: relative;
}
.gallery .gallery_item button {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
  color: #e23832;
  border: none;
  background-color: transparent;
  z-index: 3;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.gallery .gallery_item a {
  position: relative;
  z-index: 1;
  width: 100%;
}
.gallery .gallery_item img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
  will-change: transform;
}

.comments {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
  background-color: rgb(247, 247, 247);
  margin-top: 1em;
  border-radius: 0.3em;
  flex-grow: 1;
}
.comments.empty {
  padding: 2em 0;
}
.comments .title {
  display: block;
  text-align: center;
  border-bottom: solid 1px rgb(226, 226, 226);
  padding-bottom: 0.2em;
  margin-bottom: 0.2em;
  color: rgb(192, 192, 192);
}
.comments .start_event {
  padding: 0.5em 0;
  font-size: 0.9em;
  border-bottom: solid 1px #ececec;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: rgb(186, 186, 186);
}
.comments .listComments .title {
  padding: 0.5em 0;
}
.comments .listComments .comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5em 0;
  font-size: 0.9em;
  border-bottom: solid 1px #ececec;
}
.comments .listComments .comment .infos {
  width: 10%;
  align-items: center;
  padding: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.comments .listComments .comment .infos img {
  width: 2.5em;
  height: 2.5em;
  border-radius: 2.5em;
}
.comments .listComments .comment .infos span {
  text-align: center;
  font-size: 0.9em;
}
.comments .listComments .comment .content {
  width: 90%;
  padding: 0;
  border: none;
}
.comments .listComments .comment .content .date {
  display: block;
  color: rgb(169, 169, 169);
  font-size: 0.8em;
}
.comments .listComments .comment .content p {
  color: #646464;
  margin: 0;
  padding: 0.2em 2em 0.2em 0;
}
.comments .listComments .empty {
  text-align: center;
  width: 100%;
  display: block;
  margin: 1em 0;
  color: #d7d7d7;
  font-size: 1.4em;
}
@media all and (max-width: 1450px) {
  .comments .listComments {
    height: 70%;
  }
}
.comments .newCommentContainer {
  height: auto;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 65vw;
  z-index: 999999;
}
@media all and (max-width: 991px) {
  .comments .newCommentContainer {
    width: 100vw;
  }
}
.comments .newCommentContainer .editor .menubar {
  background-color: #fff;
  padding: 0.3em 0;
}
.comments .newCommentContainer .editor .menubar .menu_group {
  box-shadow: none;
}
.comments .newCommentContainer .editor .ProseMirror {
  min-height: 4.5em;
  height: 4.5em;
  transition: 0.3s;
  background-color: #fff;
  overflow: hidden;
}
.comments .newCommentContainer.open .editor .ProseMirror {
  height: 10em;
  overflow-y: auto;
}
.comments .newCommentContainer .editor:focus-within .ProseMirror {
  height: 10em;
}
.comments .newCommentContainer .addComment {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  background-color: #e23832;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  border-radius: 0.4em;
  cursor: pointer;
  transition: 0.3s;
}
.comments .newCommentContainer .addComment:hover {
  transform: scale(1.02);
  background-color: #322c31;
}
.comments .newCommentContainer .addComment svg {
  margin-left: 0.4em;
  width: 0.8em;
}
.soundPlayer {
  height: auto;
  overflow-y: auto;
}
.soundPlayer .player {
  display: flex;
  flex-direction: row;
  padding: 7px 10px;
  align-items: center;
  border-bottom: solid 1px rgb(247, 247, 247);
}
.soundPlayer .player:last-child {
  border-bottom: none;
}
.soundPlayer .player span {
  margin-bottom: 0.5em;
  width: 35%;
  font-size: 0.9em;
}
.soundPlayer .player audio {
  width: 100%;
}
.soundPlayer .player button {
  cursor: pointer;
  color: #e23832;
  border: none;
  background-color: transparent;
  z-index: 3;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-left: 10px;
}

.event_flux .soundPlayer {
  width: 40%;
}
.event_flux .soundPlayer .player {
  width: 100%;
}

.greeting {
  padding-bottom: 2em;
}
.greeting .row {
  align-items: center;
  font-size: 1.1em;
}
.greeting .row img {
  margin-right: 0.4em;
  width: 3.5em;
  height: 3.5em;
  border-radius: 4em;
}
.greeting .row .col strong {
  color: rgb(72, 72, 72);
}
.greeting .row .col .name {
  align-items: flex-end;
}
.greeting .row .col .name span {
  color: #9b9b9b;
  font-size: 1.3em;
}
.greeting .row .col .name a {
  position: relative;
  bottom: -3px;
  margin-left: 1em;
  color: #9b9b9b;
  transition: 0.3s;
}
.greeting .row .col .name a svg {
  transform: scale(0.8);
}
.greeting .row .col .name a:hover {
  color: rgb(72, 72, 72);
  transform: rotate(45deg);
}

.module {
  background-color: #fff;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
  padding: 1em;
  border-radius: 0.4em;
}
.module .module_header {
  border-bottom: solid 1px rgb(222, 222, 222);
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3em 0;
}
.module .module_header strong {
  width: 60%;
  font-weight: 400;
  color: rgb(80, 80, 80);
  flex-grow: 1;
}
.module .module_header span {
  text-align: right;
  width: 8%;
  display: none;
  color: rgb(190, 190, 190);
  font-size: 0.8em;
}
.module .module_header .add_project_button {
  font-size: 0.8em;
}
.module .module_header .add_project_button svg {
  font-size: 1.6em;
  position: relative;
  left: -0.2em;
  top: -0.15em;
}
.module .module_content .item {
  border-bottom: solid 1px rgb(232, 230, 230);
  padding: 0.4em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
.module .module_content .item .thumb {
  width: 5%;
  max-width: 2em;
  height: 2em;
  background-color: #fccd87;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  border-radius: 0.5em;
  margin-right: 1em;
}
.module .module_content .item .name {
  text-decoration: none;
  width: 70%;
  color: rgb(46, 46, 46);
  font-weight: 500;
}
.module .module_content .item .actions {
  width: 25%;
}
.module .module_content .item .actions button {
  justify-self: flex-end;
  float: right;
  border: solid 2px #575267;
  color: #575267;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
  padding: 0.3em 1em;
  border-radius: 0.3em;
  font-weight: 500;
}
.module .module_content .item .actions button:hover {
  background-color: #575267;
  color: #fff;
}

.start_project {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.start_project img {
  width: 4em;
  height: 4em;
}
.start_project strong {
  font-size: 2em;
  color: #9b9b9b;
  padding: 1em 0;
}

.wizard {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.wizard .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 900px;
  max-width: 100vw;
  padding: 2em;
  box-sizing: border-box;
  height: 50vh;
}
.wizard .content .sayto {
  margin-bottom: 6em;
}
.wizard .content .sayto img {
  width: 150px;
  transition: 0.3s;
  transform: translateY(2em);
  opacity: 0;
  visibility: hidden;
}
.wizard .content .sayto img.animate {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
@media all and (max-width: 1450px) {
  .wizard .content .sayto {
    margin: 0;
    position: absolute;
    left: 5vh;
    top: 5vh;
  }
  .wizard .content .sayto img {
    width: 100px;
  }
}
.wizard .content .steps {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  min-height: 45vh;
}
@media all and (max-width: 1450px) {
  .wizard .content .steps {
    min-height: 50vh;
  }
}
.wizard .content .steps .step {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-2em);
}
.wizard .content .steps .step.active {
  z-index: 2;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.wizard .content .steps .step .description {
  color: #9b9b9b;
  text-align: center;
}
.wizard .content .steps .step .welcome_message {
  display: flex;
  flex-direction: column;
  font-size: 2em;
  text-align: center;
  margin-bottom: 2em;
  transition: 0.3s;
  transform: translateY(2em);
  opacity: 0;
  visibility: hidden;
}
.wizard .content .steps .step .welcome_message.animate {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.wizard .content .steps .step .welcome_message span:first-child {
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 0.5em;
  color: #9b9b9b;
}
.wizard .content .steps .step p {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 2em;
  color: #9b9b9b;
}
.wizard .content .steps .step strong.question {
  font-size: 1.7em;
  text-align: center;
  color: rgb(72, 72, 72);
  display: block;
  font-weight: 400;
}
.wizard .content .steps .step input {
  border: solid 1px #bdbdbd;
  padding: 10px;
  margin: 1em 0;
  border-radius: 0.3em;
  font-size: 1.2em;
  outline: none;
  transition: 0.3s;
}
.wizard .content .steps .step input:hover, .wizard .content .steps .step input:focus {
  border-color: #fccd87 !important;
}
.wizard .content .steps .step input.project_name {
  font-weight: 600;
  width: 100%;
  text-align: center;
  border-color: rgb(247, 247, 247);
}
.wizard .content .steps .step input.project_name.error {
  border-color: #e23832 !important;
}
.wizard .content .steps .step input.project_name::-moz-placeholder {
  color: #cacaca;
  opacity: 1;
  font-weight: 400;
  font-size: 0.8em;
}
.wizard .content .steps .step input.project_name::placeholder {
  color: #cacaca;
  opacity: 1;
  font-weight: 400;
  font-size: 0.8em;
}
.wizard .content .steps .step input.project_name:-ms-input-placeholder {
  color: #cacaca;
}
.wizard .content .steps .step input.project_name::-ms-input-placeholder {
  color: #cacaca;
}
.wizard .content .steps .step .form {
  text-align: center;
}
.wizard .content .steps .step .form button {
  margin: 1.5em 0 0.5em 0;
}
.wizard .content .steps .step .form span {
  font-size: 1.7em;
  text-align: center;
  color: rgb(72, 72, 72);
  display: block;
  font-weight: 400;
}
.wizard .content .steps .step .google_autocomplete {
  margin-top: 2em;
  width: 25em;
  max-width: 100%;
}
.wizard .content .steps .step .google_autocomplete input {
  width: 100%;
  opacity: 1 !important;
}
.wizard .content .steps .step .budget {
  display: flex;
  align-items: center;
}
.wizard .content .steps .step .budget input {
  text-align: right;
}
.wizard .content .steps .step .budget span {
  font-size: 1.4em;
  margin-left: 0.3em;
}
.wizard .content .steps .step .date_project {
  display: flex;
  flex-direction: row;
}
.wizard .content .steps .step .date_project .picker {
  border-radius: 3px;
  padding: 10px;
  display: flex;
  margin: 5px;
  flex-direction: column;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
}
.wizard .content .steps .step .date_project .picker span.question {
  font-size: 1.4em;
  margin-top: 1em;
  display: block;
  text-align: center;
}
.wizard .content .actions {
  flex: inherit;
  height: 4em;
  align-items: center;
  margin-top: 5em;
  border-top: solid 1px rgb(247, 247, 247);
  width: 100%;
  transition: 0.3s;
  transform: translateY(2em);
  opacity: 0;
  visibility: hidden;
  padding-top: 1em;
}
.wizard .content .actions.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.wizard .content .actions .col.col3 {
  flex: 0.25;
  width: 25%;
}
.wizard .content .actions .col.col6 {
  flex: 0.5;
  width: 50%;
}
.wizard .animation_element {
  transition: 0.3s;
  transform: translateY(2em);
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.3s;
}
.wizard .animation_element.animate {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.recharts-label-list {
  font-size: 1.2em !important;
}
.recharts-label-list text {
  stroke: none !important;
}

.pie {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.pieLegend {
  display: flex;
  flex-direction: column;
}
.pieLegend .line {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5em;
}
.pieLegend .line .color {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 10px;
}
.pieLegend .line .value {
  margin-right: 10px;
  font-weight: bold;
}

.table_stats .head, .table_stats .content .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table_stats .head strong, .table_stats .head span, .table_stats .content .row strong, .table_stats .content .row span {
  flex: 0.333;
  padding: 5px;
}
.table_stats .head strong.right, .table_stats .head span.right, .table_stats .content .row strong.right, .table_stats .content .row span.right {
  text-align: right;
}

.filters {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 50px;
}
.filters.title_actions {
  margin-top: 0px;
  margin-bottom: -30px;
  background-color: rgb(247, 247, 247);
  position: relative;
  z-index: 3;
  padding: 8px 0px;
}
.filters.title_actions .switch {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filters.title_actions .switch button {
  border: none;
}
.filters.container {
  margin-top: 5px;
  background: #fff;
  border-radius: 5px;
  padding: 5px 15px;
  height: 65px;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
}
.filters.container .filtersActions .field {
  position: relative;
  height: 42px;
  width: 100%;
}
.filters.container .filtersActions .field label {
  font-size: 0.9em;
  text-align: left;
  top: -33px;
  position: absolute;
  z-index: 1;
  font-weight: bold;
  background: #fff;
  padding: 3px 15px;
  left: -5px;
  border-radius: 5px;
}
.filters.container .filtersActions .search_input {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.filters.container .filtersActions .search_contractors {
  width: 22.5%;
  border-right: solid 1px #ccc;
  padding: 0 15px;
}
.filters.container .filtersActions .search_tags {
  width: 22.5%;
  padding: 0 15px;
}
.filters.container .filtersActions .search_filters {
  width: 190px;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 2px #575267;
  padding: 7px;
  border-radius: 4px;
}
.filters.container .filtersActions .search_filters span.label {
  min-width: 150px;
  color: #575267;
  font-weight: 600;
}
.filters.container .filtersActions .search_filters img {
  width: 1.2em;
  transition: 0.3s;
}
.filters.container .filtersActions .search_filters .amount {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background-color: #e23832;
  color: #fff;
  top: -10px;
  right: -10px;
}
.filters.container .filtersActions .search_filters.active {
  opacity: 1;
}
.filters.with_spacer {
  padding-top: 1em;
}
.filters h3 {
  font-size: 1.5em;
  font-weight: 600 !important;
  margin-right: 1em;
}
.filters .title {
  width: 30%;
  margin: 0 !important;
}
.filters .title.spacer {
  flex-grow: 1;
}
.filters .title.compact {
  width: auto !important;
}
@media all and (max-width: 991px) {
  .filters .title {
    font-size: 1.2em;
  }
}
.filters .shareButton {
  font-size: 0.9em;
  padding: 0.5em 1em;
  color: #e23832;
  cursor: pointer;
  width: 220px;
}
.filters .shareButton svg {
  font-size: 1em;
  margin-right: 0.5em;
  color: #e23832;
}
.filters button.switchBtn {
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  opacity: 0.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 2px #575267;
  padding: 7px;
  border-radius: 4px;
}
.filters button.switchBtn span.label {
  min-width: 125px;
  color: #575267;
}
.filters button.switchBtn img {
  width: 1.5em;
  transition: 0.3s;
}
.filters button.switchBtn .amount {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background-color: #e23832;
  color: #fff;
  top: -10px;
  right: -10px;
}
.filters button.switchBtn.active {
  opacity: 1;
}
.filters .switch {
  width: 150px;
}
.filters .switch button {
  margin-right: 10px;
}
@media all and (max-width: 768px) {
  .filters .switch {
    width: 40%;
  }
}
.filters .spacer {
  width: 70%;
}
.filters .filtersActions {
  width: 100%;
  text-align: right;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
@media all and (max-width: 991px) {
  .filters .filtersActions {
    margin-right: 0px;
  }
}
.filters .filtersActions .contractor_filters {
  width: 100%;
}
.filters .filtersActions .contractor_filters__control {
  width: 100%;
  border: solid 1px rgb(247, 247, 247);
}
.filters .filtersActions .contractor_filters__placeholder {
  font-size: 0.9em;
}
.filters .filtersActions .MuiAutocomplete-root {
  background: #fff;
  border: solid 1px rgb(247, 247, 247);
  border-radius: 5px;
}
.filters .filtersActions .MuiAutocomplete-root .MuiInputBase-root {
  padding: 3px !important;
}
.filters .filtersActions .MuiAutocomplete-root fieldset {
  border-color: rgb(247, 247, 247) !important;
}
.filters .filtersActions .search_event {
  position: relative;
  width: 100%;
  border: none;
  text-align: left;
  background-color: #fff;
  margin-right: 1em;
  outline: none;
  padding: 10px;
  border-right: solid 1px #ccc;
  box-sizing: border-box;
  color: #575267;
  font-weight: 400;
  font-size: 1.1em;
}
.filters .filtersActions .search_event .MuiInputLabel-outlined {
  top: -8px;
}
.filters .filtersActions .search_event .MuiInputLabel-outlined.Mui-focused, .filters .filtersActions .search_event .MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: 0;
}
.filters .filtersActions .search_event input {
  padding: 0.5em !important;
}
.filters .filtersActions .enter_message {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  z-index: 1;
  right: 25px;
  color: #9b9b9b;
  transition: 0.3s;
}
.filters .filtersActions .enter_message button {
  margin-left: 5px;
  transition: 0.2s;
  border: none;
  background-color: #fccd87;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.filters .filtersActions .enter_message button:hover {
  background-color: #575267;
}
.filters .filtersActions .enter_message svg {
  margin: 0 0.3em;
  background: rgb(247, 247, 247);
  padding: 0 0.3em;
  font-size: 1.2em;
}
.filters .filtersActions .enter_message.empty {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.filters .filtersActions .filtersBox {
  position: absolute;
  background: #fff;
  padding: 2em;
  right: 0;
  min-width: 30em;
  min-height: 15em;
  z-index: -1;
  transition: 0.3s;
  opacity: 0;
  top: 3.5em;
  visibility: hidden;
  transform: translateY(1em);
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
  border-radius: 0.3em 0 0.3em 0.3em;
}
.filters .filtersActions .filtersBox.open {
  opacity: 1;
  visibility: visible;
  z-index: 9;
  transform: translateY(0);
}
.filters .filtersActions .filtersBox .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
}
.filters .filtersActions .filtersBox .header h3 {
  width: 70%;
  margin: 0;
}
.filters .filtersActions .filtersBox .header button {
  width: 30%;
  transition: 0.3s;
}
.filters .filtersActions .filtersBox .header button:hover {
  color: #e23832;
}
.filters .filtersActions .filtersBox .clear {
  border: none;
  background: transparent;
  cursor: pointer;
}
.filters .filtersActions .filtersBox h3 {
  text-align: left;
  font-weight: 400;
  color: rgb(171, 171, 171);
  margin-top: 0;
}
.filters .filtersActions .filtersBox .filterLabel {
  display: block;
  text-align: left;
  font-size: 0.9em;
  margin: 0.5em 0 1em 0;
  color: grey;
}
.filters .filtersActions .filtersBox .dateFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filters .filtersActions .filtersBox .dateFilter .picker {
  margin: 0;
}
.filters .filtersActions .filtersBox .dateFilter .picker.right {
  margin-left: 1.2em !important;
}
.filters .filtersActions .filtersBox .type_filter {
  text-align: left;
}
.filters .filtersActions .filtersBox .type_filter .input_select {
  font-size: 0.8em;
}
.filters .filtersActions .filtersBox .select_field {
  width: 100%;
  margin: 0;
}
.filters .filtersActions .filtersBox .select_field label {
  background-color: #fff;
}
.filters .filtersActions .filtersBox .select_field div {
  text-align: left;
  width: 100%;
}
.filters .filtersActions .filtersBox .filterButton {
  margin-top: 1em;
}
.filters .filtersActions button {
  opacity: 1;
}

.Project {
  flex: 1;
  width: 100%;
}
@media all and (max-width: 991px) {
  .Project {
    flex: 0.85;
    width: 85%;
  }
}
.Project header {
  display: flex;
  flex-direction: row;
  height: 80px;
  align-items: center;
  justify-content: space-between;
}
.Project header .project_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}
.Project header .projectName {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: solid 1px rgb(247, 247, 247);
  padding: 0.5em;
  border-radius: 3px;
  width: 80%;
}
.Project header .projectName .thumb {
  width: 2em;
  height: 2em;
  background-color: #fccd87;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  border-radius: 0.3em;
  margin-right: 1em;
  font-size: 1.2em;
}
.Project header .projectName strong {
  font-weight: 600;
  font-size: 1.4em;
  color: #322c31;
  flex-grow: 1;
  cursor: pointer;
}
.Project header .projectName .switch_project_button {
  min-width: 0;
}
.Project header .projectName button {
  padding: 0;
  margin: 0;
}
.Project header .projectName button.need_upgrade {
  opacity: 0.3 !important;
  cursor: default !important;
}
.Project header .projectName button svg {
  margin-top: 7px;
  transition: 0.3s;
  font-size: 1.2em !important;
}
.Project header .projectName button svg:hover {
  color: #e23832 !important;
}
.Project header .projectName .project_header_buttons {
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3em 0;
  margin-left: 1em;
  border-left: solid 1px rgb(247, 247, 247);
}
.Project header .projectName .project_header_buttons button {
  margin: 0 0.5em;
  background-color: rgb(247, 247, 247);
  border-radius: 0.3em;
  width: 35px;
  height: 35px;
  min-width: 0;
  display: flex;
  align-self: center;
  justify-content: center;
}
.Project header .projectName .row button svg {
  margin-top: 0 !important;
  font-size: 1em !important;
}
.Project header .notifications {
  flex: 0.05;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Project header .notifications img {
  width: 50px;
  height: 50px;
}
.Project header .notifications span {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #99201c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -25px;
  margin-top: -20px;
  border-radius: 3em;
  font-size: 0.9em;
}
.Project header .searchBar {
  flex: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.Project header .searchBar input {
  border: none;
  padding: 1em;
  background-color: #eee;
  border-radius: 0.4em;
  width: 100%;
}
@media all and (max-width: 768px) {
  .Project header {
    padding-left: 0;
  }
  .Project header .searchBar {
    display: none;
  }
  .Project header .projectName {
    flex: 0.9;
  }
}
.Project > .infos {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.Project > .infos .tabs {
  padding: 1em 1em 1em 0em;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  width: 100%;
}
@media all and (max-width: 1200px) {
  .Project > .infos .tabs {
    width: 100%;
  }
}
.Project > .infos .tabs li {
  margin-right: 2em;
}
@media all and (max-width: 1450px) {
  .Project > .infos .tabs li {
    margin-right: 2em;
  }
}
@media all and (max-width: 1200px) {
  .Project > .infos .tabs li {
    margin-right: 1em;
  }
}
.Project > .infos .tabs li a {
  color: #575267;
  text-decoration: none;
  border-radius: 4px;
  padding: 4px 14px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.Project > .infos .tabs li a span {
  display: inline-flex;
  background: rgba(87, 82, 103, 0.6);
  border-radius: 0.2em;
  color: #fff;
  font-size: 0.75em;
  min-width: 1.5em;
  padding: 0.1em 0.2em;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 0.6em;
}
.Project > .infos .tabs li a.active {
  background-color: #575267;
  color: #fff;
}
.Project > .infos .tabs li a.active span {
  background: #fff;
  color: #575267;
}
.Project > .infos .tabs li a.active:hover {
  background-color: #575267;
  color: white;
}
.Project > .infos .tabs li a:hover {
  background-color: #f1f1f1;
  color: #575267;
}
@media all and (max-width: 991px) {
  .Project > .infos .tabs {
    width: 100%;
    overflow-x: auto;
  }
  .Project > .infos .tabs li:nth-child(1), .Project > .infos .tabs li:nth-child(2), .Project > .infos .tabs li:nth-child(3), .Project > .infos .tabs li:nth-child(4) {
    display: inline;
  }
}
@media all and (max-width: 991px) {
  .Project > .infos .tabs li {
    margin-right: 0;
  }
  .Project > .infos .tabs li:last-child a {
    width: 11em;
  }
}
.Project > .infos .actions {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-content: flex-end;
}
.Project > .content {
  background-color: rgb(247, 247, 247);
  width: 100%;
  height: 90%;
  border-radius: 0.3em 0.3em 0 0;
  padding: 0em 2em 0 2em;
  box-sizing: border-box;
  position: relative;
}
.Project > .content .calendar {
  position: relative;
}
.Project > .content .calendar .loading {
  width: 100%;
  height: 93%;
  bottom: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.Project > .content .calendar h2 {
  margin: 0 0 1em 0;
  font-weight: 400;
  color: rgb(59, 59, 59);
  font-size: 1.5em;
}
.Project > .content .calendar .fc .fc-toolbar.fc-header-toolbar {
  margin: 0;
}
.Project > .content .tabContent {
  padding: 2em 0;
}
.Project > .content .tabContent h3 {
  margin: 0 0 1em 0;
  font-weight: 400;
  color: rgb(59, 59, 59);
  font-size: 1.5em;
}
.Project > .content .tabContent.files .list {
  height: 80%;
}
.Project > .content .tabContent.files .list ul {
  list-style: none;
  padding: 0.4em;
}
.Project > .content .tabContent.files .list ul li {
  padding: 0.6em;
  border-bottom: solid 1px rgb(231, 231, 231);
  display: flex;
  align-items: center;
}
.Project > .content .tabContent.files .list ul li a {
  color: #e23832;
  width: 80%;
  display: inline-block;
}
.Project > .content .tabContent.files .list ul li .date {
  font-size: 0.9em;
  display: inline-block;
  width: 18%;
  text-align: center;
}
.Project > .content .tabContent.files .list ul li svg {
  color: grey;
}
.Project > .content .tabContent .MuiDataGrid-root {
  border: none;
}
.Project > .content .tabContent .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-columnHeaders {
  border: none;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
}
.Project > .content .tabContent .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader {
  border: none;
}
.Project > .content .tabContent .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:focus {
  outline: none;
  background-color: #f7f7f7;
}
.Project > .content > div {
  width: 100%;
  position: relative;
  height: 100%;
}
.Project > .content .listContainer {
  position: relative;
  height: 100%;
  padding-top: 2em;
}
.Project > .content .gridContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.Project > .content .gridContainer .item {
  width: 23.7%;
  margin-bottom: 2%;
  background: #fff;
  border-radius: 0.6em;
  overflow: hidden;
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
}
.Project > .content .gridContainer .item img {
  width: 100%;
  max-height: 14em;
  -o-object-fit: cover;
     object-fit: cover;
}
.Project > .content .gridContainer .item .content {
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-sizing: border-box;
}
.Project > .content .gridContainer .item .content .topInfos {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 0.3em;
  border-bottom: solid 1px rgb(240, 240, 240);
}
.Project > .content .gridContainer .item .content .topInfos .contributor {
  flex: 0.1;
}
.Project > .content .gridContainer .item .content .topInfos .contributor img {
  width: 100%;
}
.Project > .content .gridContainer .item .content .topInfos .type {
  font-size: 0.9em;
  color: rgb(78, 78, 78);
  flex: 0.5;
}
.Project > .content .gridContainer .item .content .topInfos .type .picture {
  color: #e38d25;
}
.Project > .content .gridContainer .item .content .topInfos .type .date {
  color: #54a140;
}
.Project > .content .gridContainer .item .content .topInfos .type .sound {
  color: #b63320;
}
.Project > .content .gridContainer .item .content .topInfos .type .file {
  color: #9c77b6;
}
.Project > .content .gridContainer .item .content .topInfos .date {
  flex: 0.5;
  font-size: 0.9em;
  color: rgb(184, 184, 184);
  text-align: right;
}
.Project > .content .gridContainer .item .content .mainContent {
  padding: 1em 0;
  box-sizing: border-box;
  cursor: pointer;
}
.Project > .content .gridContainer .item .content .mainContent .sound {
  width: 100%;
}
.Project > .content .gridContainer .item .content .mainContent .sound audio {
  width: 100%;
}
.Project > .content .gridContainer .item .content .mainContent .pictures img {
  border-radius: 0.3em;
}
.Project > .content .gridContainer .item .content .mainContent .pictures.multiple {
  position: relative;
}
.Project > .content .gridContainer .item .content .mainContent .pictures.multiple span {
  position: absolute;
  width: 50%;
  display: flex;
  height: 50%;
  right: 2%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
}
.Project > .content .gridContainer .item .content .mainContent .pictures.multiple img:first-child {
  width: 48%;
  height: 15em;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 1%;
}
.Project > .content .gridContainer .item .content .mainContent .pictures.multiple img:nth-child(2), .Project > .content .gridContainer .item .content .mainContent .pictures.multiple img:nth-child(3) {
  width: 48%;
  height: 48%;
  margin-left: 1%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
}
.Project > .content .gridContainer .item .content .mainContent .pictures.multiple img:nth-child(3) {
  bottom: 2%;
}
.Project > .content .gridContainer .item .content .mainContent .pictures.multiple.count_2 img:nth-child(2) {
  position: relative;
  height: 100%;
}
.Project > .content .gridContainer .item .content .mainContent .files ul {
  list-style: none;
  padding: 0;
}
.Project > .content .gridContainer .item .content .mainContent .files ul li {
  border: solid 1px rgb(215, 215, 215);
  padding: 0.3em;
  border-radius: 0.4em;
  margin: 0.2em 0;
}
.Project > .content .gridContainer .item .content .mainContent .files ul li a {
  color: #322c31;
  font-size: 0.9em;
}
.Project > .content .gridContainer .item .content .mainContent .task_content {
  display: flex;
  align-items: center;
}
.Project > .content .gridContainer .item .content .mainContent .task_content svg {
  color: #9b9b9b;
  font-size: 1.2em !important;
  margin-right: 0.4em;
}
.Project > .content .gridContainer .item .content .mainContent .task_content svg.done {
  color: #a9ce7d;
}
@media all and (max-width: 1450px) {
  .Project > .content .gridContainer .item {
    width: 23.5%;
  }
}
@media all and (max-width: 991px) {
  .Project > .content .gridContainer .item {
    width: 48%;
  }
}
@media all and (max-width: 768px) {
  .Project > .content .gridContainer .item {
    width: 100%;
  }
}
.Project > .content .gridContainer .break {
  flex-basis: 100%;
  height: 0;
}
.Project > .content .gridContainer:before, .Project > .content .gridContainer:after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}
.Project > .content .gridContainer .grid_masonry {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Project > .content .gridContainer .grid_masonry .item {
  width: 11.5%;
  margin: 1% 0.5%;
}
@media all and (max-width: 1920px) {
  .Project > .content .gridContainer .grid_masonry .item {
    width: 15.6666666667%;
  }
}
@media all and (max-width: 1450px) {
  .Project > .content .gridContainer .grid_masonry .item {
    width: 24%;
  }
}
.Project > .content .list {
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  position: relative;
}
.Project > .content .list .loading {
  bottom: 0;
  position: fixed;
  width: 90.5%;
  height: 72%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
}
.Project > .content .list .loading svg {
  color: #9b9b9b;
}
.Project > .content .list .item {
  align-items: center;
  cursor: pointer;
}
.Project > .content .list .item.file {
  cursor: default;
}
.Project > .content .list .item .type {
  font-size: 0.9em;
}
.Project > .content .list .item:last-child {
  margin-bottom: 3em;
}
.Project > .content .list .content {
  display: flex;
  align-items: center;
}
.Project > .content .list .content p {
  font-weight: 500;
}
.Project > .content .list .content .pictures {
  display: flex;
  align-items: center;
}
.Project > .content .list .content .pictures span {
  display: block;
  margin-left: 0.3em;
  font-size: 1.1em;
}
.Project > .content .list .content .pictures img {
  width: 4em !important;
  height: 3em !important;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.3em;
  border: solid 2px #fff;
  position: relative;
}
.Project > .content .list .content .pictures img:nth-child(2) {
  left: -10px;
}
.Project > .content .list .creator {
  align-items: center;
}
.Project > .content .columns {
  height: 3em;
  overflow: hidden;
  border-radius: 0.3em 0.3em 0 0;
  padding-right: 1em;
  box-sizing: border-box;
  font-weight: 500;
  position: relative;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
}
.Project > .content .descriptionProject {
  display: flex;
  flex-direction: row;
}
.Project > .content .descriptionProject .col {
  width: 50%;
}
.Project > .content .descriptionProject .col .infoContent {
  width: 95%;
  margin-bottom: 1em;
}
.Project > .content .descriptionProject .col .infoContent .label {
  font-size: 0.9em;
  color: rgb(77, 77, 77);
  display: block;
  margin-bottom: 0.4em;
}
.Project > .content .descriptionProject .col .infoContent h3 {
  margin: 0;
  font-size: 2.4em;
  font-weight: 500;
}
.Project > .content .descriptionProject .col .aboutProject {
  background: #fff;
  padding: 2em;
  border-radius: 0.2em;
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
}
.Project > .content .descriptionProject .col .aboutProject .title {
  display: flex;
  align-items: center;
}
.Project > .content .descriptionProject .col .aboutProject .title button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin-left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Project > .content .descriptionProject .col .aboutProject .title button svg {
  transform: scale(0.8);
  fill: #e23832;
}
.Project > .content .descriptionProject .col .aboutProject .title > span {
  font-weight: 500;
}
.Project > .content .descriptionProject .col .aboutProject .desc {
  margin-top: 1em;
  color: rgb(78, 78, 78);
  font-size: 0.95em;
  line-height: 1.4em;
  max-height: 15em;
  overflow: auto;
}
.Project > .content .intro {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  width: 600px;
  max-width: 95vw;
}
.Project > .content .intro button {
  margin: 1em auto;
  width: 50%;
}
.Project > .content .intro button.add_to_project_min {
  width: auto;
}
@media all and (max-width: 991px) {
  .Project > .content .listContainer {
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 0.5em;
    height: 100%;
  }
  .Project > .content .listContainer .list,
  .Project > .content .listContainer .columns {
    min-width: 1400px;
  }
}
.Project .emptyProject {
  color: rgb(217, 217, 217);
  text-align: center;
  padding: 2em;
  display: block;
  font-size: 2em;
  font-weight: 300;
}

.sharing_ics {
  background-color: #f7f7f7;
  padding: 0.3em 0.5em;
  display: block;
  border-radius: 0.3em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sharing_ics a {
  color: #e23832;
  margin-left: 0.3em;
  flex-grow: 1;
}
.sharing_ics span {
  color: #575267;
}
.sharing_ics .ics_copied {
  color: #a9ce7d;
  margin-right: 1em;
}
.sharing_ics button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.sharing_ics svg {
  font-size: 1.4em;
  background-color: #fff;
}

.scrollable_container {
  height: 78%;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 1em;
  border-radius: 0.3em;
  background-color: #fff;
}

.avatars {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}
.avatars .itemsLeft {
  margin-left: -10px;
}
.avatars .picture {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  position: relative;
  left: -10px;
  border: solid 3px #fff;
}
.avatars .picture:first-child {
  left: 0;
}

.addEvent_old {
  position: fixed !important;
  bottom: 3em;
  right: 2em;
  z-index: 3;
  width: 50em !important;
  max-width: 90vw;
  height: 3em !important;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
}
.addEvent_old .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 44, 49, 0.9);
  transition: 0.2s;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}
.addEvent_old .buttonAdd {
  display: block;
  width: 70px;
  height: 70px;
  background-color: #e23832;
  border: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  border-radius: 2em;
  color: #fff;
  position: relative;
  z-index: 2;
  transition: 0.3s;
  border: solid 4px #fff;
  cursor: pointer;
}
.addEvent_old .buttonAdd:hover {
  transform: scale(1.02);
}
.addEvent_old .menu {
  background-color: #fff;
  width: 90%;
  border-radius: 30px 0 0 30px;
  box-shadow: -10px 10px 30px -3px rgba(0, 0, 0, 0.2);
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  right: -40px;
  height: 70px;
  transition: 0.3s;
  transform: translateX(1em);
  opacity: 0;
  cursor: pointer;
  visibility: hidden;
  overflow-x: auto;
}
.addEvent_old .menu .group {
  display: flex;
  flex-direction: row;
  border-left: solid 1px #c9c9c9;
  padding: 0 1em;
}
.addEvent_old .menu .group:first-child {
  border-left: none;
}
.addEvent_old .menu button {
  background: transparent;
  margin: 0 0.2em;
  border: none;
  border-radius: 0.3em;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}
.addEvent_old .menu button img {
  width: 50px;
  height: 30px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s;
}
.addEvent_old .menu button span {
  transition: 0.3s;
  color: rgb(75, 75, 75);
}
.addEvent_old .menu button:hover span {
  color: #e23832;
}
.addEvent_old .menu button:hover img {
  transform: scale(1.1);
}
.addEvent_old.open .overlay {
  opacity: 0.8;
  visibility: visible;
}
.addEvent_old.open .buttonAdd {
  transform: rotate(-45deg);
}
.addEvent_old.open .buttonAdd:hover {
  transform: scale(1.02) rotate(-45deg);
}
.addEvent_old.open .menu {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.addEvent {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: 0.3s;
}
.addEvent .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 44, 49, 0.9);
  transition: 0.2s;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}
.addEvent .menu {
  background-color: #fff;
  width: 600px;
  padding: 2em;
  height: 500px;
  max-width: 90%;
  max-height: 90%;
  box-shadow: -10px 10px 30px -3px rgba(0, 0, 0, 0.2);
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.3em;
  transition: 0.3s;
  transform: translateY(1em);
  opacity: 0;
  cursor: pointer;
  visibility: hidden;
  overflow-x: auto;
}
@media all and (max-width: 1450px) {
  .addEvent .menu {
    height: 60%;
  }
}
@media all and (max-width: 991px) {
  .addEvent .menu {
    height: 70%;
    width: 80%;
  }
}
.addEvent .menu .title {
  flex: 0.2;
  align-items: flex-end;
  display: flex;
  background-color: rgb(247, 247, 247);
  padding: 40px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
}
.addEvent .menu .title button {
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
}
.addEvent .menu .title button:hover {
  color: #e23832;
}
.addEvent .menu .title span {
  font-size: 1.2em;
  color: rgb(72, 72, 72);
  font-weight: 400;
  text-align: left;
}
.addEvent .menu .content {
  flex: 0.7;
}
.addEvent .menu .content .group {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #e9e9e9;
  padding: 1em;
  justify-content: center;
}
.addEvent .menu .content .group:last-child {
  border-bottom: none;
}
.addEvent .menu .content button {
  background: transparent;
  margin: 0 0.2em;
  border: none;
  border-radius: 0.3em;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}
.addEvent .menu .content button img {
  width: 90px;
  height: 90px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s;
}
.addEvent .menu .content button span {
  transition: 0.3s;
  font-size: 1.2em;
  color: rgb(75, 75, 75);
}
.addEvent .menu .content button:hover span {
  color: #e23832;
}
.addEvent .menu .content button:hover img {
  transform: scale(1.1);
}
.addEvent.open {
  opacity: 1;
  visibility: visible;
  z-index: 3;
}
.addEvent.open .overlay {
  opacity: 1;
  visibility: visible;
}
.addEvent.open .buttonAdd {
  transform: rotate(-45deg);
}
.addEvent.open .buttonAdd:hover {
  transform: scale(1.02) rotate(-45deg);
}
.addEvent.open .menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.modalEvent {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 9;
  top: 0;
  left: 0;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-2em);
  opacity: 0;
  visibility: hidden;
}
.modalEvent.open {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.modalEvent .overlay {
  position: absolute;
  background-color: rgba(50, 44, 49, 0.2);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modalEvent .closeModal {
  position: absolute;
  top: 0.75em;
  right: 0.75em;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 2em;
}
.modalEvent .closeModal svg {
  transform: scale(0.8);
}
.modalEvent .content {
  position: relative;
  width: 1200px;
  max-width: 90%;
  min-height: 40%;
  padding: 2em 2em 5em 2em;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
}
.modalEvent .content .row .col:first-child {
  margin-right: 0.5em;
}
.modalEvent .content .row .col:last-child {
  margin-left: 0.5em;
}
@media all and (max-width: 1450px) {
  .modalEvent .content .row .col:first-child {
    width: 45%;
    flex: auto;
  }
  .modalEvent .content .row .col:last-child {
    width: 55%;
    flex: auto;
  }
}
.modalEvent .content .row #form-file-upload {
  margin: 10px 0;
}
.modalEvent .content .row.date {
  margin: 15px 0 5px 0;
}
.modalEvent .content .row.date:first-child {
  margin-top: -0.2em;
}
.modalEvent .content .row.date input {
  padding: 0.65em !important;
  font-size: 0.85em;
}
.modalEvent .content .title {
  text-transform: capitalize;
}
.modalEvent .content .title span,
.modalEvent .content .title strong {
  display: block;
}
.modalEvent .content .title span {
  color: grey;
}
.modalEvent .content .title strong {
  color: #e23832;
  font-size: 2em;
}
.modalEvent .content .add_file_button {
  margin-top: 1.25em;
}
.modalEvent .content .MuiAlert-root {
  position: absolute;
  bottom: 2em;
  left: 2em;
}
.modalEvent .content .add_event_button {
  position: absolute;
  bottom: 2em;
  right: 2em;
}
.modalEvent .content .add_event_button svg {
  color: #fff;
  transform: scale(0.6);
}
.modalEvent .content textarea {
  width: 100%;
  min-height: 10em;
  max-width: 100%;
  min-width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border: solid 1px rgb(208, 208, 208);
  border-radius: 0.3em;
  max-height: 30em;
}
.modalEvent .content ul.file_list {
  list-style: none;
  padding: 0;
  max-height: 15em;
  overflow-y: auto;
}
.modalEvent .content ul.file_list span {
  color: rgb(230, 230, 230);
  font-size: 1.2em;
  display: block;
  text-align: center;
}
.modalEvent .content ul.file_list li {
  border-bottom: solid 1px rgb(223, 223, 223);
  padding: 0.5em 0;
  color: rgb(82, 82, 82);
  font-size: 0.9em;
}
.modalEvent .content ul.file_list li button {
  border: none;
  background-color: #fff;
  color: red;
  cursor: pointer;
  float: right;
}

.contributors_content {
  height: 90%;
  overflow: hidden;
}
.contributors_content h3 {
  font-size: 1.5em;
  margin: 0;
  font-weight: 400;
  color: rgb(59, 59, 59);
}
.contributors_content > div {
  margin-top: 1em;
  border-radius: 0.4em 0.4em 0 0;
  overflow: hidden;
  overflow-y: hidden !important;
  background-color: #fff;
  height: 100%;
}
.contributors_content .contributors_list {
  padding: 0;
  list-style: none;
  padding-bottom: 2em;
  width: 100%;
  height: 96%;
  display: block;
  background-color: #fff;
  overflow: auto;
  margin-top: 0;
}
.contributors_content .contributors_list li {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  padding: 0.6em;
  margin: 0.5em 0;
  box-shadow: 4px 10px 30px -20px rgba(0, 0, 0, 0.1);
}
.contributors_content .contributors_list li .ico {
  position: relative;
  width: 2em;
  height: 2em;
  margin-right: 1em;
}
.contributors_content .contributors_list li .ico img {
  border-radius: 3em;
  width: 100%;
}
.contributors_content .contributors_list li .infos {
  width: 80%;
}
.contributors_content .contributors_list li .infos .function {
  color: rgb(164, 164, 164);
  font-style: italic;
  font-size: 0.9em;
  margin-top: 0.4em;
}
.contributors_content .contributors_list li .infos a {
  color: #e23832;
  text-decoration: none;
  transition: 0.3s;
}
.contributors_content .contributors_list li .infos a:hover {
  color: rgb(45, 45, 45);
}
.contributors_content .contributors_list li .actions {
  width: 20%;
  text-align: right;
}
.contributors_content .contributors_list li .actions button {
  margin-left: 0.4em;
}
.contributors_content .contributors_list li .actions button.test {
  color: rgb(173, 173, 173);
  border-color: rgb(183, 183, 183);
}

/** animations */
@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.event_actions_menu {
  position: absolute;
  top: 4em;
  right: 4em;
}
.event_actions_menu .delete_action.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) {
  color: #99201c !important;
}
.event_actions_menu .delete_action.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) .MuiListItemIcon-root {
  color: #99201c !important;
}

.share_content {
  width: 600px;
  box-sizing: border-box;
  padding: 2em;
  max-width: 90vw;
}
.share_content textarea {
  width: 100%;
  min-height: 10em;
  max-width: 100%;
  min-width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border: solid 1px rgb(208, 208, 208);
  border-radius: 0.3em;
  max-height: 30em;
  margin: 2em 0;
}
.share_content .success {
  color: #a9ce7d;
  display: flex;
  flex-direction: column;
  padding: 2em;
  align-items: center;
  justify-content: center;
}
.share_content .success svg {
  transform: scale(3);
  margin-bottom: 1em;
}

.calendar_view.with_filters {
  padding-top: 28px;
}

.fc-button-primary {
  background-color: #575267 !important;
  border-color: #575267 !important;
}

.MuiCalendarPicker-root .MuiButtonBase-root.Mui-selected {
  color: #fff !important;
  background-color: #e23832 !important;
}

.calendar {
  padding-top: 2em;
  height: 85%;
}
.calendar a {
  color: #322c31;
}
.calendar > div {
  height: 100%;
  position: relative;
  z-index: 1;
}
.calendar .event_day {
  border: dashed 2px;
}
.calendar .fc-view-harness {
  background: #fff;
  border-radius: 0.3em;
  padding: 2em;
  overflow-y: auto;
  height: 80%;
}
.calendar .fc-view-harness .fc-view {
  border: none !important;
}
.calendar .fc-view-harness .fc-event {
  cursor: pointer;
}
.calendar .fc-view-harness .fc-daygrid-dot-event {
  background-color: #575267;
}
.calendar .fc-view-harness .fc-daygrid-dot-event .fc-daygrid-event-dot {
  border-color: #575267;
}
.calendar .fc-view-harness .fc-daygrid-dot-event .fc-event-title {
  padding: 0;
}
.calendar .fc-daygrid-more-link {
  border: none !important;
  background-color: rgba(50, 44, 49, 0.2) !important;
  color: #322c31;
  padding: 0.25em !important;
  font-weight: 500;
}
.calendar .fc-event-title {
  font-weight: 400 !important;
  padding: 0.25em !important;
}
.calendar .fc-bg-event,
.calendar .fc-h-event {
  background-color: #322c31;
  border-color: transparent;
}
.calendar .fc-daygrid-day-bg .fc-daygrid-bg-harness {
  height: 50%;
}
.calendar .fc-daygrid-day-bg .fc-daygrid-bg-harness:last-child {
  top: 50%;
}
.calendar .fc-daygrid-day-bg .fc-daygrid-bg-harness:first-child {
  top: 0;
}
.calendar .fc-daygrid-day-bg .fc-daygrid-bg-harness:only-child {
  height: 100%;
}
.calendar .fc-button {
  border: solid 1px #fff !important;
  transition: 0.3s;
  outline: none;
  box-shadow: none !important;
}
.calendar .fc-button:hover {
  opacity: 0.9 !important;
}
.calendar .fc-button.fc-button-active {
  opacity: 1 !important;
}
@media all and (max-width: 768px) {
  .calendar .fc .fc-toolbar-title {
    font-size: 1em;
  }
}

.addContributor {
  position: absolute;
  right: 0;
}

.contributor_modal {
  position: relative;
}
.contributor_modal .modal {
  position: relative;
}
.contributor_modal .modal.open {
  z-index: 99;
}
.contributor_modal .modal .function_field {
  width: 96%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.contributor_modal .modal .function_field .css-b62m3t-container {
  z-index: 2;
}
.contributor_modal .modal .fullWidth {
  width: 98%;
}
.contributor_modal .modal .actions {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 1em 1.5em 0 1.5em;
  box-sizing: border-box;
  height: 3em;
}
.contributor_modal .modal .actions button {
  margin-left: 1em;
}
.contributor_modal .modal .company_field {
  position: relative;
  z-index: 9;
}
.contributor_modal .modal .address_field {
  width: 100%;
  position: relative;
  z-index: 9;
}
.contributor_modal .modal .address_field input {
  padding: 15px;
}

.contractors_box .col {
  position: relative;
}
.contractors_box .col span {
  color: #9b9b9b;
}
.contractors_box .col span.alternative {
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0.35em;
}
.contractors_box .col span.alternative:before, .contractors_box .col span.alternative:after {
  content: " ";
  display: inline-block;
  width: 2em;
  height: 2px;
  background-color: #eee;
  margin: 0 0.5em;
}

.row_color .color_picker_box {
  flex-direction: row !important;
  align-items: center;
  padding: 0 0.5em;
  border: solid 1px #dddddd;
  height: 2.6em;
  border-radius: 0.2em;
}
.row_color .color_picker_box span {
  width: 90%;
  font-size: 0.9em;
  color: rgb(72, 72, 72);
}
.row_color .color_picker_box .select_field {
  width: 10% !important;
  min-width: 0 !important;
  padding-right: 0.3em;
}
.row_color .color_picker_box .colorPickerButton {
  width: 40px;
  height: 25px;
  padding: 0.4em;
  box-sizing: border-box;
  border: none;
  border-radius: 0.2em;
}
.row_color .color_picker_box .colorPickerButton div {
  width: 100%;
  height: 100%;
  border-radius: 0.2em;
}
.row_color .color_picker_box .block-picker {
  position: absolute !important;
  top: 2em;
  left: -4em;
  box-shadow: 0 0 30px -3px rgba(0, 0, 0, 0.2) !important;
}
.row_color .color_picker_box .block-picker > div input {
  display: none;
}

.contractor_thumbnail {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 40px;
  font-weight: 800;
  text-transform: uppercase;
  border: solid 2px #fff;
  position: relative;
}
.contractor_thumbnail img {
  width: 25px;
  height: 25px;
  -o-object-fit: contain;
     object-fit: contain;
}
.contractor_thumbnail.more {
  background-color: #322c31;
  color: #fff;
}
.contractor_thumbnail:nth-child(2) {
  left: -10px;
}
.contractor_thumbnail:nth-child(3) {
  left: -20px;
}
.contractor_thumbnail:nth-child(4) {
  left: -30px;
}

.contractorsFoundModal {
  position: absolute;
  background-color: #fff;
  z-index: 10;
  padding: 1em;
  width: 100%;
  height: 10em;
  overflow-x: hidden;
  top: 3em;
  top: 4em;
  box-sizing: border-box;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.1);
}

.contractorsFound {
  max-height: 20em;
  box-shadow: 0 0 10px -3px rgba(72, 72, 72, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  border-radius: 0.3e;
  z-index: 2;
  top: 6em;
  width: 201% !important;
}

.contractorsFoundModal .contractor,
.contractorsFound .contractor {
  padding: 1em;
  border-bottom: solid 1px rgb(247, 247, 247);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contractorsFoundModal .contractor:last-child,
.contractorsFound .contractor:last-child {
  border: none;
}
.contractorsFoundModal .contractor .contractorInfos,
.contractorsFound .contractor .contractorInfos {
  flex: 1;
}
.contractorsFoundModal .contractor .contractorInfos .row .contractorName,
.contractorsFoundModal .contractor .contractorInfos .row .contractorCompany,
.contractorsFoundModal .contractor .contractorInfos .row .contractorEmail,
.contractorsFound .contractor .contractorInfos .row .contractorName,
.contractorsFound .contractor .contractorInfos .row .contractorCompany,
.contractorsFound .contractor .contractorInfos .row .contractorEmail {
  margin-right: 1em;
  font-weight: 400;
}
.contractorsFoundModal .contractor .contractorInfos .row .contractorCompany,
.contractorsFound .contractor .contractorInfos .row .contractorCompany {
  color: #e23832;
  font-weight: 600;
}
.contractorsFoundModal .contractor .contractorInfos .row .contractorAddress,
.contractorsFound .contractor .contractorInfos .row .contractorAddress {
  color: #9b9b9b;
}
.contractorsFoundModal .contractor .contractorInfos .row .contractorEmail,
.contractorsFound .contractor .contractorInfos .row .contractorEmail {
  color: #9b9b9b;
}

.eventDetailsContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  background-color: rgba(50, 44, 49, 0.9) !important;
}
.eventDetailsContainer .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.eventDetailsContainer .eventDetails {
  border-left: solid 4px #eee;
  width: 65%;
  height: 100%;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  opacity: 0;
  transition: 0.2s;
  transform: translateX(50%);
}
.eventDetailsContainer .eventDetails .details_header {
  position: absolute;
  z-index: 9;
  background-color: #fff;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-bottom: solid 2px #eee;
  height: 8vh;
}
.eventDetailsContainer .eventDetails .details_header .details_infos {
  display: flex;
  align-items: center;
  width: 70%;
}
.eventDetailsContainer .eventDetails .details_header .details_infos span {
  color: rgb(201, 201, 201);
}
.eventDetailsContainer .eventDetails .details_header .details_infos h2 {
  margin: 0;
  color: rgb(61, 61, 61);
}
.eventDetailsContainer .eventDetails .details_header .details_infos input {
  width: 100%;
  display: inline-block;
  padding: 0.5em;
  border: solid 1px rgb(221, 221, 221);
  border-radius: 0.3em;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions {
  width: 30%;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions {
  font-size: 0.7em;
  padding: 0.2em 0.5em !important;
  transition: 0.2s;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions svg {
  font-size: 1.5em !important;
  margin-right: 0.3em;
  transition: 0.2s;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions span {
  color: rgba(50, 44, 49, 0.9) !important;
  transition: 0.2s;
  height: 1.6em;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions:hover, .eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions.done {
  background-color: rgba(169, 206, 125, 0.1) !important;
  border-color: rgba(169, 206, 125, 0.9) !important;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions:hover span,
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions:hover svg, .eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions.done span,
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .task_actions.done svg {
  color: #a9ce7d !important;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions {
  height: 2em;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2em;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item strong {
  font-size: 0.9em;
  font-weight: 400;
  margin-right: 0.4em;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .select_field {
  margin: 0;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .colorPickerButton {
  width: 40px;
  height: 20px;
  border: solid 1px rgb(203, 203, 203);
  border-radius: 0.2em;
  cursor: pointer;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .colorPickerButton div {
  height: 30px;
  height: 13px;
  border-radius: 0.2em;
  background-color: #000;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .block-picker {
  position: absolute !important;
  top: 2em;
  left: -4em;
  box-shadow: 0 0 30px -3px rgba(0, 0, 0, 0.2) !important;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .block-picker > div input {
  display: none;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .action .status {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .action .status .label {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 1px rgb(247, 247, 247);
  padding: 0.3em 1em;
  margin-right: 1em;
  border-radius: 0.4em;
  color: #9b9b9b;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .action .status .label span {
  margin-left: 0.4em;
  color: #9b9b9b;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .action .status.confirmed .label {
  border-color: #a9ce7d;
  color: #a9ce7d;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .action .status.confirmed .label span {
  color: #a9ce7d;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .action .status.cancelled .label {
  border-color: #99201c;
  color: #99201c;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .action .status.cancelled .label span {
  color: #99201c;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .row .col .item .action .status button {
  font-size: 0.9em !important;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .file_actions .textField,
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .textField {
  margin: 0;
  border: none;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .file_actions .textField .icon,
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .textField .icon {
  width: 15%;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .file_actions .react-select__control,
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .react-select__control {
  height: 35px;
  min-height: 0;
  border-radius: 0.3em;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .file_actions .react-select__control .react-select__single-value,
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .react-select__control .react-select__single-value {
  margin-top: -5px;
}
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .file_actions .react-select__control .react-select__placeholder,
.eventDetailsContainer .eventDetails .details_header .details_infos .actions .date_actions .react-select__control .react-select__placeholder {
  font-size: 0.8em;
}
.eventDetailsContainer .eventDetails .details_header .details_actions {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.eventDetailsContainer .eventDetails .details_header .details_actions .share_action,
.eventDetailsContainer .eventDetails .details_header .details_actions .save_action {
  margin-right: 2em;
  position: relative;
  padding: 8px !important;
  font-size: 0.8em !important;
  background-color: #e23832 !important;
}
.eventDetailsContainer .eventDetails .details_header .details_actions .share_action svg,
.eventDetailsContainer .eventDetails .details_header .details_actions .save_action svg {
  font-size: 1.2em !important;
  margin-right: 0.2em;
}
.eventDetailsContainer .eventDetails .details_header .details_actions .share_action span,
.eventDetailsContainer .eventDetails .details_header .details_actions .save_action span {
  height: 1.6em;
}
@media all and (max-width: 1450px) {
  .eventDetailsContainer .eventDetails .details_header .details_actions .share_action span,
  .eventDetailsContainer .eventDetails .details_header .details_actions .save_action span {
    width: 100%;
    overflow: hidden;
  }
}
.eventDetailsContainer .eventDetails .details_header .details_actions .save_action {
  background-color: #575267 !important;
}
.eventDetailsContainer .eventDetails .details_header .details_actions .closeButton {
  margin-left: -1em;
  background: transparent;
  border: none;
  cursor: pointer;
}
.eventDetailsContainer .eventDetails .details_header .details_actions .closeButton img {
  width: 2.5em;
}
@media all and (max-width: 991px) {
  .eventDetailsContainer .eventDetails .details_header {
    width: 100vw;
    padding: 0.2em 1em;
  }
}
.eventDetailsContainer .eventDetails .infos {
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 8vh;
  height: 95vh;
  overflow-y: auto;
}
.eventDetailsContainer .eventDetails .infos .creation_action {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px rgb(234, 234, 234);
}
.eventDetailsContainer .eventDetails .infos .creation {
  flex: 1;
  color: rgb(76, 76, 76);
  font-weight: 500;
  margin: 1em 0 0 0;
}
.eventDetailsContainer .eventDetails .infos .add_container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1em;
}
.eventDetailsContainer .eventDetails .infos .add_container button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
  margin: 2px;
  border-radius: 2px;
}
.eventDetailsContainer .eventDetails .infos .add_container button:hover {
  background: rgb(242, 242, 242);
}
.eventDetailsContainer .eventDetails .infos .add_container button:hover svg {
  color: #e23832;
}
.eventDetailsContainer .eventDetails .infos .date {
  font-weight: 500;
}
.eventDetailsContainer .eventDetails .infos .input_title {
  font-size: 1.7em;
  border-radius: 0.2em;
  padding: 0.4em 0;
  font-weight: 600;
  border: solid 1px transparent;
  outline: none;
  transition: 0.3s;
  margin: 0.3em 0;
}
.eventDetailsContainer .eventDetails .infos .input_title:hover {
  border-color: #9b9b9b;
  padding: 0.4em;
}
.eventDetailsContainer .eventDetails .infos .input_title.edit {
  border-color: rgb(72, 72, 72);
  padding: 0.4em;
}
.eventDetailsContainer .eventDetails .infos .contributors {
  margin: 0;
  list-style: none;
  padding: 0;
}
.eventDetailsContainer .eventDetails .infos .contributors li {
  margin: 0.5em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;
}
.eventDetailsContainer .eventDetails .infos .contributors li:focus-within {
  z-index: 2;
}
.eventDetailsContainer .eventDetails .infos .contributors li strong {
  display: block;
  width: 20%;
  font-weight: 400;
  font-size: 0.9em;
}
.eventDetailsContainer .eventDetails .infos .contributors li .action {
  width: 80%;
}
.eventDetailsContainer .eventDetails .infos .contributors li .action .css-bpeome-MuiSvgIcon-root-MuiSelect-icon,
.eventDetailsContainer .eventDetails .infos .contributors li .action .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  right: 0;
}
.eventDetailsContainer .eventDetails .infos .contributors li .action .select,
.eventDetailsContainer .eventDetails .infos .contributors li .action .select_field {
  width: 100%;
  position: relative;
  z-index: 2;
}
.eventDetailsContainer .eventDetails .infos .contributors li .action .colorPicker {
  position: absolute !important;
  z-index: 99;
  box-shadow: 0 0 26px -3px rgba(0, 0, 0, 0.2) !important;
  top: -4em;
  left: 4em;
}
.eventDetailsContainer .eventDetails .infos .contributors li .action .colorPicker > div:first-child {
  top: 30% !important;
  left: -8% !important;
  transform: rotate(-90deg);
  margin-left: 0 !important;
}
.eventDetailsContainer .eventDetails .infos .contributors li .action .colorPicker span {
  margin-left: 0;
}
.eventDetailsContainer .eventDetails .infos .contributors li span {
  margin-left: 0.3em;
}
.eventDetailsContainer .eventDetails .infos .contributors li img {
  width: 3em;
  height: 3em;
}
.eventDetailsContainer .eventDetails .infos .row_color .color_picker_box .colorPickerButton {
  position: relative;
  right: 10px;
}
.eventDetailsContainer .eventDetails .infos .row_color .color_picker_box .block-picker {
  left: -5.4em;
  top: 2.4em;
  z-index: 4;
}
.eventDetailsContainer .eventDetails .input_title {
  position: relative;
  z-index: 2;
}
.eventDetailsContainer .eventDetails .event_general_details {
  position: relative;
  z-index: 4;
}
.eventDetailsContainer .eventDetails .event_general_details:hover {
  z-index: 3;
}
.eventDetailsContainer .eventDetails .event_general_details > .description {
  padding: 1em;
  background-color: rgb(247, 247, 247);
  margin-bottom: 2em;
  max-height: 15em;
  overflow: auto;
}
.eventDetailsContainer .eventDetails .event_general_details .row {
  position: relative;
  z-index: 1;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col:first-child {
  width: 60%;
  flex: 0.6;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col:first-child .editor {
  margin-top: -2em;
  margin-bottom: 2em;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col:nth-child(2) {
  width: 40%;
  flex: 0.4;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .description {
  border: solid 1px rgb(247, 247, 247);
  padding: 0.2em;
  min-height: 7em;
  transition: 0.3s;
  border-radius: 0.3em;
  cursor: pointer;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .description p,
.eventDetailsContainer .eventDetails .event_general_details .row .col .description span {
  margin: 1em;
  color: rgb(72, 72, 72);
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .description span {
  display: inline-block;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .description:hover {
  background-color: rgb(247, 247, 247);
  padding: 0.2em;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
  border-bottom: solid 1px rgb(247, 247, 247);
  padding-bottom: 1em;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item:last-child {
  border-bottom: none;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item strong {
  font-size: 0.9em;
  font-weight: 400;
  width: 30%;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action {
  width: 70%;
  transition: 0.3s;
  padding: 0.2em 0;
  border-radius: 0.3em;
  cursor: pointer;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .MuiChip-filled {
  background-color: rgba(87, 82, 103, 0.3);
  color: #575267;
  margin-right: 3px;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .select_tags div {
  border: none;
  background-color: rgb(247, 247, 247);
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .select_tags div span {
  background-color: #fff;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .select_tags div .rti--input {
  font-size: 0.9em;
  background-color: transparent;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .select_tags div .rti--tag {
  background-color: rgba(87, 82, 103, 0.3);
  color: #575267;
  border-radius: 0.3em;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .select_tags div .rti--tag span {
  background: transparent !important;
  color: #575267;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .empty_contributors {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .empty_contributors span {
  color: #9b9b9b;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .empty_contributors a {
  text-align: right;
  color: #575267;
  text-decoration: none;
  font-size: 0.8em;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .empty_contributors a button {
  transform: scale(0.85);
  font-size: 1em !important;
  padding: 0.2em 0.5em !important;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item .action .empty_contributors a button svg {
  font-size: 1.2em !important;
}
.eventDetailsContainer .eventDetails .event_general_details .row .col .item:hover .action {
  background-color: rgb(247, 247, 247);
}
.eventDetailsContainer .eventDetails .eventDate {
  border: solid 1px rgb(219, 219, 219);
  border-radius: 0.3em;
  padding: 1em;
  position: relative;
  /** Color Picker */
}
.eventDetailsContainer .eventDetails .eventDate .event_details {
  border-bottom: solid 1px rgb(229, 229, 229);
  text-align: right;
  padding-bottom: 0.3em;
  margin-bottom: 0.3em;
}
.eventDetailsContainer .eventDetails .eventDate .event_details .row {
  align-items: center;
  justify-content: flex-end;
}
.eventDetailsContainer .eventDetails .eventDate .event_details strong {
  font-weight: 400;
  font-size: 0.9em;
  color: grey;
}
.eventDetailsContainer .eventDetails .eventDate .actions {
  position: absolute;
  right: 1em;
  bottom: 1em;
}
.eventDetailsContainer .eventDetails .eventDate .actions button {
  margin-left: 0.5em;
  background-color: transparent;
  border-radius: 0.3em;
}
.eventDetailsContainer .eventDetails .eventDate strong {
  font-size: 1.2em;
  color: #444;
}
.eventDetailsContainer .eventDetails .eventDate .row {
  margin-bottom: 1em;
}
.eventDetailsContainer .eventDetails .eventDate label {
  font-weight: 400;
  font-size: 0.9em;
  margin-bottom: 0.4em;
}
.eventDetailsContainer .eventDetails .eventDate .date {
  font-size: 2em;
  color: #222;
}
.eventDetailsContainer .eventDetails .eventDate .meeting {
  display: flex;
  align-items: center;
}
.eventDetailsContainer .eventDetails .eventDate .meeting svg {
  margin: 0 10px;
}
.eventDetailsContainer .eventDetails .eventDate p {
  font-weight: 400;
  color: #444;
}
@media all and (max-width: 991px) {
  .eventDetailsContainer .eventDetails .eventDate .actions {
    position: relative;
    margin-bottom: 0.4em;
    text-align: center;
  }
}
.eventDetailsContainer .eventDetails .content,
.eventDetailsContainer .eventDetails .medias {
  padding-top: 1em;
  position: relative;
  z-index: 2;
}
.eventDetailsContainer .eventDetails .content .fileList span,
.eventDetailsContainer .eventDetails .medias .fileList span {
  display: block;
  color: grey;
  font-size: 0.9em;
}
.eventDetailsContainer .eventDetails .content .fileList a,
.eventDetailsContainer .eventDetails .medias .fileList a {
  color: #e23832;
  margin: 0.3em 0;
  display: block;
}
.eventDetailsContainer .eventDetails .medias {
  z-index: 1;
}
.eventDetailsContainer .eventDetails .content strong,
.eventDetailsContainer .eventDetails .content h3,
.eventDetailsContainer .eventDetails .description strong,
.eventDetailsContainer .eventDetails .description h3 {
  font-weight: 500;
}
.eventDetailsContainer .eventDetails .content ul li p,
.eventDetailsContainer .eventDetails .description ul li p {
  margin: 0.1em 0;
}
.eventDetailsContainer .eventDetails .medias .tabs .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  margin-bottom: 1em;
}
.eventDetailsContainer .eventDetails .medias .tabs .header .tab_title {
  color: #575267;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 10px;
  border-radius: 4px;
  margin-right: 30px;
  cursor: pointer;
}
.eventDetailsContainer .eventDetails .medias .tabs .header .tab_title:hover {
  background-color: rgb(247, 247, 247);
}
.eventDetailsContainer .eventDetails .medias .tabs .header .tab_title .count {
  display: flex;
  padding: 4px;
  background-color: #575267;
  color: #fff;
  width: 20px;
  height: 15px;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.eventDetailsContainer .eventDetails .medias .tabs .header .tab_title.active {
  background-color: #575267;
  color: #fff;
}
.eventDetailsContainer .eventDetails .medias .tabs .header .tab_title.active .count {
  background-color: #fff;
  color: #575267;
}
.eventDetailsContainer .eventDetails .medias .tabs .header .tab_title.active:hover {
  background-color: #575267;
}
.eventDetailsContainer .eventDetails .medias .tabs .content .item {
  position: relative;
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.eventDetailsContainer .eventDetails .medias .tabs .content .item.active {
  visibility: visible;
  opacity: 1;
  height: auto;
}
.eventDetailsContainer .eventDetails .medias .items {
  border: solid 1px rgb(247, 247, 247);
  border-radius: 4px;
  margin-bottom: 10px;
  max-height: 50vh;
  overflow: auto;
}
.eventDetailsContainer .eventDetails .medias .items .emptyMessage {
  padding: 10px;
  font-size: 1.2em;
}
.eventDetailsContainer .eventDetails .medias .items .file {
  padding: 10px 5px;
  padding: 7px 10px;
  border-bottom: solid 1px rgb(247, 247, 247);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.eventDetailsContainer .eventDetails .medias .items .file:last-child {
  border-bottom: none;
}
.eventDetailsContainer .eventDetails .medias .items .file a {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
}
.eventDetailsContainer .eventDetails .medias .items .file a svg {
  margin-right: 10px;
  color: rgb(72, 72, 72);
}
.eventDetailsContainer .eventDetails .medias .items .file button {
  cursor: pointer;
  background: transparent;
  border: none;
  transition: 0.3s;
  color: #e23832;
}
.eventDetailsContainer .eventDetails .medias .items .file button:hover {
  color: #e23832;
}
.eventDetailsContainer .eventDetails .content .item .items div {
  display: flex;
  flex-direction: row;
}
.eventDetailsContainer .eventDetails .content .item .items div a {
  margin: 1px;
}
@media all and (max-width: 1450px) {
  .eventDetailsContainer .eventDetails,
  .eventDetailsContainer .details_header {
    width: 65vw !important;
  }
}
@media all and (max-width: 991px) {
  .eventDetailsContainer .eventDetails,
  .eventDetailsContainer .details_header {
    width: 100% !important;
  }
}

.event_menu_title {
  text-align: center;
  color: rgb(185, 185, 185);
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  border-bottom: solid 1px rgb(240, 240, 240);
  padding: 0 0.5em 0.5em 1em;
}
.event_menu_title svg {
  margin-right: 0.4em;
}

.clip-content {
  position: relative;
  width: 180px;
  height: 120px;
  display: flex;
}
.clip-content .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.clip-content .overlay svg {
  font-size: 4em;
  transition: 0.15s;
}
.clip-content .overlay:hover svg {
  font-size: 4.4em;
}
.clip-content .clip-thumbnail {
  position: relative;
  background-color: #eee;
  border-radius: 5px;
  z-index: 1;
}
.clip-content .clip-thumbnail:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.clip-content .clip-thumbnail::after {
  content: "";
  display: block;
}
.clip-content button {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
  color: #e23832;
  border: none;
  background-color: transparent;
  z-index: 3;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.flow {
  padding-top: 2em;
}

.scrollhandler {
  height: 77vh;
  overflow: auto;
  padding-top: 1em;
  padding-right: 2em;
  width: 100.3%;
  padding-bottom: 0;
  position: relative;
}
@media all and (max-width: 1450px) {
  .scrollhandler {
    height: 64vh;
  }
}
.scrollhandler .loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9;
}

.event_flux {
  padding-top: 2em;
}
.event_flux > div {
  margin-bottom: 3em;
}
.event_flux > div:last-child {
  margin-bottom: 6em;
}
.event_flux .day_line {
  font-size: 1.5em;
  color: #575267;
  display: block;
  margin-bottom: 0.4em;
}
.event_flux .day_line.hide {
  opacity: 0;
  margin: 0;
  height: 0;
}
.event_flux .event_flux_item {
  background: #fff;
  padding: 5px 25px 10px 60px;
  box-sizing: border-box;
  border-radius: 0.3em;
  margin-bottom: 0.5em;
  cursor: pointer;
  transition: 0.3s;
}
.event_flux .event_flux_item:hover {
  box-shadow: 0px 3px 20px -8px rgba(0, 0, 0, 0.2);
}
.event_flux .event_flux_item .event_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px rgb(247, 247, 247);
  padding-bottom: 1px;
  margin-bottom: 5px;
}
.event_flux .event_flux_item .event_header .type > div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #000;
  font-size: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.event_flux .event_flux_item .event_header .type > div svg {
  transform: scale(0.8);
}
.event_flux .event_flux_item .event_header .type.video > div {
  background-color: #e23832;
}
.event_flux .event_flux_item .event_header .type.text > div {
  background-color: #322c31;
}
.event_flux .event_flux_item .event_header .type.picture > div {
  background-color: #575267;
}
.event_flux .event_flux_item .event_header .type.file > div {
  background-color: #fccd87;
}
.event_flux .event_flux_item .event_header .type.sound > div {
  background-color: #99201c;
}
.event_flux .event_flux_item .event_header .type.date > div {
  background-color: #a9ce7d;
}
.event_flux .event_flux_item .event_header .type.task > div {
  background-color: #7bb9b3;
}
.event_flux .event_flux_item .event_header .type.mail > div {
  background-color: #9b9b9b;
}
.event_flux .event_flux_item .event_header .event_action {
  flex-grow: 1;
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.event_flux .event_flux_item .event_header .event_action span {
  margin-left: 0.2em;
}
.event_flux .event_flux_item .event_header .event_action .title {
  font-weight: 600;
  font-size: 1rem;
}
.event_flux .event_flux_item .event_header .file_type {
  width: 15%;
  font-size: 0.9em;
  text-align: left;
  position: relative;
  top: 1px;
  margin-left: 0.5em;
}
.event_flux .event_flux_item .event_header .file_type span {
  display: inline-block;
  padding: 0.3em;
  border-radius: 0.3em;
  box-sizing: border-box;
}
.event_flux .event_flux_item .event_header .file_type span:first-child {
  text-align: center;
  width: 5.5em;
}
.event_flux .event_flux_item .event_header .file_type span:last-child {
  font-weight: 500;
  color: #575267;
  text-align: right;
}
.event_flux .event_flux_item .event_header .file_type.estimate span:first-child {
  background-color: #7bb9b3;
  color: #322c31;
}
.event_flux .event_flux_item .event_header .file_type.invoice span:first-child {
  background-color: rgba(226, 56, 50, 0.3);
  color: #99201c;
}
.event_flux .event_flux_item .event_header .event_created {
  color: black;
  flex: auto;
  font-size: 0.9em;
  font-weight: bold;
  background-color: rgb(247, 247, 247);
  padding: 2px 5px;
  border-radius: 0.3em;
  flex: 0.03;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.4em;
  margin: 0;
  position: relative;
  top: 1px;
}
.event_flux .event_flux_item .event_header .event_created.mail {
  flex: 0.18;
  margin-left: 5px;
}
.event_flux .event_flux_item .event_header .event_created.mail span {
  margin: 0 3px;
}
.event_flux .event_flux_item .event_header .tags {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
@media all and (max-width: 991px) {
  .event_flux .event_flux_item .event_header .tags {
    width: 13%;
  }
}
.event_flux .event_flux_item .event_header .tags .MuiChip-root {
  margin: 0 0.1em;
}
.event_flux .event_flux_item .event_header .tags .MuiChip-filled {
  background-color: rgba(0, 0, 0, 0.08);
  color: #575267;
}
.event_flux .event_flux_item .event_header .tags .tagsLeft {
  margin-left: 5px;
  position: relative;
  left: -2px;
  color: #575267;
}
.event_flux .event_flux_item .event_header .tags .no_tag {
  display: block;
  color: #9b9b9b;
  font-size: 0.8em;
  text-align: right;
  margin-top: -10px;
}
@media all and (max-width: 991px) {
  .event_flux .event_flux_item .event_header .tags .no_tag {
    font-size: 0.6em;
  }
}
.event_flux .event_flux_item .event_header .contributors {
  width: 10%;
  justify-content: flex-end;
}
@media all and (max-width: 991px) {
  .event_flux .event_flux_item .event_header .contributors {
    width: 13%;
  }
}
.event_flux .event_flux_item .event_header .contributors .avatars {
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 10px;
}
.event_flux .event_flux_item .event_header .contributors .avatars.l4 {
  left: 30px;
}
.event_flux .event_flux_item .event_header .contributors .avatars.l3 {
  left: 20px;
}
.event_flux .event_flux_item .event_header .contributors .avatars.l2 {
  left: 10px;
}
.event_flux .event_flux_item .event_header .contributors .avatars span {
  position: relative;
  left: 0;
}
.event_flux .event_flux_item .event_header .contributors .avatars .contractor_thumbnail {
  width: 25px;
  height: 25px;
  font-size: 0.7em;
}
.event_flux .event_flux_item .event_header .contributors .no_contractor {
  display: block;
  color: #9b9b9b;
  font-size: 0.8em;
  text-align: right;
}
@media all and (max-width: 991px) {
  .event_flux .event_flux_item .event_header .contributors .no_contractor {
    font-size: 0.6em;
  }
}
.event_flux .event_flux_item .event_header .comments {
  width: 10%;
  text-align: right;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0;
}
@media all and (max-width: 991px) {
  .event_flux .event_flux_item .event_header .comments {
    width: 13%;
  }
}
.event_flux .event_flux_item .event_header .comments svg {
  margin-left: 0.1em;
  width: 15px;
}
.event_flux .event_flux_item .event_header .comments span {
  font-size: 0.8em;
  font-weight: 500;
  margin-top: -2px;
}
@media all and (max-width: 991px) {
  .event_flux .event_flux_item .event_header .comments span {
    font-size: 0.6em;
  }
}
.event_flux .event_flux_item .event_header .comments.empty {
  color: #9b9b9b;
}
.event_flux .event_flux_item .event_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 83%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.3em;
  flex-grow: 1;
}
.event_flux .event_flux_item .event_content > div {
  display: flex;
  align-items: center;
}
@media all and (max-width: 1450px) {
  .event_flux .event_flux_item .event_content {
    width: 70%;
  }
}
.event_flux .event_flux_item .event_content a {
  display: inline-block;
  padding: 0.1em 0.3em;
  font-size: 0.8em;
  color: #e23832;
  margin-right: 0.3em;
}
.event_flux .event_flux_item .event_content img {
  width: 180px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.3em;
  display: inline-block;
  margin-right: 0.3em;
}
.event_flux .event_flux_item .event_content span {
  background-color: rgb(247, 247, 247);
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: bold;
}
.event_flux .event_flux_item .event_content .main_content {
  width: 80%;
}
.event_flux .event_flux_item .event_content .main_content .title {
  font-weight: 500;
  flex-grow: 1;
}
.event_flux .event_flux_item .event_content .main_content .description p {
  margin: 0;
}
.event_flux .event_flux_item .event_content .main_content .files {
  margin-top: 5px;
  color: #9b9b9b;
}
.event_flux .event_flux_item .event_header .type,
.event_flux .event_flux_item .event_content .comments {
  margin-left: -42px;
  margin-right: 15px;
}
.event_flux .event_flux_item .event_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.event_flux .event_flux_item .event_footer .content {
  width: 83%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.3em;
  flex-grow: 1;
}
.event_flux .event_flux_item .event_footer .content > div {
  display: flex;
  align-items: center;
}
@media all and (max-width: 1450px) {
  .event_flux .event_flux_item .event_footer .content {
    width: 70%;
  }
}
.event_flux .event_flux_item .event_footer .content a {
  display: inline-block;
  padding: 0.1em 0.3em;
  font-size: 0.8em;
  color: #e23832;
  margin-right: 0.3em;
}
.event_flux .event_flux_item .event_footer .content img {
  width: 180px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.3em;
  display: inline-block;
  margin-right: 0.3em;
}
.event_flux .event_flux_item .event_footer .content span {
  background-color: rgb(247, 247, 247);
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: bold;
}
.event_flux .event_flux_item .medias {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}
.event_flux .event_flux_item .medias span {
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(72, 72, 72);
  background-color: rgb(247, 247, 247);
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: bold;
}
.event_flux .event_flux_item .medias span svg {
  font-size: 1.4em;
  margin-right: 3px;
}

.media_item {
  border-bottom: solid 1px #e1e1e1;
  background: #fff;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.media_item:nth-of-type(odd) {
  background: #fafafa;
}
.media_item .type {
  margin-left: 15px;
  margin-right: 15px;
}
.media_item .type > div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #000;
  font-size: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.media_item .type > div svg {
  transform: scale(0.8);
}
.media_item .type.video > div {
  background-color: #e23832;
}
.media_item .type.picture > div {
  background-color: #575267;
}
.media_item .type.sound > div {
  background-color: #99201c;
}
.media_item .date {
  color: black;
  font-size: 0.9em;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 0.3em;
  width: 100px;
}
.media_item .tags {
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.media_item .tags .MuiChip-root {
  margin: 0 0.1em;
}
.media_item .tags .MuiChip-filled {
  background-color: rgba(87, 82, 103, 0.3);
  color: #575267;
  border-radius: 0.3em;
}
.media_item .tags .tagsLeft {
  margin-left: 5px;
  position: relative;
  left: -2px;
  font-size: 0.9em;
  color: #575267;
}
.media_item .tags .no_tag {
  display: block;
  color: #9b9b9b;
  font-size: 0.8em;
  text-align: right;
  margin-top: -10px;
}
.media_item .title {
  display: flex;
  width: 30%;
}
.media_item .title .main_content {
  width: 80%;
}
.media_item .title .main_content .title {
  font-weight: 500;
  flex-grow: 1;
}
.media_item .title .main_content .description p {
  margin: 0;
}
.media_item .content {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.3em;
  flex-grow: 1;
}
@media all and (max-width: 1450px) {
  .media_item .content {
    width: 70%;
  }
}
.media_item .content a {
  display: inline-block;
  padding: 0.1em 0.3em;
  font-size: 0.8em;
  color: #e23832;
  margin-right: 0.3em;
}
.media_item .content img {
  width: 80px;
  height: 51px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.3em;
  display: inline-block;
  margin-right: 0.3em;
}
.media_item .content span {
  color: #9b9b9b;
}

.listMedia a {
  text-decoration: none;
}
.listMedia .listMedia_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.listMedia .listMedia_item span {
  margin-left: 0.5em;
  text-decoration: none;
}

.mail .event_flux {
  height: 80%;
  overflow-y: auto;
  padding-top: 2.5em;
}

.ValidationDateStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.ValidationDateStatus .content {
  flex: 0.33;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ValidationDateStatus .content .box {
  width: 70%;
  min-height: 50%;
  box-sizing: border-box;
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
  padding: 2em;
  box-sizing: border-box;
}
.ValidationDateStatus .content .box .messageValidation {
  font-size: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ValidationDateStatus .content .box .messageValidation svg {
  font-size: 2.3em;
  margin-bottom: 0.2em;
}
.ValidationDateStatus .content .box .messageValidation.confirmed {
  color: #a9ce7d;
}
.ValidationDateStatus .content .box .messageValidation.cancelled {
  color: #99201c;
}
.ValidationDateStatus .content .box .event {
  background-color: #FFF;
  margin-top: 1em;
  border-radius: 0.3em;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.ValidationDateStatus .content .box .event .title {
  font-size: 1.4em;
  font-weight: 100;
  color: #d7d7d7;
  margin-bottom: 1em;
  display: block;
}
.ValidationDateStatus .content .box .event .event__date {
  text-align: left;
}
.ValidationDateStatus .content .box .event .event__date > div {
  display: flex;
  align-items: center;
  margin: 0.5em 0;
  padding: 0.5em 0;
  border-bottom: solid 1px rgb(247, 247, 247);
}
.ValidationDateStatus .content .box .event .event__date > div:last-child {
  border: none;
}
.ValidationDateStatus .content .box .event .event__date > div .icon {
  width: 4em;
}
.ValidationDateStatus .about {
  background-color: #fff7e9;
  padding: 2em;
  box-sizing: border-box;
  flex: 0.66;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  line-height: 1.6em;
}
.ValidationDateStatus .about img {
  width: 10em;
  margin: 0 0 2em 0;
}
.ValidationDateStatus .about .goTo {
  margin-top: 2em;
}

.login_container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}
.login_container .form {
  width: 40%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_container .form .content {
  width: 70%;
  min-height: 50%;
  padding: 2em;
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
}
.login_container .form .content .password_strength {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.login_container .form .content .password_strength .label {
  font-size: 0.9em;
  color: #9b9b9b;
}
.login_container .form .content .password_strength .label.weak {
  color: #e23832;
}
.login_container .form .content .password_strength .label.medium {
  color: rgb(255, 162, 0);
}
.login_container .form .content .password_strength .label.strong {
  color: rgb(63, 117, 39);
}
.login_container .form .content .password_strength .bar {
  width: 90px;
  margin-right: 5px;
}
.login_container .form .content .password_strength .bar .strength {
  width: 100%;
  height: 0.5em;
  border-radius: 0.5em;
  background-color: rgb(247, 247, 247);
  position: relative;
  transition: 0.3s;
  margin-right: 1em;
  color: rgb(247, 247, 247);
}
.login_container .form .content .password_strength .bar .strength::after {
  content: "";
  display: block;
  width: 0%;
  height: 100%;
  border-radius: 0.5em;
  background-color: #e23832;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
}
.login_container .form .content .password_strength .bar .strength.weak::after {
  width: 33%;
  background-color: #e23832;
}
.login_container .form .content .password_strength .bar .strength.medium::after {
  width: 66%;
  background-color: rgb(255, 191, 0);
}
.login_container .form .content .password_strength .bar .strength.strong::after {
  width: 100%;
  background-color: rgb(79, 191, 31);
}
.login_container .form .content .switch_account_action {
  text-align: center;
  margin-bottom: 2em;
}
.login_container .form .content .switch_account_action strong {
  display: block;
  font-size: 1.8em;
  margin-bottom: 0.3em;
  font-weight: 600;
  color: rgb(37, 37, 37);
}
.login_container .form .content .switch_account_action span,
.login_container .form .content .switch_account_action a {
  margin-top: 1em;
  font-size: 1.5em;
  display: block;
}
.login_container .form .content .switch_account_action span i,
.login_container .form .content .switch_account_action a i {
  font-weight: bold;
  font-style: normal;
}
.login_container .form .content .switch_account_action a {
  color: #e23832;
  margin-left: 12px;
}
.login_container .form .content .switch_account_action.success svg {
  fill: rgb(79, 191, 31);
  font-size: 5em;
  margin-top: 1em;
  margin-bottom: 0.2em;
}
.login_container .form .content .fields {
  margin: 0.4em 10%;
}
.login_container .form .content .fields fieldset {
  border-color: rgb(238, 238, 238);
  border-width: 3px;
}
.login_container .form .content .fields.center {
  text-align: center;
}
.login_container .form .content .actions {
  padding: 0em 0 2em 0;
}
.login_container .form .content .actions a {
  color: rgb(87, 82, 103);
  font-size: 0.9em;
  float: right;
  text-align: left;
  transition: 0.3s;
}
.login_container .form .content .actions a:hover {
  color: #e23832;
}
.login_container .form .content .actions button {
  margin-top: 1em;
}
.login_container .form .content .actions button svg {
  color: #fff;
  transform: scale(0.4);
}
.login_container .form .content .actions button.Mui-disabled svg {
  color: #322c31;
}
.login_container .form .content .actions .error {
  margin-top: 1em;
}
.login_container .form .content .connect_with {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(119, 119, 119);
  font-size: 0.9em;
  font-weight: 500;
}
.login_container .form .content .connect_with::after, .login_container .form .content .connect_with::before {
  content: "";
  display: block;
  width: 4em;
  height: 2px;
  background-color: rgb(218, 218, 218);
  margin: 1em;
}
.login_container .form .content .oauth_connect {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  transition: 0.3s;
}
.login_container .form .content .oauth_connect span {
  color: #9b9b9b;
  margin-right: 0.5em;
}
.login_container .form .content .oauth_connect .login_button {
  cursor: pointer;
  margin: 0 1em;
  background-color: #eee;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login_container .form .content .oauth_connect .login_button.twitter {
  color: #1da1f2;
}
.login_container .form .content .oauth_connect .login_button.google {
  background-color: #db4437;
  color: #fff;
}
.login_container .form .content .oauth_connect .login_button.facebook {
  background-color: #3b5998;
  color: #fff;
}
.login_container .form .content .oauth_connect .login_button.apple {
  background-color: #222;
  color: #fff;
}
.login_container .form .content .oauth_connect .login_button svg {
  transition: 0.3s;
  transform: scale(1.4);
}
.login_container .form .content .oauth_connect .login_button:hover svg {
  opacity: 0.8;
}
.login_container .presentation {
  width: 60%;
  height: 100%;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  position: relative;
  display: flex;
  align-items: center;
}
.login_container .presentation:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
}
.login_container .presentation img {
  width: 15em;
  position: relative;
  z-index: 2;
  margin-top: 3em;
}
@media all and (max-width: 991px) {
  .login_container {
    flex-direction: column-reverse;
  }
  .login_container .presentation {
    width: 100%;
    height: 30%;
    display: none;
  }
  .login_container .presentation img {
    max-width: 10em;
  }
  .login_container .form {
    width: 100%;
    height: 100%;
  }
  .login_container .form img {
    max-width: 60%;
  }
}

.cgu_accept {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  margin-top: 1em !important;
}
.cgu_accept span {
  font-size: 1em;
  color: rgb(72, 72, 72);
}
.cgu_accept a {
  font-size: 0.8em;
  color: #e23832;
  margin-left: -0.8em;
}

.actions_validate_form {
  display: flex;
  flex-direction: row;
}
.actions_validate_form .textField {
  margin: 1em 0 0 0 !important;
}
.actions_validate_form button svg {
  margin: 0 !important;
  fill: rgb(55, 55, 55) !important;
  position: absolute;
  right: 0;
  top: -0.3em;
  z-index: 2;
}
.actions_validate_form .validate_btn {
  width: 150px;
  margin-left: 10px;
}
@media all and (max-width: 991px) {
  .actions_validate_form {
    flex-direction: column;
  }
  .actions_validate_form .textField {
    margin: 20px 0 !important;
  }
  .actions_validate_form .validate_btn {
    width: 100%;
    margin: 0;
  }
}

.need_more {
  background-color: #f1f1f1;
  border-radius: 0.3em;
  margin-left: 1em;
  padding: 4px 8px;
  font-size: 0.7em;
  position: relative;
}
.need_more span.need_upgrade_label {
  background-color: #99201c;
  color: white;
  padding: 0em 0.5em;
  border-radius: 0.3em;
  font-size: 0.7em;
  position: relative;
}

span.need_upgrade_label {
  background-color: #99201c;
  color: white;
  position: absolute;
  top: -20px;
  right: -10px;
  padding: 0em 0.5em;
  border-radius: 0.3em;
  font-size: 0.7em;
}

.subscription .row .title {
  color: rgb(72, 72, 72);
  font-size: 1.6em;
  font-weight: 500;
}
.subscription .row .title span.actual_plan_label {
  margin-left: 0.5em;
  background-color: #e23832;
  padding: 0em 1em;
  font-size: 0.8em;
  border-radius: 0.3em;
  color: #fff;
  height: 1.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.subscription .row.row_actual_plan {
  margin-bottom: 0em;
}
.subscription .row.row_selector_title {
  align-items: center;
}
.subscription .row.row_selector_title .title {
  font-size: 2em;
  color: rgb(72, 72, 72);
  flex-grow: 1;
}
.subscription .row.row_selector_title .title span {
  color: #e23832;
}
.subscription .row.row_selector_title .toggle button {
  background-color: rgb(244, 244, 244);
  color: #e23832 !important;
  border: solid 2px #e23832;
  border-radius: 0;
  padding: 0.3em 1em;
}
.subscription .row.row_selector_title .toggle button:first-child {
  border-radius: 2em 0 0 2em;
}
.subscription .row.row_selector_title .toggle button:last-child {
  border-radius: 0 2em 2em 0;
}
.subscription .row.row_selector_title .toggle button.active {
  background-color: #e23832 !important;
  color: #fff !important;
}
.subscription .row .plan_selector .plan {
  border-radius: 1em;
  overflow: hidden;
}
.subscription .row .plan_selector .plan span.plan_not_selected,
.subscription .row .plan_selector .plan span.actual_selected {
  display: flex;
  padding: 0.3em;
  height: 2em;
}
.subscription .row .plan_selector .plan span.actual_selected {
  align-items: center;
  justify-content: center;
  background-color: #e23832;
  color: #fff;
}
.subscription .row .plan_selector .plan .content {
  padding: 1em;
  text-align: center;
}
.subscription .row .plan_selector .plan .content strong {
  font-weight: 600;
  font-size: 2em;
}
.subscription .row .plan_selector .plan .content span.price {
  margin-top: 1em;
  font-size: 1.5em;
  font-weight: bold;
  display: block;
  color: #e23832;
}
.subscription .row .plan_selector .plan .content span.storage {
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 4px;
}
.subscription .row .plan_selector .plan .content ul.advantages {
  list-style: none;
  padding: 0;
}
.subscription .row .plan_selector .plan .content ul.advantages li {
  padding: 0.5em 0;
  color: rgb(84, 84, 84);
  height: 1.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription .row .plan_selector .plan .content ul.advantages li.reduction {
  color: #e23832;
}
.subscription .row .plan_selector .plan .content ul.advantages li span.disabled {
  color: rgb(193, 193, 193);
}
.subscription .row .plan_selector .plan .content .select_button:not(.Mui-disabled) {
  color: #e23832 !important;
}

.checkout {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  background-color: rgba(50, 44, 49, 0.9) !important;
}
.checkout .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.checkout .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.checkout .loading svg {
  color: #b9b9b9;
}
.checkout .form {
  border-left: solid 4px #eee;
  width: 40vw;
  height: 100vh;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  opacity: 0;
  transition: 0.2s;
  transform: translateX(50%);
  padding: 6%;
  text-align: right;
}
.checkout .form h3 {
  font-size: 2.4em;
  margin: 0.4em 0;
}
.checkout .form span.price {
  font-size: 1.4em;
}
.checkout .form .contentForm {
  min-height: 300px;
  margin: 2em 0;
}

.cancel_subscription strong,
.cancel_subscription span {
  display: block;
}
.cancel_subscription strong {
  font-size: 1.4em;
}
.cancel_subscription span {
  margin: 1em 0;
  color: #9b9b9b;
}

.paymentInfo {
  padding: 2em;
  text-align: center;
  background-color: #FFF;
}
.paymentInfo.success h2 {
  color: #a9ce7d;
}
.paymentInfo.cancel h2 {
  color: #99201c;
}

.payments .columns .header {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #eee;
}
.payments .columns .header .date {
  width: 15%;
  text-align: center;
}
.payments .columns .header .description {
  width: 40%;
}
.payments .columns .header .amount {
  width: 20%;
}
.payments .columns .header .actions {
  text-align: center;
  width: 10%;
}
.payments .list .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0;
  border-bottom: solid 1px #eee;
}
.payments .list .item .date {
  width: 15%;
  text-align: center;
}
.payments .list .item .description {
  width: 40%;
}
.payments .list .item .amount {
  width: 20%;
}
.payments .list .item .actions {
  width: 10%;
  text-align: center;
}
.payments .list .item .actions a {
  margin-right: 0;
}

.Page.account header .pageName .avatar {
  margin-top: 1em;
  width: 5em;
  height: 5em;
  border-radius: 3em;
  overflow: hidden;
  background-color: rgb(247, 247, 247);
}
.Page.account header .pageName .avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.Page.account header .pageName .infos {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
}
.Page.account header .pageName .infos strong {
  color: #322c31;
  font-weight: 500;
}
.Page.account header .pageName .infos .plan {
  opacity: 0.8;
  margin-top: 0.4em;
  background-color: #e23832;
  color: #fff;
  width: 6em;
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 0.2em;
  border-radius: 0.2em;
  text-align: center;
}

.settings_card {
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03) !important;
  margin-bottom: 0.5em;
  overflow: initial !important;
}
.settings_card .MuiCardHeader-content {
  border-bottom: solid 1px #f0f0f0;
}
.settings_card .MuiCardHeader-content span {
  font-size: 1.2em !important;
}
.settings_card strong.title {
  border-bottom: solid 1px rgb(247, 247, 247) !important;
  margin-bottom: 0.4em;
  padding-bottom: 0.2em;
  color: #9b9b9b;
  font-weight: 400;
}
.settings_card .settings_actions {
  padding: 1em !important;
}
.settings_card .settings_actions button {
  margin: 0px 0px 0px auto;
}
.settings_card .delete_content {
  display: flex;
}
.settings_card .delete_content .delete_button {
  color: #e23832;
  border: none;
  background: transparent;
  margin: 0 0 0 auto;
  display: inherit;
  cursor: pointer;
  transition: 0.3s;
}
.settings_card .delete_content .delete_button:hover {
  color: #99201c;
}
.settings_card .delete_content .delete_button:visited {
  color: #99201c;
}

.lock_provider {
  color: #9b9b9b;
  font-style: italic;
  font-size: 0.8em;
  padding: 0 0.3em;
}

.transferEmail {
  background-color: rgb(247, 247, 247);
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: 0.2px;
}
.transferEmail a {
  color: #e23832;
}
.transferEmail a:visited {
  color: #e23832;
}

/** validate */
.validate_store {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.validate_store a {
  margin: 0 !important;
}
.validate_store img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.upgradeDialog {
  text-align: center;
}

.modal {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 9;
  top: 0;
  left: 0;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-2em);
  opacity: 0;
  visibility: hidden;
}
.modal.open {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.modal.hidden {
  z-index: -1;
}
.modal .overlay {
  position: absolute;
  background-color: rgba(50, 44, 49, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal .closeModal {
  position: absolute;
  top: 0.75em;
  right: 0.75em;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 2em;
}
.modal .closeModal svg {
  transform: scale(0.8);
}
.modal .content {
  position: relative;
  width: 1200px;
  max-width: 90%;
  min-height: 40%;
  padding: 2em 2em 5em 2em;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
}
.modal .content .title span,
.modal .content .title strong {
  display: block;
}
.modal .content .title span {
  color: grey;
}
.modal .content .title strong {
  color: #e23832;
  font-size: 2em;
}
.modal .content .row.sep {
  margin: 1em 0;
}
.modal .content .row .col > div,
.modal .content .row .col input {
  width: 100%;
}
.modal .content .add_file_button {
  float: right;
  margin-top: -2em;
}
.modal .content .MuiAlert-root {
  position: absolute;
  bottom: 2em;
  left: 2em;
}
.modal .content .action_button {
  position: absolute;
  bottom: 2em;
  right: 2em;
  padding: 5px 20px;
}
.modal .content .action_button span {
  width: 25px !important;
  height: 25px !important;
}
.modal .content .action_button svg {
  color: #fff;
  transform: scale(0.6);
}
.modal .content textarea {
  width: 100%;
  min-height: 10em;
  max-width: 100%;
  min-width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border: solid 1px rgb(208, 208, 208);
  border-radius: 0.3em;
  max-height: 30em;
}

.videoModal {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.videoModal::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.editor {
  position: relative;
}
.editor .title {
  position: absolute;
  font-size: 0.9em;
  top: 0.3em;
  color: rgb(154, 154, 154);
}
.editor .menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.3s;
  opacity: 0;
  transform: translateY(0.3em);
  background-color: #fff;
}
.editor .menubar .menu_group {
  position: relative;
  z-index: 1;
  display: inline-block;
  border-left: solid 1px rgb(204, 204, 204);
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
}
.editor .menubar .menu_group:first-child {
  border-left: none;
}
.editor .menubar .menu_group button {
  background: transparent;
  border: none;
  margin: 0 0.3em;
  padding: 0 0.2em;
  font-size: 0.7em;
  transition: 0.3s;
  cursor: pointer;
}
.editor .menubar .menu_group button svg {
  transform: scale(0.8);
  transition: 0.3s;
  fill: #202020;
}
.editor .menubar .menu_group button:hover svg {
  fill: #e23832;
}
.editor .menubar .menu_group button:disabled {
  cursor: default;
}
.editor .menubar .menu_group button:disabled svg {
  fill: #c1c1c1;
}
.editor .menubar .menu_group button:disabled:hover svg {
  fill: #c1c1c1;
}
.editor .menubar .menu_group button.open_color_nav.active, .editor .menubar .menu_group button.is-active {
  background-color: rgb(233, 233, 233);
}
.editor .menubar .color_nav {
  position: relative;
  z-index: 0;
  transform: translateX(-0.4em);
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}
.editor .menubar .color_nav.open {
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
}
.editor .menubar .color_nav button {
  padding: 0 0.3em;
  border: none;
  background: transparent;
  cursor: pointer;
}
.editor .menubar .color_nav button div {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  transition: 0.3s;
}
.editor .menubar .color_nav button:hover div {
  transform: scale(1.1);
}
.editor .menubar.min .color_nav {
  width: 25%;
}
.editor .ProseMirror p.is-editor-empty:first-child:before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.editor .ProseMirror {
  padding: 0.3em 1em;
  border: solid 1px rgb(222, 222, 222);
  transition: 0.3s;
  border-radius: 0 0.4em 0.4em;
  outline: none;
  min-height: 6em;
  max-height: 20em;
  overflow: auto;
}
.editor .ProseMirror p {
  text-align: start !important;
}
.editor .ProseMirror p,
.editor .ProseMirror strong,
.editor .ProseMirror span {
  color: #373737;
}
.editor .ProseMirror strong {
  font-weight: 500;
  font-size: 1em;
}
.editor .ProseMirror h3 {
  font-weight: 600;
}
.editor .ProseMirror h3 strong {
  font-weight: 600;
}
.editor:focus-visible .menubar, .editor:hover .menubar, .editor:focus-within .menubar {
  opacity: 1;
  transform: translateY(0);
}
.editor:focus-visible .ProseMirror, .editor:hover .ProseMirror, .editor:focus-within .ProseMirror {
  outline: none;
  border-color: #fccd87;
}

.list,
.columns {
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
}
.list .item,
.columns .item {
  width: 100%;
  padding: 1em 2em;
  box-sizing: border-box;
  border-bottom: solid 1px rgb(224, 224, 224);
  display: flex;
  flex-direction: row;
}
.list .item:last-child,
.columns .item:last-child {
  border-bottom: none;
}
.list .item .file,
.columns .item .file {
  width: 52%;
}
.list .item .type,
.columns .item .type {
  width: 5%;
  text-align: center;
}
.list .item .type img,
.columns .item .type img {
  width: 40px;
}
.list .item .event,
.columns .item .event {
  width: 26%;
}
.list .item .content,
.columns .item .content {
  width: 40%;
  padding: 0 0.3em;
  height: 4em;
}
.list .item .date,
.columns .item .date {
  width: 15%;
  text-align: center;
}
.list .item .creator,
.columns .item .creator {
  width: 10%;
  text-align: center;
  justify-content: center;
  display: flex;
}
.list .item .creator .picture,
.columns .item .creator .picture {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.list .item .contributors,
.columns .item .contributors {
  width: 15%;
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
}
.list .item .contributors .avatars,
.columns .item .contributors .avatars {
  justify-content: center;
}
.list .item .tags,
.columns .item .tags {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
.list .item .tags .MuiChip-root,
.columns .item .tags .MuiChip-root {
  margin: 0 0.1em;
}
.list .item .tags .MuiChip-filled,
.columns .item .tags .MuiChip-filled {
  background-color: rgba(87, 82, 103, 0.3);
  color: #575267;
  border-radius: 0.3em;
}
.list .item .tags .tagsLeft,
.columns .item .tags .tagsLeft {
  margin-left: 5px;
  position: relative;
  left: -2px;
  font-size: 0.9em;
  color: #575267;
}
.list .item .tags .no_tag,
.columns .item .tags .no_tag {
  display: block;
  color: #9b9b9b;
  font-size: 0.8em;
  text-align: right;
  margin-top: -10px;
}
.list .item .actions,
.columns .item .actions {
  text-align: right;
  align-items: flex-end;
  width: 15%;
}
.list .item .actions a,
.columns .item .actions a {
  margin-right: 2em;
  display: inline-flex;
  align-items: center;
}
.list .item .actions a svg,
.columns .item .actions a svg {
  margin-right: 5px;
}
.list .item .iconpriority,
.columns .item .iconpriority {
  width: 1%;
}
.list .item .iconpriority svg,
.columns .item .iconpriority svg {
  font-size: 1.3em;
  position: relative;
  top: 2px;
}
.list .item .iconpriority svg.high,
.columns .item .iconpriority svg.high {
  color: #e23832;
}
.list .item .iconpriority svg.low,
.columns .item .iconpriority svg.low {
  color: #7bb9b3;
}
.list .item .checkaction,
.columns .item .checkaction {
  width: 3%;
  text-align: center;
}
.list .item .checkaction svg,
.columns .item .checkaction svg {
  color: #9b9b9b;
  font-size: 1.3em;
  position: relative;
  top: 2px;
}
.list .item .checkaction .done,
.columns .item .checkaction .done {
  color: #a9ce7d;
}
.list .item .task .title.done,
.columns .item .task .title.done {
  text-decoration: line-through;
  font-style: italic;
  color: #9b9b9b;
}
.list .item .task .title.high,
.columns .item .task .title.high {
  font-weight: 600;
}
.list .item .due_date,
.list .item .size,
.list .item .priority,
.list .item .status,
.columns .item .due_date,
.columns .item .size,
.columns .item .priority,
.columns .item .status {
  width: 15%;
}
.list .item .due_date .normal,
.columns .item .due_date .normal {
  color: grey;
}
.list .item .due_date .past,
.columns .item .due_date .past {
  color: rgb(214, 50, 50);
}
.list .item .status .tag,
.list .item .priority .tag,
.columns .item .status .tag,
.columns .item .priority .tag {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.8em;
}
.list .item .status .tag.assigned,
.list .item .priority .tag.assigned,
.columns .item .status .tag.assigned,
.columns .item .priority .tag.assigned {
  border-color: #7bb9b3;
}
.list .item .status .tag.started,
.list .item .priority .tag.started,
.columns .item .status .tag.started,
.columns .item .priority .tag.started {
  border-color: #575267;
}
.list .item .status .tag.review,
.list .item .priority .tag.review,
.columns .item .status .tag.review,
.columns .item .priority .tag.review {
  border-color: #fccd87;
}
.list .item .status .tag.done,
.list .item .priority .tag.done,
.columns .item .status .tag.done,
.columns .item .priority .tag.done {
  border-color: #a9ce7d;
}
.list .item .status .tag.low,
.list .item .priority .tag.low,
.columns .item .status .tag.low,
.columns .item .priority .tag.low {
  border-color: #7bb9b3;
}
.list .item .status .tag.mid,
.list .item .priority .tag.mid,
.columns .item .status .tag.mid,
.columns .item .priority .tag.mid {
  border-color: #fccd87;
}
.list .item .status .tag.high,
.list .item .priority .tag.high,
.columns .item .status .tag.high,
.columns .item .priority .tag.high {
  border-color: #e23832;
}
.list .item .task,
.columns .item .task {
  width: 40%;
}
.list .item .function,
.columns .item .function {
  width: 5%;
}
.list .item .company,
.columns .item .company {
  width: 30%;
}
.list .item .email,
.columns .item .email {
  width: 20%;
}
.list .item .lastname,
.list .item .firstname,
.list .item .phone,
.columns .item .lastname,
.columns .item .firstname,
.columns .item .phone {
  width: 15%;
}
.list .item .open_details_action,
.list .item .open_file_action,
.columns .item .open_details_action,
.columns .item .open_file_action {
  color: #575267;
  transition: 0.3s;
  cursor: pointer;
}
.list .item .open_details_action:hover,
.list .item .open_file_action:hover,
.columns .item .open_details_action:hover,
.columns .item .open_file_action:hover {
  color: #e23832;
  text-decoration: underline;
}
.list .item a,
.columns .item a {
  cursor: pointer;
  color: #575267;
  transition: 0.3s;
}
.list .item a:hover,
.columns .item a:hover {
  color: #e23832;
}
.list .item_with_subrows,
.columns .item_with_subrows {
  border-bottom: solid 1px rgb(224, 224, 224);
  padding: 1em 2em;
}
.list .item_with_subrows:last-child,
.columns .item_with_subrows:last-child {
  border-bottom: none;
}
.list .item_with_subrows .item,
.columns .item_with_subrows .item {
  padding: 0;
  margin-bottom: 0.5em;
  border-bottom: solid 1px rgb(247, 247, 247);
  padding-bottom: 0.4em;
}
.google_autocomplete {
  position: relative;
  width: 100%;
  z-index: 9;
  margin-bottom: 1em;
}
.google_autocomplete input {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  padding: 15px;
  border: solid 1px #dedede;
  border-radius: 2px;
}
.google_autocomplete .loader {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.google_autocomplete .suggestion {
  width: 99.8%;
  position: absolute;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  padding: 0;
}
.google_autocomplete .suggestion ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.google_autocomplete .suggestion ul li {
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}
.google_autocomplete .suggestion ul li:hover {
  background-color: #f4f4f4;
}
.google_autocomplete .suggestion ul li:last-child {
  border-bottom: none;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.row.reverse {
  flex-direction: row-reverse;
}
.row .col {
  position: relative;
  margin: 0 0.2em;
}
.row .col:first-child {
  margin-left: 0;
}
.row .col:last-child {
  margin-right: 0;
}
@media all and (max-width: 991px) {
  .row {
    flex-direction: column;
  }
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.col.reverse {
  flex-direction: column-reverse;
}
.col.md4 {
  width: 25%;
  flex: 0.25;
}
.col.md6 {
  width: 50%;
  flex: 0.5;
}
.col.md8 {
  width: 75%;
  flex: 0.75;
}

.textField {
  border: solid 1px rgb(222, 222, 222);
  border-radius: 0.2em;
  width: 100%;
  position: relative;
  margin: 1em 0;
  z-index: 1;
}
.textField label {
  position: absolute;
  color: rgb(199, 199, 199);
  padding: 0 0.3em;
  z-index: 0;
  left: 15px;
  height: 100%;
  font-size: 0.8em;
  transition: 0.3s;
  top: 0;
  display: flex;
  align-items: center;
}
.textField input {
  border: none;
  outline: none;
  width: 94%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  background: transparent;
  height: 100%;
  padding: 0.8em 15px;
  font-size: 0.95em;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: rgb(35, 35, 35);
}
.textField button,
.textField .icon {
  width: 6%;
  height: 100%;
  text-align: right;
  border: none;
  background: transparent;
  font-size: 0.8em;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textField button svg,
.textField .icon svg {
  transform: scale(0.7);
  transition: 0.3s;
  fill: grey;
}
.textField button :hover {
  cursor: pointer;
}
.textField button :hover svg {
  fill: #e23832;
}
.textField:focus label, .textField:focus-visible label, .textField:focus-within label, .textField.complete label {
  transform: scale(0.85) translateY(-67%) translateX(-10%);
  color: #322c31;
  background-color: #fff;
  height: auto;
}
.textField.error {
  border: solid 1px #99201c;
  background-color: rgb(246, 237, 236);
  color: #99201c;
}
.textField.error label {
  color: #99201c;
  background-color: rgb(246, 237, 236);
  height: auto;
}
.textField.disabled label,
.textField.disabled input {
  color: #9b9b9b;
}
.textField.disabled input {
  font-style: italic;
}

.select_element .css-1dimb5e-singleValue {
  color: #322c31;
}
.react-select__control.react-select__control--is-focused {
  border-color: #e23832 !important;
  outline: none !important;
  box-shadow: none !important;
}
.react-select__control .react-select__option--is-selected {
  background-color: #e23832 !important;
  color: #fff !important;
}
.react-select__control .react-select__menu-list .react-select__option.option--is-selected,
.react-select__control .react-select__menu-list .react-select__option .option--is-selected {
  background-color: #e23832 !important;
  color: #fff !important;
}

.MuiDataGrid-root {
  height: 78% !important;
  background: #FFF !important;
  border-radius: 0.6em !important;
}
@media all and (max-width: 1450px) {
  .MuiDataGrid-root {
    height: 75% !important;
  }
}

.MuiDataGrid-footerContainer {
  display: flex;
  flex-direction: row-reverse;
}

.MuiButton-containedPrimary:not(.Mui-disabled) {
  background-color: #575267 !important;
}

.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) {
  color: #322c31 !important;
}
.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root).MuiButton-outlined {
  border-color: #322c31 !important;
}
.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root).MuiButton-textError {
  color: #e23832 !important;
}
.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root).MuiButton-textError.MuiButton-outlined {
  border-color: #e23832 !important;
}

.errroNotification .MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) {
  color: #fff !important;
}

.MuiModal-backdrop:not(.MuiBackdrop-invisible) {
  background-color: rgba(50, 44, 49, 0.9) !important;
}

#form-file-upload {
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 15em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  transition: 0.3s;
}
#label-file-upload:hover {
  border-color: #e23832;
}
#label-file-upload > div {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.uploadGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.uploadGallery img {
  margin: 3px;
  width: 75px;
  height: 75px;
  -o-object-fit: cover;
     object-fit: cover;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.preview_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-wrap: wrap;
}
.preview_container .preview {
  width: 75px;
  height: 75px;
  margin: 3px;
  border: solid 2px #eee;
}
.preview_container .preview img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.preview_container_file {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-wrap: wrap;
}
.preview_container_file .file_name {
  border-bottom: solid 1px rgb(203, 203, 203);
  display: flex;
  align-items: center;
  padding: 5px;
}

.shepherd-modal-overlay-container {
  opacity: 0;
  transition: 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.shepherd-modal-is-visible {
  opacity: 0.9;
}
.shepherd-modal-is-visible path {
  fill: #575267;
}

.shepherd-element {
  background-color: #fff;
  padding: 10px;
  z-index: 999;
  outline: none;
  position: relative;
  padding: 0;
  max-width: 700px;
}
.shepherd-element .shepherd-cancel-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.shepherd-element .shepherd-header {
  background-color: #21a194;
  text-align: center;
  padding: 30px;
  color: #FFF;
  font-size: 1.8em;
  line-height: 2em;
}
.shepherd-element .shepherd-text {
  padding: 30px;
}
.shepherd-element .shepherd-footer {
  border-top: solid 1px rgb(247, 247, 247);
  margin-top: 15px;
  padding: 30px;
}
.shepherd-element .shepherd-footer .exit_button {
  border: none;
  background: transparent;
  color: #322c31;
  text-transform: uppercase;
  cursor: pointer;
}
.shepherd-element .shepherd-footer .back_button {
  border: solid 1px #575267;
  color: #575267;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 5px;
  background: transparent;
  float: right;
  margin: 0 10px;
  cursor: pointer;
}
.shepherd-element .shepherd-footer .next_button {
  border: solid 1px #21a194;
  background-color: #21a194;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 5px;
  float: right;
  cursor: pointer;
}

.shepherd-content {
  position: relative;
  z-index: 1;
  background: #FFF;
}

.shepherd-arrow {
  background-color: #fff7e9;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  position: absolute;
  z-index: 0;
}

.arrowTop {
  margin-top: 1em;
}
.arrowTop .shepherd-arrow {
  left: 45%;
}

.arrowRight {
  margin-left: -1em;
}
.arrowRight .shepherd-arrow {
  top: 35%;
  left: auto;
  right: 0;
}

/* Global styles */
body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-feature-settings: "pnum";
  font-size: 14px;
  font-style: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  color: #3b3b3b;
}

a {
  color: #e23832;
}
a:visited {
  color: #e23832;
}

.emptyMessage {
  color: rgb(217, 217, 217);
  text-align: center;
  padding: 2em;
  display: block;
  font-size: 2em;
  font-weight: 300;
}
.emptyMessage a {
  font-size: 0.9em;
  line-height: 2em;
}

.error_message {
  color: #99201c;
}

.success_message {
  color: #a9ce7d;
}

.applicationContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
  padding: 0 2em;
  overflow: hidden;
  background-color: #fff;
}
@media all and (max-width: 768px) {
  .applicationContainer {
    padding: 0;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgb(225, 225, 225);
  margin: 1em 0;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  position: fixed;
  z-index: 9;
  background-color: #fff;
}
.loader svg {
  color: grey;
}

.lost {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lost svg {
  font-size: 6em;
  margin-bottom: 0.2em;
  color: #e23832;
}
.lost .lostIcon {
  color: rgb(247, 247, 247);
}
.lost span {
  font-size: 5em;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0.4em;
}
.lost span.message {
  font-size: 1em;
  font-weight: 200;
  width: auto !important;
  height: auto !important;
}
.lost a {
  color: #e23832;
}

/* Font weight */
strong,
h2,
th {
  font-weight: 600;
}

/* scrollbar */
/* width */
body {
  scrollbar-color: #888 #f1f1f1;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.select_label {
  color: #e23832;
  font-size: 0.7em;
  padding: 0.3em 1em;
  background-color: #fff;
  position: relative;
  display: inline-block;
  width: 4em;
  top: 0em;
  z-index: 2;
  margin-bottom: -0.4em;
  left: 0.5em;
}

.uploadLoader {
  top: 0;
  width: 100%;
  z-index: 99 !important;
  background-color: rgba(226, 56, 50, 0.2) !important;
  transition: 0.3s;
}
.uploadLoader.done {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4px);
}
.uploadLoader span {
  background-color: #e23832;
}

.appLoader {
  position: fixed !important;
  top: 0;
  width: 100vw;
  z-index: 99 !important;
  background-color: rgba(226, 56, 50, 0.2) !important;
  transition: 0.3s;
}
.appLoader.done {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4px);
}
.appLoader span {
  background-color: #e23832;
}

.loading_skeleton {
  padding: 3em 0;
  opacity: 0.5;
}
.loading_skeleton .row {
  flex: 0.05;
}

.deviceView {
  display: none;
}
@media all and (max-width: 990px) {
  .deviceView {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 3em;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    font-size: 1.2em;
  }
  .deviceView.hide {
    display: none;
  }
  .deviceView img {
    width: 10em;
    max-width: 90%;
    margin-bottom: 4em;
  }
  .deviceView span {
    font-size: 2em;
    font-weight: 300;
    text-align: center;
  }
  .deviceView strong {
    font-size: 1.3em;
    margin-top: 1em;
    text-align: center;
    padding: 1em 2em;
  }
  .deviceView .stores {
    display: flex;
    margin-top: 1.2em;
  }
}

.desktopView {
  display: block;
}
@media all and (max-width: 990px) {
  .desktopView {
    display: none;
  }
  .desktopView.show {
    display: block;
  }
}

.tag.clickable {
  cursor: pointer;
  transition: 0.3s;
}
.tag.clickable:hover {
  background-color: #322c31 !important;
}
.tag.clickable:hover .MuiChip-label {
  color: #fff !important;
}

/** upgrade */
.need_upgrade {
  padding: 3px 5px;
  color: #fccd87;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: solid 2px #fccd87;
  border-radius: 0.3em;
  margin: 0 0.3em;
  font-size: 0.9em;
  transition: 0.3s;
  cursor: pointer;
}
.need_upgrade:hover {
  color: #fff;
  background-color: #fccd87;
}
.need_upgrade svg {
  font-size: 1.2em;
  margin-right: 0.3em;
}/*# sourceMappingURL=App.css.map */