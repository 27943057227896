.list,
.columns {
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    .item {
        width: 100%;
        padding: 1em 2em;
        box-sizing: border-box;
        border-bottom: solid 1px rgb(224, 224, 224);
        display: flex;
        flex-direction: row;

        &:last-child {
            border-bottom: none;
        }


        .file{
            width:52%;
        }
        .type {
            width: 5%;
            text-align: center;

            img {
                width: 40px;
            }
        }

        .event{
            width: 26%;
        }

        .content {
            width: 40%;
            padding: 0 0.3em;
            height: 4em;
        }
        .date {
            width: 15%;
            text-align: center;
        }

        .creator {
            width: 10%;
            text-align: center;
            justify-content: center;
            display: flex;
            .picture {
                width: 40px;
                height: 40px;
                border-radius: 20px;
            }
        }

        .contributors {
            width: 15%;
            position: relative;
            display: flex;
            flex-direction: row;
            text-align: center;

            .avatars {
                justify-content: center;
            }
        }
        .tags {
            width: 10%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-top: 10px;

            .MuiChip-root {
                margin: 0 0.1em;
            }
            .MuiChip-filled {
                background-color: rgba($colorScheme3, 0.3);
                color: $colorScheme3;
                border-radius: 0.3em;
            }

            .tagsLeft {
                margin-left: 5px;
                position: relative;
                left: -2px;
                font-size: 0.9em;
                color: $colorScheme3;
            }
            .no_tag {
                display: block;
                color: $midGrey;
                font-size: 0.8em;
                text-align: right;
                margin-top: -10px;
            }
        }

        .actions {
            //display: none;
            text-align: right;
            align-items: flex-end;
            width: 15%;

            a {
                margin-right: 2em;
                display: inline-flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }

        .iconpriority{
            width:1%;
            svg{
                font-size: 1.3em;
                position:relative;
                top:2px;

                &.high{
                    color:$high;
                }

                &.low{
                    color:$low;
                }
            }
        }

        .checkaction{
            width:3%;
            text-align: center;

            svg{
                color:$midGrey;
                font-size: 1.3em;
                position:relative;
                top:2px;
            }


            .done{
                color:$success;
            }
        }

        .task{
            .title{
                &.done{
                    text-decoration: line-through;
                    font-style: italic;
                    color:$midGrey;
                }

                &.high{
                    font-weight: 600;
                }
            }
        }
        .due_date,
        .size,
        .priority,
        .status {
            width: 15%;
        }

        .due_date {
            .normal {
                color: grey;
            }

            .past {
                color: rgb(214, 50, 50);
            }
        }

        .status,
        .priority {
            .tag {
                //color: #fff;
                display: inline-block;
                //padding: 0.3em 1em;
                text-transform: uppercase;
                //border-radius: 0.2em;
                font-size: 0.8em;
                //border: solid 1px grey;

                &.assigned {
                    //color: $assigned;
                    border-color: $assigned;
                }
                &.started {
                    //color: $start;
                    border-color: $start;
                }
                &.review {
                    //color: $review;
                    border-color: $review;
                }
                &.done {
                    //color: $done;
                    border-color: $done;
                }

                &.low {
                    //color: $low;
                    border-color: $low;
                }
                &.mid {
                    //color: $mid;
                    border-color: $mid;
                }
                &.high {
                    //color: $high;
                    border-color: $high;
                }
            }
        }

        .task {
            width: 40%;
        }

        .function {
            width: 5%;
        }

        .company {
            width: 30%;
        }

        .email {
            width: 20%;
        }
        .lastname,
        .firstname,
        .phone {
            width: 15%;
        }

        .open_details_action,
        .open_file_action {
            color: $colorScheme3;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                color: $colorScheme1;
                text-decoration: underline;
            }
        }

        a {
            cursor: pointer;
            color: $colorScheme3;
            transition: 0.3s;

            &:hover {
                color: $colorScheme1;
            }
        }
    }

    .item_with_subrows {
        border-bottom: solid 1px rgb(224, 224, 224);
        padding: 1em 2em;

        &:last-child {
            border-bottom: none;
        }

        .item {
            padding: 0;
            margin-bottom: 0.5em;
            border-bottom: solid 1px $lightGrey;
            padding-bottom: 0.4em;
        }

        .subrows {
        }
    }
}
