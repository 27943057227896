@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

@import "theme";

@import "Modules/header";
@import "Modules/navigation";
@import "Modules/page";
@import "Modules/lightbox";
@import "Modules/comments";
@import "Modules/sound";
@import "Modules/dashboard";
@import "Modules/wizard";
@import "Modules/reports";

@import "Modules/Projects/filters";
@import "Modules/Projects/project";
@import "Modules/Projects/events";
@import "Modules/Projects/calendar";
@import "Modules/Projects/contractors";

@import "Modules/Events/details";
@import "Modules/Events/flux";
@import "Modules/Events/validation";

@import "Modules/Account/login";
@import "Modules/Account/subscription";
@import "Modules/Account/account";

@import "Modules/Common/modals";
@import "Modules/Common/editor";
@import "Modules/Common/list";
@import "Modules/Common/googleAutocomplete";

@import "Utils/flexbox";
@import "Utils/fields";
@import "Utils/datagrid";
@import "Utils/mui";
@import "Utils/dropUploader";
@import "Utils/tour";

/* Global styles */

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-feature-settings: "pnum";
  font-size: 14px;
  font-style: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  color: #3b3b3b;
}
a {
  color: $primary;
  &:visited {
    color: $primary;
  }
}

.emptyMessage {
  color: rgb(217, 217, 217);
  text-align: center;
  padding: 2em;
  display: block;
  font-size: 2em;
  font-weight: 300;

  a {
    font-size: 0.9em;
    line-height: 2em;
  }
}

.error_message {
  color: $error;
}
.success_message {
  color: $success;
}

.applicationContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
  padding: 0 2em;
  overflow: hidden;
  background-color: $background;

  @media all and (max-width: 768px) {
    padding: 0;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgb(225, 225, 225);
  margin: 1em 0;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  position: fixed;
  z-index: 9;
  background-color: #fff;
  svg {
    color: grey;
  }
}

.lost {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 6em;
    margin-bottom: 0.2em;
    color: $primary;
  }

  .lostIcon {
    color: $lightGrey;
  }

  span {
    font-size: 5em;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 0.4em;

    &.message {
      font-size: 1em;
      font-weight: 200;
      width: auto !important;
      height: auto !important;
    }
  }

  a {
    color: $primary;
  }
}
/* Font weight */

strong,
h2,
th {
  font-weight: 600;
}
/* scrollbar */

/* width */
body {
  scrollbar-color: #888 #f1f1f1;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.select_label {
  color: $primary;
  font-size: 0.7em;
  padding: 0.3em 1em;
  background-color: #fff;
  position: relative;
  display: inline-block;
  width: 4em;
  top: 0em;
  z-index: 2;
  margin-bottom: -0.4em;
  left: 0.5em;
}

.uploadLoader {
  top: 0;
  width: 100%;
  z-index: 99 !important;
  background-color: rgba($primary, 0.2) !important;
  transition: 0.3s;

  &.done {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4px);
  }

  span {
    background-color: $primary;
  }
}

.appLoader {
  position: fixed !important;
  top: 0;
  width: 100vw;
  z-index: 99 !important;
  background-color: rgba($primary, 0.2) !important;
  transition: 0.3s;

  &.done {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4px);
  }

  span {
    background-color: $primary;
  }
}

.loading_skeleton {
  padding: 3em 0;
  opacity: 0.5;

  .row {
    flex: 0.05;
  }
}

.deviceView {
  display: none;

  @media all and (max-width: 990px) {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 3em;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    font-size: 1.2em;

    &.hide {
      display: none;
    }

    img {
      width: 10em;
      max-width: 90%;
      margin-bottom: 4em;
    }

    span {
      font-size: 2em;
      font-weight: 300;
      text-align: center;
    }

    strong {
      font-size: 1.3em;
      margin-top: 1em;
      text-align: center;
      padding: 1em 2em;
    }

    .stores {
      display: flex;
      margin-top: 1.2em;
    }
  }
}

.desktopView {
  display: block;
  @media all and (max-width: 990px) {
    display: none;

    &.show {
      display: block;
    }
  }
}

.tag {
  &.clickable {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: $secondary !important;

      .MuiChip-label {
        color: #fff !important;
      }
    }
  }
}

/** upgrade */

.need_upgrade {
  padding: 3px 5px;
  color: $colorScheme4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: solid 2px $colorScheme4;
  border-radius: 0.3em;
  margin: 0 0.3em;
  font-size: 0.9em;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: $colorScheme4;
  }
  svg {
    font-size: 1.2em;
    margin-right: 0.3em;
  }
}
