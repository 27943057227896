.google_autocomplete {
    position: relative;
    width: 100%;
    z-index: 9;
    margin-bottom: 1em;

    input {
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        padding: 15px;
        border: solid 1px #dedede;
        border-radius: 2px;
    }

    .loader {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .suggestion {
        width: 99.8%;
        position: absolute;
        background-color: #fff;
        border: 1px solid #dedede;
        border-radius: 3px;
        padding: 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding: 15px;
                border-bottom: 1px solid #f0f0f0;
                cursor: pointer;
                &:hover {
                    background-color: #f4f4f4;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
