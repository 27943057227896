.shepherd-modal-overlay-container {
    opacity: 0;
    transition: 0.2s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.shepherd-modal-is-visible {
    opacity: .9;
    path {
        fill: $third;
    }
}

.shepherd-element {
    background-color: #fff;
    padding: 10px;
    z-index: 999;
    outline: none;
    position: relative;
    padding: 0;
    max-width: 700px;

    .shepherd-cancel-icon {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .shepherd-header {
        background-color: #21a194;
        text-align: center;
        padding: 30px;
        color: #FFF;
        font-size:1.8em;
        line-height: 2em;
    }

    .shepherd-text {
        padding: 30px;
    }
    .shepherd-footer {
        border-top: solid 1px $lightGrey;
        margin-top: 15px;
        padding: 30px;

        .exit_button {
            border: none;
            background: transparent;
            color: $secondary;
            text-transform: uppercase;
            cursor: pointer;

        }

        .back_button {
            border: solid 1px $third;
            color: $third;
            text-transform: uppercase;
            border-radius: 3px;
            padding: 5px;
            background: transparent;
            float: right;
            margin: 0 10px;
            cursor: pointer;

        }

        .next_button {
            border: solid 1px #21a194;
            background-color: #21a194;
            color: #fff;
            text-transform: uppercase;
            border-radius: 3px;
            padding: 5px;
            float: right;
            cursor: pointer;
        }
    }
}

.shepherd-content{
    position: relative;
    z-index: 1;
    background: #FFF;
}

.shepherd-arrow{
    background-color: $colorScheme6;
    width:50px;
    height:50px;
    transform: rotate(45deg);
    position: absolute;
    z-index: 0;
}

.arrowTop{
    margin-top:1em;
    .shepherd-arrow{
        left:45%;
    }
}

.arrowRight{
    margin-left:-1em;
    .shepherd-arrow{
        top:35%;
        left:auto;
        right:0;
    }
}