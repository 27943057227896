.calendar_view {
    &.with_filters {
        padding-top: 28px;
    }
}

.fc-button-primary {
    background-color: $colorScheme3 !important;
    border-color: $colorScheme3 !important;
}

.MuiCalendarPicker-root .MuiButtonBase-root.Mui-selected {
    color: #fff !important;
    background-color: $primary !important;
}

.calendar {
    padding-top: 2em;

    a {
        color: $secondary;
    }

    height: 85%;

    > div {
        height: 100%;
        position: relative;
        z-index: 1;
    }

    .event_day {
        border: dashed 2px;
    }

    .fc-view-harness {
        background: #fff;
        border-radius: 0.3em;
        padding: 2em;
        overflow-y: auto;
        height: 80%;

        .fc-view {
            border: none !important;
        }

        .fc-event {
            cursor: pointer;
        }

        .fc-daygrid-dot-event {
            background-color: $colorScheme3;

            .fc-daygrid-event-dot {
                border-color: $colorScheme3;
            }

            .fc-event-time,
            .fc-event-title {
                //color: $secondary;
                //color: #fff;
            }
            .fc-event-title {
                padding: 0;
            }
        }
    }

    .fc-daygrid-more-link {
        border: none !important;
        background-color: rgba($secondary, 0.2) !important;
        color: $secondary;
        padding: 0.25em !important;
        font-weight: 500;
    }

    .fc-event-title {
        font-weight: 400 !important;
        padding: 0.25em !important;
        //color: #fff;
    }
    .fc-bg-event,
    .fc-h-event {
        background-color: $secondary;
        border-color: transparent;
    }

    .fc-daygrid-day-bg {
        .fc-daygrid-bg-harness {
            height: 50%;

            &:last-child {
                top: 50%;
            }

            &:first-child {
                top: 0;
            }
            &:only-child {
                height: 100%;
            }
        }
    }

    .fc-button {
        border: solid 1px #fff !important;
        //opacity: 0.7;
        transition: 0.3s;
        outline: none;
        box-shadow: none !important;

        &:hover {
            opacity: 0.9 !important;
        }
        &.fc-button-active {
            opacity: 1 !important;
        }
    }
    @media all and (max-width: 768px) {
        .fc .fc-toolbar-title {
            font-size: 1em;
        }
    }
}
