.wizard {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 900px;
        max-width: 100vw;
        padding: 2em;
        box-sizing: border-box;
        height: 50vh;

        .sayto {
            margin-bottom: 6em;

            img {
                width: 150px;
                transition: 0.3s;
                transform: translateY(2em);
                opacity: 0;
                visibility: hidden;
                &.animate {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }

            @media all and (max-width: 1450px) {
                margin: 0;
                position: absolute;
                left: 5vh;
                top: 5vh;

                img {
                    width: 100px;
                }
            }
        }
        .steps {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 3;
            min-height: 45vh;

            @media all and (max-width: 1450px) {
                min-height: 50vh;
            }

            .step {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                transition: 0.3s;
                position: absolute;

                opacity: 0;
                visibility: hidden;
                transform: translateY(-2em);

                &.active {
                    z-index: 2;
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }

                .description {
                    color: $midGrey;
                    text-align: center;
                }

                .welcome_message {
                    display: flex;
                    flex-direction: column;
                    font-size: 2em;
                    text-align: center;
                    margin-bottom: 2em;
                    transition: 0.3s;
                    transform: translateY(2em);
                    opacity: 0;
                    visibility: hidden;

                    &.animate {
                        transform: translateY(0);
                        opacity: 1;
                        visibility: visible;
                    }

                    span:first-child {
                        font-size: 1em;
                        font-weight: 300;
                        margin-bottom: 0.5em;
                        color: $midGrey;
                    }
                }

                p {
                    font-size: 1.2em;
                    text-align: center;
                    margin-bottom: 2em;
                    color: $midGrey;
                }

                strong.question {
                    font-size: 1.7em;
                    text-align: center;
                    color: $darkGrey;
                    display: block;
                    font-weight: 400;
                }

                input {
                    border: solid 1px #bdbdbd;
                    padding: 10px;
                    margin: 1em 0;
                    border-radius: 0.3em;
                    font-size: 1.2em;

                    outline: none;
                    transition: 0.3s;

                    &:hover,
                    &:focus {
                        border-color: $colorScheme4 !important;
                    }

                    &.project_name {
                        font-weight: 600;
                        width: 100%;
                        text-align: center;
                        border-color: $lightGrey;

                        &.error {
                            border-color: $primary !important;
                        }

                        &::placeholder {
                            color: #cacaca;
                            opacity: 1;
                            font-weight: 400;
                            font-size: 0.8em;
                        }

                        &:-ms-input-placeholder {
                            color: #cacaca;
                        }

                        &::-ms-input-placeholder {
                            color: #cacaca;
                        }
                    }
                }

                .form {
                    text-align: center;
                    button {
                        margin: 1.5em 0 0.5em 0;
                    }
                    span {
                        font-size: 1.7em;
                        text-align: center;
                        color: $darkGrey;
                        display: block;
                        font-weight: 400;
                    }
                }
                .google_autocomplete {
                    margin-top: 2em;
                    width: 25em;
                    max-width: 100%;
                    input {
                        width: 100%;
                        opacity: 1 !important;
                    }
                }

                .budget {
                    display: flex;
                    align-items: center;
                    input {
                        text-align: right;
                    }
                    span {
                        font-size: 1.4em;
                        margin-left: 0.3em;
                    }
                }

                .date_project {
                    display: flex;
                    flex-direction: row;
                    .picker {
                        border-radius: 3px;
                        padding: 10px;
                        display: flex;
                        margin: 5px;
                        flex-direction: column;
                        box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);

                        span.question {
                            font-size: 1.4em;
                            margin-top: 1em;
                            display: block;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .actions {
            flex: inherit;
            height: 4em;
            align-items: center;
            margin-top: 5em;
            border-top: solid 1px $lightGrey;
            width: 100%;
            transition: 0.3s;
            transform: translateY(2em);
            opacity: 0;
            visibility: hidden;
            padding-top: 1em;

            &.active {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }

            .col {
                &.col3 {
                    flex: 0.25;
                    width: 25%;
                }

                &.col6 {
                    flex: 0.5;
                    width: 50%;
                }
            }
        }
    }

    .animation_element {
        transition: 0.3s;
        transform: translateY(2em);
        opacity: 0;
        visibility: hidden;
        transition-delay: 0.3s;

        &.animate {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }
    }
}
