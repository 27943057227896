.need_more {
    background-color: #f1f1f1;
    border-radius: 0.3em;
    margin-left: 1em;
    padding: 4px 8px;
    font-size: 0.7em;
    position: relative;
    span {
        &.need_upgrade_label {
            background-color: $colorScheme5;
            color: white;
            padding: 0em 0.5em;
            border-radius: 0.3em;
            font-size: 0.7em;
            position: relative;
        }
    }
}

span {
    &.need_upgrade_label {
        background-color: $colorScheme5;
        color: white;
        position: absolute;
        top: -20px;
        right: -10px;
        padding: 0em 0.5em;
        border-radius: 0.3em;
        font-size: 0.7em;
    }
}

.subscription {
    .row {
        .title {
            color: $darkGrey;
            font-size: 1.6em;
            font-weight: 500;

            span.actual_plan_label {
                margin-left: 0.5em;
                background-color: $primary;
                padding: 0em 1em;
                font-size: 0.8em;
                border-radius: 0.3em;
                color: #fff;
                height: 1.5em;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.row_actual_plan {
            margin-bottom: 0em;
        }

        &.row_selector_title {
            align-items: center;

            .title {
                font-size: 2em;
                color: $darkGrey;

                flex-grow: 1;
                span {
                    color: $primary;
                }
            }
            .toggle {
                button {
                    background-color: rgb(244, 244, 244);
                    color: $primary !important;
                    border: solid 2px $primary;
                    border-radius: 0;
                    padding: 0.3em 1em;

                    &:first-child {
                        border-radius: 2em 0 0 2em;
                    }

                    &:last-child {
                        border-radius: 0 2em 2em 0;
                    }
                    &.active {
                        background-color: $primary !important;
                        color: #fff !important;
                    }
                }
            }
        }

        .plan_selector {
            .plan {
                border-radius: 1em;
                overflow: hidden;

                span.plan_not_selected,
                span.actual_selected {
                    display: flex;
                    padding: 0.3em;
                    height: 2em;
                }
                span.actual_selected {
                    align-items: center;
                    justify-content: center;
                    background-color: $primary;
                    color: #fff;
                }

                .content {
                    padding: 1em;
                    text-align: center;

                    strong {
                        font-weight: 600;
                        font-size: 2em;
                    }

                    span.price {
                        margin-top: 1em;
                        font-size: 1.5em;
                        font-weight: bold;
                        display: block;
                        color: $primary;
                    }

                    span.storage {
                        font-size: 1.2em;
                        font-weight: bold;
                        margin-right: 4px;
                    }

                    ul.advantages {
                        list-style: none;
                        padding: 0;

                        li {
                            padding: 0.5em 0;
                            color: rgb(84, 84, 84);
                            height: 1.6em;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.reduction {
                                color: $primary;
                            }

                            span.disabled {
                                color: rgb(193, 193, 193);
                            }
                        }
                    }

                    .select_button:not(.Mui-disabled) {
                        color: $primary !important;
                    }
                }
            }
        }
    }
}

.checkout {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    background-color: rgba($secondary, 0.9) !important;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        svg {
            color: #b9b9b9;
        }
    }

    .form {
        border-left: solid 4px #eee;
        width: 40vw;
        height: 100vh;
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        opacity: 0;
        transition: 0.2s;
        transform: translateX(50%);
        padding: 6%;
        text-align: right;

        h3 {
            font-size: 2.4em;
            margin: 0.4em 0;
        }

        span.price {
            font-size: 1.4em;
        }

        .contentForm {
            min-height:300px;
            margin: 2em 0;
        }
    }
}

.cancel_subscription {
    strong,
    span {
        display: block;
    }

    strong {
        font-size: 1.4em;
    }

    span {
        margin: 1em 0;
        color: $midGrey;
    }
}

.paymentInfo{
    padding:2em;
    text-align: center;
    background-color: #FFF;

    &.success{
        h2{
            color:$success;
        }
    }

    &.cancel{

        h2{
            color:$alert;
        }
    }
}


.payments {

    
       .columns {

        .header {
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid 1px #eee;

            .date {
                width: 15%; 
                text-align: center;
            }
            .description {
                width: 40%; 
            }
            .amount {
                width: 20%; 
            }
            .actions {
                text-align: center;
                width: 10%; 
            }
        }
        

    }

    .list {

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1em 0;
            border-bottom: solid 1px #eee;

            .date {
                width: 15%; 
                text-align: center;
            }
            .description {
                width: 40%; 
            }
            .amount {
                width: 20%; 
            }
            .actions {
                width: 10%; 
                text-align: center;

                a {
                    margin-right: 0;
                }
            }
        }
    }

    


}