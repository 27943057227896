.soundPlayer {
    height: auto;
    overflow-y: auto;

    .player {
        display: flex;
        flex-direction: row;
        padding: 7px 10px;
        align-items: center;
        border-bottom: solid 1px $lightGrey;

        &:last-child {
            border-bottom: none;
        }

        span {
            margin-bottom: 0.5em;
            width: 35%;
            font-size: 0.9em;
        }
        audio {
            width: 100%;
        }
        button {
            cursor: pointer;

            color: $primary;
            border: none;
            background-color: transparent;
            z-index: 3;
            border-radius: 100px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            margin-left: 10px;
        }
    }
}

.event_flux {
    .soundPlayer {
        width: 40%;

        .player {
            width: 100%;
        }
    }
}
