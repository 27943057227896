#form-file-upload {
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 15em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;

  transition: 0.3s;

  &:hover {
    border-color: $primary;
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.uploadGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 1rem;
  margin-bottom: 1rem;

  img {
    margin: 3px;
    width: 75px;
    height: 75px;
    object-fit: cover;
  }
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.preview_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-wrap: wrap;

  .preview {
    width: 75px;
    height: 75px;
    margin: 3px;
    border: solid 2px #eee;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.preview_container_file {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-wrap: wrap;

  .file_name {
    border-bottom: solid 1px rgb(203, 203, 203);
    display: flex;
    align-items: center;
    padding:5px;
  }
}
