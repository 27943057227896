.LightBox {
    position: fixed;
    transition: 0.3s;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;

    &.open {
        opacity: 1;
        visibility: visible;
    }

    &.close {
        opacity: 0;
        visibility: hidden;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .close {
        position: fixed;
        right: 2em;
        top: 2em;
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 1.4em;

        img {
            width: 1.5em;
        }
    }

    .nav_button {
        position: fixed;
        top: 45vh;
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        border: none;
        color: #000;
        font-size: 1.4em;
        transition: 0.3s;
        background-color: transparent;
        opacity: 0.4;
        cursor: pointer;

        img {
            width: 2.5em;
        }
        &:hover {
            opacity: 1;
        }

        &.previous {
            left: 2vw;
        }

        &.next {
            right: 2vw;
        }
    }

    .caption {
        position: fixed;
        bottom: 0;
        width: 100vw;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 2em;
        box-sizing: border-box;
        color: rgba(#fff, 0.7);
        font-size: 0.9em;
    }

    .pictureContainer {
        overflow: none;
        max-width: 70vw;
        max-height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                transform: scale(1.005);
            }
        }

        &.zoom {
            overflow: auto;
            width: 100%;
            height: 100%;

            max-width: none;
            max-height: none;

            img {
                width: auto;
                height: 100%;
                max-width: none;
                max-height: none;

                &:hover {
                    transition: 0s;
                    transform: scale(1);
                }
            }
        }
    }
    img {
        max-width: 70vw;
        max-height: 80vh;

        transition: 0.1s;
    }
}

.gallery {
    width: 100%;
    height: 100%;
    position: relative;

    > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .gallery_item {
        width: 13em;
        height: 9em;
        margin: 0.2em;
        background-color: rgb(227, 227, 227);
        text-align: center;

        border-radius: 0.3em;
        position: relative;

        button {
            cursor: pointer;
            position: absolute;
            top: 2px;
            right: 2px;
            color: $primary;
            border: none;
            background-color: transparent;
            z-index: 3;
            border-radius: 100px;
            width: 24px;
            height: 24px;
            display:flex;
            align-items: center;
            justify-content: center;
            background: #fff;
        }
        a {
            position: relative;
            z-index: 1;
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            object-fit: contain;
            will-change: transform;
        }
    }
}
