.eventDetailsContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    background-color: rgba($secondary, 0.9) !important;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
    .eventDetails {
        border-left: solid 4px #eee;
        width: 65%;
        height: 100%;
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        opacity: 0;
        transition: 0.2s;
        transform: translateX(50%);

        .details_header {
            position: absolute;
            z-index: 9;
            background-color: #fff;
            width: 100%;
            padding: 1em;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            border-bottom: solid 2px #eee;
            height: 8vh;

            .details_infos {
                display: flex;
                align-items: center;
                width: 70%;
                span {
                    color: rgb(201, 201, 201);
                }
                h2 {
                    margin: 0;
                    color: rgb(61, 61, 61);
                }

                input {
                    width: 100%;
                    display: inline-block;
                    padding: 0.5em;
                    border: solid 1px rgb(221, 221, 221);
                    border-radius: 0.3em;
                }

                .actions {
                    width: 30%;
                    .task_actions {
                        font-size: 0.7em;
                        padding: 0.2em 0.5em !important;
                        transition: 0.2s;

                        svg {
                            font-size: 1.5em !important;
                            margin-right: 0.3em;
                            transition: 0.2s;
                        }
                        span {
                            color: rgba($secondary, 0.9) !important;
                            transition: 0.2s;
                            height: 1.6em;
                        }

                        &:hover,
                        &.done {
                            background-color: rgba($success, 0.1) !important;
                            border-color: rgba($success, 0.9) !important;
                            span,
                            svg {
                                color: $success !important;
                            }
                        }
                    }

                    .date_actions {
                        height: 2em;
                        .row {
                            .col {
                                .item {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    height: 2em;

                                    strong {
                                        font-size: 0.9em;
                                        font-weight: 400;
                                        margin-right: 0.4em;
                                    }

                                    .select_field {
                                        margin: 0;
                                    }

                                    .colorPickerButton {
                                        width: 40px;
                                        height: 20px;
                                        border: solid 1px rgb(203, 203, 203);
                                        border-radius: 0.2em;
                                        cursor: pointer;

                                        div {
                                            height: 30px;
                                            height: 13px;
                                            border-radius: 0.2em;
                                            background-color: #000;
                                        }
                                    }

                                    .block-picker {
                                        position: absolute !important;
                                        top: 2em;
                                        left: -4em;
                                        box-shadow: 0 0 30px -3px rgba(0, 0, 0, 0.2) !important;

                                        > div {
                                            input {
                                                display: none;
                                            }
                                        }
                                    }

                                    .action {
                                        .status {
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;

                                            .label {
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                border: solid 1px $lightGrey;
                                                padding: 0.3em 1em;
                                                margin-right: 1em;
                                                border-radius: 0.4em;
                                                color: $midGrey;

                                                span {
                                                    margin-left: 0.4em;
                                                    color: $midGrey;
                                                }
                                            }

                                            &.confirmed {
                                                .label {
                                                    border-color: $success;
                                                    color: $success;

                                                    span {
                                                        color: $success;
                                                    }
                                                }
                                            }

                                            &.cancelled {
                                                .label {
                                                    border-color: $error;
                                                    color: $error;

                                                    span {
                                                        color: $error;
                                                    }
                                                }
                                            }

                                            button {
                                                font-size: 0.9em !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .file_actions,
                    .date_actions {
                        .textField {
                            margin: 0;
                            border: none;

                            .icon {
                                width: 15%;
                            }
                        }

                        .react-select__control {
                            height: 35px;
                            min-height: 0;
                            border-radius: 0.3em;

                            .react-select__single-value {
                                margin-top: -5px;
                            }
                            .react-select__placeholder {
                                font-size: 0.8em;
                            }
                        }
                    }
                }
            }

            .details_actions {
                width: 30%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                .share_action,
                .save_action {
                    margin-right: 2em;
                    position: relative;
                    padding: 8px !important;
                    font-size: 0.8em !important;
                    background-color: $colorScheme1 !important;

                    svg {
                        font-size: 1.2em !important;
                        margin-right: 0.2em;
                    }
                    span {
                        height: 1.6em;

                        @media all and (max-width: 1450px) {
                            width: 100%;
                            overflow: hidden;
                        }
                    }
                }

                .save_action {
                    background-color: $colorScheme3 !important;
                }

                .closeButton {
                    margin-left: -1em;
                    background: transparent;
                    border: none;
                    cursor: pointer;

                    img {
                        width: 2.5em;
                    }
                }
            }

            @media all and (max-width: 991px) {
                width: 100vw;
                padding: 0.2em 1em;
            }
        }

        .infos {
            padding: 1em;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding-top: 8vh;
            height: 95vh;
            overflow-y: auto;

            .creation_action {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: solid 1px rgb(234, 234, 234);
            }
            .creation {
                flex: 1;
                color: rgb(76, 76, 76);
                font-weight: 500;
                margin: 1em 0 0 0;
            }
            .add_container {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 1em;

                button {
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    transition: 0.3s;
                    margin: 2px;
                    border-radius: 2px;

                    &:hover {
                        background: rgb(242, 242, 242);
                        svg {
                            color: $primary;
                        }
                    }
                }
            }
            .date {
                //color: rgb(195, 195, 195);
                font-weight: 500;
            }

            .input_title {
                font-size: 1.7em;
                border-radius: 0.2em;
                padding: 0.4em 0;
                font-weight: 600;
                border: solid 1px transparent;
                outline: none;
                transition: 0.3s;
                margin: 0.3em 0;

                &:hover {
                    border-color: $midGrey;
                    padding: 0.4em;
                }
                &.edit {
                    border-color: $darkGrey;
                    padding: 0.4em;
                }
            }

            .contributors {
                margin: 0;
                list-style: none;
                padding: 0;

                li {
                    margin: 0.5em 0;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    z-index: 1;

                    &:focus-within {
                        z-index: 2;
                    }

                    strong {
                        display: block;
                        width: 20%;
                        font-weight: 400;
                        font-size: 0.9em;
                    }

                    .action {
                        width: 80%;
                        .css-bpeome-MuiSvgIcon-root-MuiSelect-icon,
                        .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
                            right: 0;
                        }
                        .select,
                        .select_field {
                            width: 100%;
                            position: relative;
                            z-index: 2;
                        }

                        .colorPicker {
                            position: absolute !important;
                            z-index: 99;
                            box-shadow: 0 0 26px -3px rgba(0, 0, 0, 0.2) !important;
                            top: -4em;
                            left: 4em;

                            > div:first-child {
                                top: 30% !important;
                                left: -8% !important;
                                transform: rotate(-90deg);
                                margin-left: 0 !important;
                            }

                            span {
                                margin-left: 0;
                            }
                        }
                    }

                    span {
                        margin-left: 0.3em;
                    }

                    img {
                        width: 3em;
                        height: 3em;
                    }
                }
            }
            .row_color {
                .color_picker_box {
                    .colorPickerButton {
                        position: relative;
                        right: 10px;
                    }
                    .block-picker {
                        left: -5.4em;
                        top: 2.4em;
                        z-index: 4;
                    }
                }
            }
        }

        .input_title {
            position: relative;
            z-index: 2;
        }

        .event_general_details {
            position: relative;
            z-index: 4;

            &:hover {
                z-index: 3;
            }

            > .description {
                padding: 1em;
                background-color: $lightGrey;
                margin-bottom: 2em;
                max-height: 15em;
                overflow: auto;
            }
            .row {
                position: relative;
                z-index: 1;

                .col {
                    &:first-child {
                        width: 60%;
                        flex: 0.6;
                        //margin-top: -2em;

                        .editor {
                            margin-top: -2em;
                            margin-bottom: 2em;
                        }
                    }
                    &:nth-child(2) {
                        width: 40%;
                        flex: 0.4;
                    }

                    .description {
                        border: solid 1px $lightGrey;
                        padding: 0.2em;
                        min-height: 7em;
                        transition: 0.3s;
                        border-radius: 0.3em;
                        cursor: pointer;

                        p,
                        span {
                            margin: 1em;
                            color: $darkGrey;
                        }
                        span {
                            display: inline-block;
                        }

                        &:hover {
                            background-color: $lightGrey;
                            padding: 0.2em;
                        }
                    }

                    .item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 1em;
                        border-bottom: solid 1px $lightGrey;
                        padding-bottom: 1em;

                        &:last-child {
                            border-bottom: none;
                        }

                        strong {
                            font-size: 0.9em;
                            font-weight: 400;
                            width: 30%;
                        }

                        .action {
                            width: 70%;
                            transition: 0.3s;
                            padding: 0.2em 0;
                            border-radius: 0.3em;
                            cursor: pointer;

                            .MuiChip-filled {
                                background-color: rgba($colorScheme3, 0.3);
                                color: $colorScheme3;
                                margin-right: 3px;
                            }

                            .select_tags {
                                div {
                                    border: none;
                                    background-color: $lightGrey;

                                    span {
                                        background-color: #fff;
                                    }
                                    .rti--input {
                                        font-size: 0.9em;
                                        background-color: transparent;
                                    }

                                    .rti--tag {
                                        background-color: rgba($colorScheme3, 0.3);
                                        color: $colorScheme3;
                                        border-radius: 0.3em;

                                        span {
                                            background: transparent !important;
                                            color: $colorScheme3;
                                        }
                                    }
                                }
                            }

                            .empty_contributors {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                span {
                                    color: $midGrey;
                                }
                                a {
                                    text-align: right;
                                    color: $colorScheme3;
                                    text-decoration: none;
                                    font-size: 0.8em;

                                    button {
                                        transform: scale(0.85);
                                        font-size: 1em !important;
                                        padding: 0.2em 0.5em !important;

                                        svg {
                                            font-size: 1.2em !important;
                                        }
                                    }
                                }
                            }
                        }

                        &:hover {
                            .action {
                                background-color: $lightGrey;
                            }
                        }
                    }
                }
            }
        }

        .eventDate {
            border: solid 1px rgb(219, 219, 219);
            border-radius: 0.3em;
            padding: 1em;
            position: relative;

            /** Color Picker */

            .event_details {
                border-bottom: solid 1px rgb(229, 229, 229);
                text-align: right;
                padding-bottom: 0.3em;
                margin-bottom: 0.3em;

                .row {
                    align-items: center;
                    justify-content: flex-end;
                }

                strong {
                    font-weight: 400;
                    font-size: 0.9em;
                    color: grey;
                }
            }

            .actions {
                position: absolute;
                right: 1em;
                bottom: 1em;

                button {
                    margin-left: 0.5em;
                    background-color: transparent;
                    border-radius: 0.3em;
                }
            }
            strong {
                font-size: 1.2em;
                color: #444;
            }

            .row {
                margin-bottom: 1em;
            }

            label {
                font-weight: 400;
                font-size: 0.9em;
                margin-bottom: 0.4em;
            }

            .date {
                font-size: 2em;
                color: #222;
            }

            .meeting {
                display: flex;
                align-items: center;

                svg {
                    margin: 0 10px;
                }
            }
            p {
                font-weight: 400;
                color: #444;
            }

            @media all and (max-width: 991px) {
                .actions {
                    position: relative;
                    margin-bottom: 0.4em;
                    text-align: center;
                }
            }
        }

        .content,
        .medias {
            padding-top: 1em;
            position: relative;
            z-index: 2;

            .fileList {
                span {
                    display: block;
                    color: grey;
                    font-size: 0.9em;
                }

                a {
                    color: $primary;
                    margin: 0.3em 0;
                    display: block;
                }
            }
        }

        .medias {
            z-index: 1;
        }

        .content,
        .description {
            strong,
            h3 {
                font-weight: 500;
            }

            ul {
                li {
                    p {
                        margin: 0.1em 0;
                    }
                }
            }
        }

        .medias {
            .tabs {
                .header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    height: auto;
                    margin-bottom: 1em;

                    .tab_title {
                        color: $third;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 4px 10px;
                        border-radius: 4px;
                        margin-right: 30px;
                        cursor: pointer;

                        &:hover {
                            background-color: $lightGrey;
                        }

                        .count {
                            display: flex;
                            padding: 4px;
                            background-color: $third;
                            color: #fff;
                            width: 20px;
                            height: 15px;
                            border-radius: 3px;
                            margin-left: 10px;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                        }

                        &.active {
                            background-color: $third;
                            color: #fff;

                            .count {
                                background-color: #fff;
                                color: $third;
                            }

                            &:hover {
                                background-color: $third;
                            }
                        }
                    }
                }

                .content {
                    .item {
                        position: relative;
                        opacity: 0;
                        visibility: hidden;
                        height: 0;

                        &.active {
                            visibility: visible;
                            opacity: 1;
                            height: auto;
                        }
                    }
                }
            }
            .items {
                border: solid 1px $lightGrey;
                border-radius: 4px;
                margin-bottom: 10px;
                max-height: 50vh;
                overflow: auto;

                .emptyMessage {
                    padding: 10px;
                    font-size: 1.2em;
                }

                .file {
                    padding: 10px 5px;
                    padding: 7px 10px;
                    border-bottom: solid 1px $lightGrey;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    &:last-child {
                        border-bottom: none;
                    }

                    a {
                        flex-grow: 1;
                        display: inline-flex;
                        align-items: center;

                        svg {
                            margin-right: 10px;
                            color: $darkGrey;
                        }
                    }

                    button {
                        cursor: pointer;
                        background: transparent;
                        border: none;
                        transition: 0.3s;
                        color: $primary;

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }

        .content {
            .item .items div {
                display: flex;
                flex-direction: row;
                a {
                    margin: 1px;
                }
            }
        }
    }

    @media all and (max-width: 1450px) {
        .eventDetails,
        .details_header {
            width: 65vw !important;
        }
    }

    @media all and (max-width: 991px) {
        .eventDetails,
        .details_header {
            width: 100% !important;
        }
    }
}

.event_menu_title {
    text-align: center;
    color: rgb(185, 185, 185);
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    border-bottom: solid 1px rgb(240, 240, 240);
    padding: 0 0.5em 0.5em 1em;

    svg {
        margin-right: 0.4em;
    }
}

.clip-content {
    position: relative;
    width: 180px;
    height: 120px;
    display: flex;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        svg {
            font-size: 4em;
            transition: 0.15s;
        }

        &:hover {
            svg {
                font-size: 4.4em;
            }
        }
    }

    .clip-thumbnail {
        position: relative;
        background-color: #eee;
        border-radius: 5px;
        z-index: 1;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
        }
        &::after {
            content: "";
            display: block;
        }
    }

    button {
        cursor: pointer;
        position: absolute;
        top: 2px;
        right: 2px;
        color: $primary;
        border: none;
        background-color: transparent;
        z-index: 3;
        border-radius: 100px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
    }
}
