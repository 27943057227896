.editor {
    position: relative;
    .title {
        position: absolute;
        font-size: 0.9em;
        top: 0.3em;
        color: rgb(154, 154, 154);
    }
    .menubar {
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: 0.3s;
        opacity: 0;
        transform: translateY(0.3em);
        background-color: #fff;

        .menu_group {
            position: relative;
            z-index: 1;
            display: inline-block;
            border-left: solid 1px rgb(204, 204, 204);
            box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);

            &:first-child {
                border-left: none;
            }
            button {
                background: transparent;
                border: none;
                margin: 0 0.3em;
                padding: 0 0.2em;
                font-size: 0.7em;
                transition: 0.3s;
                cursor: pointer;

                svg {
                    transform: scale(0.8);
                    transition: 0.3s;
                    fill: #202020;
                }

                &:hover {
                    svg {
                        fill: $primary;
                    }
                }

                &:disabled {
                    cursor: default;
                    svg {
                        fill: #c1c1c1;
                    }

                    &:hover {
                        svg {
                            fill: #c1c1c1;
                        }
                    }
                }

                &.open_color_nav.active,
                &.is-active {
                    background-color: rgb(233, 233, 233);
                }
            }
        }

        .color_nav {
            position: relative;
            z-index: 0;
            transform: translateX(-0.4em);
            transition: 0.3s;
            visibility: hidden;
            opacity: 0;

            &.open {
                visibility: visible;
                transform: translateX(0);
                opacity: 1;
            }

            button {
                padding: 0 0.3em;
                border: none;
                background: transparent;
                cursor: pointer;

                div {
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                    transition: 0.3s;
                }

                &:hover {
                    div {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &.min {
            .color_nav {
                width: 25%;
            }
        }
    }

    .ProseMirror p.is-editor-empty:first-child:before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }

    .ProseMirror {
        padding: 0.3em 1em;
        border: solid 1px rgb(222, 222, 222);
        transition: 0.3s;
        border-radius: 0 0.4em 0.4em;
        outline: none;
        min-height: 6em;
        max-height: 20em;
        overflow: auto;

        p{
            text-align: start !important;
        }

        p,
        strong,
        span {
            color: #373737;
        }

        strong {
            font-weight: 500;
            font-size: 1em;
        }

        h3 {
            font-weight: 600;
            strong {
                font-weight: 600;
            }
        }
    }

    &:focus-visible,
    &:hover,
    &:focus-within {
        .menubar {
            opacity: 1;
            transform: translateY(0);
        }

        .ProseMirror {
            outline: none;
            border-color: $colorScheme4;
        }
    }
}
