.upgradeDialog {
    text-align: center;
}
.modal {
    position: fixed !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9;
    top: 0;
    left: 0;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-2em);
    opacity: 0;
    visibility: hidden;

    &.open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    &.hidden{
        z-index: -1;
    }

    .overlay {
        position: absolute;
        background-color: rgba($secondary, 0.9);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .closeModal {
        position: absolute;
        top: 0.75em;
        right: 0.75em;
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 2em;

        svg {
            transform: scale(0.8);
        }
    }

    .content {
        position: relative;
        width: 1200px;
        max-width: 90%;
        min-height: 40%;
        padding: 2em 2em 5em 2em;
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
        border-radius: 0.3em;

        .title {
            span,
            strong {
                display: block;
            }

            span {
                color: grey;
            }
            strong {
                color: $primary;
                font-size: 2em;
            }
        }

        .row {
            &.sep {
                margin: 1em 0;
            }
            .col {
                > div,
                input {
                    width: 100%;
                }
            }
        }
        .add_file_button {
            float: right;
            margin-top: -2em;
        }

        .MuiAlert-root {
            position: absolute;
            bottom: 2em;
            left: 2em;
        }

        .action_button {
            position: absolute;
            bottom: 2em;
            right: 2em;
            padding: 5px 20px;

            span {
                width: 25px !important;
                height: 25px !important;
            }
            svg {
                color: #fff;
                transform: scale(0.6);
            }
        }

        textarea {
            width: 100%;
            min-height: 10em;
            max-width: 100%;
            min-width: 100%;
            padding: 1em;
            box-sizing: border-box;
            border: solid 1px rgb(208, 208, 208);
            border-radius: 0.3em;
            max-height: 30em;
        }
    }
}

.videoModal {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
    }
}
