.flow {
    padding-top: 2em;
}
.scrollhandler {
    height: 77vh;
    overflow: auto;
    padding-top: 1em;
    padding-right: 2em;
    width: 100.3%;
    padding-bottom: 0;
    position: relative;

    @media all and (max-width: 1450px) {
        height: 64vh;
    }

    .loading {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 9;
    }
}

.event_flux {
    padding-top: 2em;
    > div {
        margin-bottom: 3em;
    }
    > div:last-child {
        margin-bottom: 6em;
    }

    .day_line {
        font-size: 1.5em;
        color: $colorScheme3;
        display: block;
        margin-bottom: 0.4em;

        &.hide {
            opacity: 0;
            margin: 0;
            height: 0;
        }
    }
    .event_flux_item {
        background: #fff;
        padding: 5px 25px 10px 60px;
        box-sizing: border-box;
        border-radius: 0.3em;
        margin-bottom: 0.5em;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            box-shadow: 0px 3px 20px -8px rgba(0, 0, 0, 0.2);
        }

        .event_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: solid 1px $lightGrey;
            padding-bottom: 1px;
            margin-bottom: 5px;

            .type {
                > div {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #000;
                    font-size: 0.5em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    svg {
                        transform: scale(0.8);
                    }
                }

                &.video > div {
                    background-color: $colorScheme1;
                }
                &.text > div {
                    background-color: $colorScheme2;
                }
                &.picture > div {
                    background-color: $colorScheme3;
                }
                &.file > div {
                    background-color: $colorScheme4;
                }
                &.sound > div {
                    background-color: $colorScheme5;
                }
                &.date > div {
                    background-color: $colorScheme8;
                }
                &.task > div {
                    background-color: $colorScheme7;
                }
                &.mail > div {
                    background-color: $midGrey;
                }
            }

            .event_action {
                flex-grow: 1;
                width: 70%;
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                    margin-left: 0.2em;
                }

                .title{
                    font-weight: 600;
                    font-size: 1rem;
                }
            }
            .file_type {
                width: 15%;
                font-size: 0.9em;
                text-align: left;
                position: relative;
                top: 1px;
                margin-left: 0.5em;

                span {
                    display: inline-block;
                    padding: 0.3em;
                    border-radius: 0.3em;
                    box-sizing: border-box;

                    &:first-child {
                        text-align: center;
                        width: 5.5em;
                    }
                    &:last-child {
                        font-weight: 500;
                        color: $colorScheme3;
                        text-align: right;
                    }
                }

                &.estimate {
                    span {
                        &:first-child {
                            background-color: $colorScheme7;
                            color: $colorScheme2;
                        }
                    }
                }
                &.invoice {
                    span {
                        &:first-child {
                            background-color: rgba($colorScheme1, 0.3);
                            color: $colorScheme5;
                        }
                    }
                }
            }
            .event_created {
                color: black;
                flex: auto;
                font-size: 0.9em;
                font-weight: bold;
                background-color: $lightGrey;
                padding: 2px 5px;
                border-radius: 0.3em;
                flex: 0.03;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.4em;
                margin: 0;
                position: relative;
                top: 1px;

                &.mail {
                    flex: 0.18;
                    margin-left: 5px;
                    span {
                        margin: 0 3px;
                    }
                }
            }

            .tags {
                width: 10%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                margin-top: 10px;
                @media all and (max-width: 991px) {
                    width: 13%;
                }

                .MuiChip-root {
                    margin: 0 0.1em;
                }
                .MuiChip-filled {
                    background-color: rgba(0, 0, 0, 0.08);
                    color: $colorScheme3;
                }

                .tagsLeft {
                    margin-left: 5px;
                    position: relative;
                    left: -2px;
                    color: $colorScheme3;
                }
                .no_tag {
                    display: block;
                    color: $midGrey;
                    font-size: 0.8em;
                    text-align: right;
                    margin-top: -10px;

                    @media all and (max-width: 991px) {
                        font-size: 0.6em;
                    }
                }
            }
            .contributors {
                width: 10%;
                justify-content: flex-end;

                @media all and (max-width: 991px) {
                    width: 13%;
                }

                .avatars {
                    text-align: right;
                    align-items: center;
                    justify-content: flex-end;
                    position: relative;

                    margin-top: 10px;

                    &.l4 {
                        left: 30px;
                    }

                    &.l3 {
                        left: 20px;
                    }

                    &.l2 {
                        left: 10px;
                    }

                    span {
                        position: relative;
                        left: 0;
                    }

                    .contractor_thumbnail {
                        width: 25px;
                        height: 25px;
                        font-size: 0.7em;
                    }
                }

                .no_contractor {
                    display: block;
                    color: $midGrey;
                    font-size: 0.8em;
                    text-align: right;

                    @media all and (max-width: 991px) {
                        font-size: 0.6em;
                    }
                }
            }
            .comments {
                width: 10%;
                text-align: right;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 0;

                @media all and (max-width: 991px) {
                    width: 13%;
                }

                svg {
                    margin-left: 0.1em;
                    width: 15px;
                }

                span {
                    font-size: 0.8em;
                    font-weight: 500;
                    margin-top: -2px;

                    @media all and (max-width: 991px) {
                        font-size: 0.6em;
                    }
                }
                &.empty {
                    color: $midGrey;
                }
            }
        }

        .event_content {
            display: flex;
            flex-direction: row;
            align-items: center;

            width: 83%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 0.3em;
            flex-grow: 1;

            > div {
                display: flex;
                align-items: center;
            }
            @media all and (max-width: 1450px) {
                width: 70%;
            }

            a {
                display: inline-block;
                padding: 0.1em 0.3em;
                font-size: 0.8em;
                color: $primary;
                margin-right: 0.3em;
            }

            img {
                width: 180px;
                height: 120px;
                object-fit: cover;
                border-radius: 0.3em;
                display: inline-block;
                margin-right: 0.3em;
                //border: solid 1px #c3c3c3;
            }

            span {
                background-color: $lightGrey;
                padding: 2px 10px;
                border-radius: 5px;
                font-weight: bold;
            }
            
            .main_content {
                width: 80%;

                .title {
                    font-weight: 500;
                    flex-grow: 1;
                }
                .description {
                    p {
                        margin: 0;
                    }
                }

                .files {
                    margin-top: 5px;
                    color: $midGrey;
                }
            }
        }

        .event_header .type,
        .event_content .comments {
            margin-left: -42px;
            margin-right: 15px;
        }
        .event_footer {
            display: flex;
            flex-direction: row;
            align-items: center;

            .content {
                width: 83%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 0.3em;
                flex-grow: 1;

                > div {
                    display: flex;
                    align-items: center;
                }
                @media all and (max-width: 1450px) {
                    width: 70%;
                }

                a {
                    display: inline-block;
                    padding: 0.1em 0.3em;
                    font-size: 0.8em;
                    color: $primary;
                    margin-right: 0.3em;
                }

                img {
                    width: 180px;
                    height: 120px;
                    object-fit: cover;
                    border-radius: 0.3em;
                    display: inline-block;
                    margin-right: 0.3em;
                    //border: solid 1px #c3c3c3;
                }

                span {
                    background-color: $lightGrey;
                    padding: 2px 10px;
                    border-radius: 5px;
                    font-weight: bold;
                }
            }
        }
        .medias {
            display: flex;
            flex-direction: row;
            margin-top: 4px;
            span {
                margin-right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $darkGrey;
                background-color: $lightGrey;
                padding: 2px 10px;
                border-radius: 5px;
                font-weight: bold;

                svg {
                    font-size: 1.4em;
                    margin-right: 3px;
                }
            }
        }
    }
}

.media_item {
    &:nth-of-type(odd) {
        background: #fafafa;
    }
    border-bottom: solid 1px #e1e1e1;

    background: #fff;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    .type {
        margin-left: 15px;
        margin-right: 15px;

        > div {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #000;
            font-size: 0.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            svg {
                transform: scale(0.8);
            }
        }

        &.video > div {
            background-color: $colorScheme1;
        }
        &.picture > div {
            background-color: $colorScheme3;
        }
        &.sound > div {
            background-color: $colorScheme5;
        }
    }

    .date {
        color: black;
        font-size: 0.9em;
        font-weight: bold;
        padding: 2px 5px;
        border-radius: 0.3em;
        width: 100px;
    }

    .tags {
        width: 10%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .MuiChip-root {
            margin: 0 0.1em;
        }
        .MuiChip-filled {
            background-color: rgba($colorScheme3, 0.3);
            color: $colorScheme3;
            border-radius: 0.3em;
        }

        .tagsLeft {
            margin-left: 5px;
            position: relative;
            left: -2px;
            font-size: 0.9em;
            color: $colorScheme3;
        }
        .no_tag {
            display: block;
            color: $midGrey;
            font-size: 0.8em;
            text-align: right;
            margin-top: -10px;
        }
    }

    .title {
        display: flex;
        width: 30%;

        .main_content {
            width: 80%;

            .title {
                font-weight: 500;
                flex-grow: 1;
            }
            .description {
                p {
                    margin: 0;
                }
            }
        }
    }

    .content {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 0.3em;
        flex-grow: 1;

        @media all and (max-width: 1450px) {
            width: 70%;
        }

        a {
            display: inline-block;
            padding: 0.1em 0.3em;
            font-size: 0.8em;
            color: $primary;
            margin-right: 0.3em;
        }

        img {
            width: 80px;
            height: 51px;
            object-fit: cover;
            border-radius: 0.3em;
            display: inline-block;
            margin-right: 0.3em;
            //border: solid 1px #c3c3c3;
        }

        span {
            color: $midGrey;
        }
    }
}

.listMedia {
    a {
        text-decoration: none;
    }
    .listMedia_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
            margin-left: 0.5em;
            text-decoration: none;
        }
    }
}

.mail .event_flux {
    height: 80%;
    overflow-y: auto;
    padding-top: 2.5em;
}
