.login_container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;

    .form {
        width: 40%;
        height: 100%;
        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        .content {
            width: 70%;
            min-height: 50%;
            padding: 2em;
            box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);

            .password_strength {
                display: flex;
                flex-direction: row;
                align-items: center;

                .label {
                    font-size: 0.9em;
                    color: $midGrey;

                    &.weak {
                        color: $colorScheme1;
                    }
                    &.medium {
                        color: rgb(255, 162, 0);
                    }
                    &.strong {
                        color: rgb(63, 117, 39);
                    }
                }
                .bar {
                    width: 90px;
                    margin-right: 5px;

                    

                    .strength {
                    
                        width: 100%;
                        height: 0.5em;
                        border-radius: 0.5em;
                        background-color: $lightGrey;
                        position: relative;
                        transition: 0.3s;
                        margin-right: 1em;

                        color: $lightGrey;

    
                        &::after {
                            content: "";
                            display: block;
                            width: 0%;
                            height: 100%;
                            border-radius: 0.5em;
                            background-color: $primary;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: 0.3s;
                        }
    
                        &.weak {
                            &::after {
                                width: 33%;
                                background-color: $colorScheme1;
                            }
                        }
                        &.medium {
                            &::after {
                                width: 66%;
                                background-color: rgb(255, 191, 0);
                            }
                        }
                        &.strong {
                            &::after {
                                width: 100%;
                                background-color: rgb(79, 191, 31);
                            }
                        }
                    }
                }

                
            }

            .switch_account_action {
                text-align: center;
                margin-bottom: 2em;

                strong {
                    display: block;
                    font-size: 1.8em;
                    margin-bottom: 0.3em;
                    font-weight: 600;
                    color: rgb(37, 37, 37);
                }

                span,
                a {
                    margin-top: 1em;
                    font-size: 1.5em;
                    display: block;

                    i {
                        font-weight: bold;
                        font-style: normal;
                    }
                }

                a {
                    color: $primary;
                    margin-left: 12px;
                }

                &.success {
                    svg {
                        fill: rgb(79, 191, 31);
                        font-size: 5em;
                        margin-top: 1em;
                        margin-bottom: 0.2em;
                    }
                }
            }

            .fields {
                margin: 0.4em 10%;
                fieldset {
                    border-color: rgb(238, 238, 238);
                    border-width: 3px;
                }

                &.center {
                    text-align: center;
                }
            }

            .actions {
                padding: 0em 0 2em 0;

                a {
                    color: rgb(87, 82, 103);
                    font-size: 0.9em;
                    float: right;
                    text-align: left;
                    transition: 0.3s;

                    &:hover {
                        color: $primary;
                    }
                }

                button {
                    margin-top: 1em;
                    //background-color: $primary;

                    svg {
                        color: #fff;
                        transform: scale(0.4);
                    }

                    &.Mui-disabled {
                        svg {
                            color: $secondary;
                        }
                    }
                }

                .error {
                    margin-top: 1em;
                }
            }

            .connect_with {
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(119, 119, 119);
                font-size: 0.9em;
                font-weight: 500;

                &::after,
                &::before {
                    content: "";
                    display: block;
                    width: 4em;
                    height: 2px;
                    background-color: rgb(218, 218, 218);
                    margin: 1em;
                }
            }
            .oauth_connect {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1em;
                transition: 0.3s;

                span {
                    color: $midGrey;
                    margin-right: 0.5em;
                }
                .login_button {
                    cursor: pointer;
                    margin: 0 1em;
                    background-color: #eee;
                    border-radius: 5px;
                    width: 45px;
                    height: 45px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &.twitter {
                        color: $twitter;
                    }
                    &.google {
                        background-color: $google;
                        color: #fff;
                    }
                    &.facebook {
                        background-color: $facebook;
                        color: #fff;
                    }
                    &.apple {
                        background-color: #222;
                        color: #fff;
                    }

                    svg {
                        transition: 0.3s;
                        transform: scale(1.4);
                    }

                    &:hover {
                        svg {
                            opacity: 0.8;
                            //                            transform: scale(1.5);
                        }
                    }
                }
            }
        }
    }

    .presentation {
        width: 60%;
        height: 100%;
        background-size: cover;
        background-position: center;
        flex-direction: column;
        position: relative;
        display: flex;
        align-items: center;

        &:before {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            content: "";
            //background-color: rgba(0, 0, 0, 0.8);
            //background-color: rgba(255,255,255, 0.8);
        }
        img {
            width: 15em;
            position: relative;
            z-index: 2;
            margin-top: 3em;
        }
    }

    @media all and (max-width: 991px) {
        flex-direction: column-reverse;
        .presentation {
            width: 100%;
            height: 30%;
            display:none;
            img {
                max-width: 10em;
            }
        }
        .form {
            width: 100%;
            height: 100%;

            img {
                max-width: 60%;
            }
        }
    }
}

.cgu_accept {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    margin-top: 1em !important;

    span {
        font-size: 1em;
        color: $darkGrey;
    }

    a {
        font-size: 0.8em;
        color: $primary;
        margin-left: -0.8em;
    }
}

.actions_validate_form {
    display: flex;
    flex-direction: row;

    .textField {
        margin: 1em 0 0 0 !important;
    }
    button {
        svg {
            margin: 0 !important;
            fill: rgb(55, 55, 55) !important;
            position: absolute;
            right: 0;
            top: -0.3em;
            z-index: 2;
        }
    }

    .validate_btn {
        width: 150px;
        margin-left: 10px;
    }

    @media all and (max-width: 991px) {
        flex-direction: column;

        .textField {
            margin: 20px 0 !important;
        }

        .validate_btn {
            width: 100%;
            margin: 0;
        }
    }
}
