.recharts-label-list {
    font-size: 1.2em !important;
    text {
        stroke: none !important;
    }
}

.pie {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.pieLegend {
    display: flex;
    flex-direction: column;

    .line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5em;

        .color {
            width: 10px;
            height: 10px;
            border-radius: 20px;
            margin-right: 10px;
        }

        .value {
            margin-right: 10px;
            font-weight: bold;
        }
    }
}


.table_stats{
    .head,.content .row{
        display: flex;
        flex-direction: row;
        align-items: center;

        strong,span{
            flex:.333;
            padding:5px;

            &.right{
                text-align: right;
            }
        }
    }

}