.textField {
    border: solid 1px rgb(222, 222, 222);
    border-radius: 0.2em;
    width: 100%;
    position: relative;
    margin: 1em 0;
    z-index: 1;

    label {
        position: absolute;
        color: rgb(199, 199, 199);
        padding: 0 0.3em;
        z-index: 0;
        left: 15px;
        height: 100%;
        font-size: 0.8em;
        transition: 0.3s;
        top: 0;
        display: flex;
        align-items: center;
    }

    input {
        border: none;
        outline: none;
        width: 94%;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        background: transparent;
        height: 100%;
        padding: 0.8em 15px;
        font-size: 0.95em;
        letter-spacing: 0.2px;
        font-weight: 400;
        color: rgb(35, 35, 35);
    }

    button,
    .icon {
        width: 6%;
        height: 100%;
        text-align: right;
        border: none;
        background: transparent;
        font-size: 0.8em;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            transform: scale(0.7);
            transition: 0.3s;
            fill: grey;
        }
    }
    button :hover {
        cursor: pointer;
        svg {
            fill: $primary;
        }
    }

    &:focus,
    &:focus-visible,
    &:focus-within,
    &.complete {
        label {
            transform: scale(0.85) translateY(-67%) translateX(-10%);
            color: $colorScheme2;
            background-color: #fff;
            height: auto;
        }
    }

    &.error {
        border: solid 1px $error;
        background-color: rgb(246, 237, 236);
        color: $error;
        label {
            color: $error;
            background-color: rgb(246, 237, 236);
            height: auto;
        }
    }

    &.disabled {
        label,
        input {
            color: $midGrey;
        }

        input {
            font-style: italic;
        }
    }
}

.select_element {
    .css-1dimb5e-singleValue {
        color: $secondary;
    }
    &.assigned {
        .css-1dimb5e-singleValue {
            //color: $assigned;
        }
    }
    &.started {
        .css-1dimb5e-singleValue {
            //color: $start;
        }
    }
    &.review {
        .css-1dimb5e-singleValue {
            //color: $review;
        }
    }
    &.done {
        .css-1dimb5e-singleValue {
            //color: $done;
        }
    }

    &.low {
        .css-1dimb5e-singleValue {
            //color: $low;
        }
    }

    &.mid {
        .css-1dimb5e-singleValue {
            //color: $mid;
        }
    }

    &.high {
        .css-1dimb5e-singleValue {
            //color: $high;
        }
    }
}

.react-select__control {
    &.react-select__control--is-focused {
        border-color: $primary !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .react-select__option--is-selected {
        background-color: $primary !important;
        color: #fff !important;
    }
    .react-select__menu-list {
        .react-select__option {
            &.option--is-selected,
            .option--is-selected {
                background-color: $primary !important;
                color: #fff !important;
            }
        }
    }
}
