.navigation {
    flex: 0.08;
    width: 8vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 2em;
    display: none;

    @media all and (max-width: 1450px) {
        flex: 0.1;
        width: 10vw;
    }
    @media all and (max-width: 991px) {
        flex: 0.2;
        width: 20vw;
    }

    .app_icon {
        max-width: 50%;
        margin-top: 2em;
        margin-bottom: 4em;
    }

    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            margin: 4em 0;
            border: none;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            align-items: center;

            img {
                width: 50px;
                height: 50px;
                transition: 0.3s;
            }

            svg {
                color: $midGrey;
                transition: 0.3s;
                font-size: 2.4em;
            }

            span {
                color: $midGrey;
            }
            &:hover {
                svg {
                    color: $colorScheme3;
                }
            }
            &.active {
                svg {
                    color: $colorScheme2;
                }

                span {
                    color: $colorScheme2;
                }
            }

            @media all and (max-width: 1450px) {
                margin: 2.5em 0;
            }
        }
    }

    @media all and (max-width: 768px) {
        width: 5em;
    }
}

.account_actions_menu {
    position: absolute;
    top: 6em;
    right: 2em;
    z-index: 4;

    .logout {
        color: $colorScheme1 !important;
    }

    .used_space {
        background-color: #fff;
        padding: 10px 0;
        border-bottom: solid 1px $lightGrey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            color: $midGrey;
        }
        .space {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;

            .progress {
                width: 50%;
                height: 5px;
                background-color: $lightGrey;
                border-radius: 5px;
                position: relative;
                margin-right: 10px;

                .progress_bar {
                    height: 5px;
                    background-color: $colorScheme1;
                    border-radius: 5px;
                }
            }
        }
    }
}

.app_icon {
    width: 70px;
    margin-right: 2rem;
    margin-top:3px;
}


.right_user_actions{
    display: flex;
    flex-direction: row;
    align-items: center;

    .settings_link {
        color: rgba($secondary, 0.5) !important;
        transition: 0.3s;
        margin-right:30px;
    
        &:hover {
            color: rgba($secondary, 0.9) !important;
        }
    }

    
}

.userConnected {
    position: relative;
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 0.6em;
    border-radius: 0.3em;

    &:hover {
        box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
    }

    img {
        width: 45px;
        height: 45px;
        border-radius: 3em;
        object-fit: cover;
        background-color: $midGrey;
        margin-right: 0.5em;
    }

    .infos {
        display: flex;
        flex-direction: column;

        span {
            color: #d0d0d0;
            font-weight: 300;
        }
    }

    .moreIcon {
        margin-left: 1em;
        transition: 0.3s;
    }

    &.open {
        .moreIcon {
            transform: rotate(180deg);
        }
    }
}

.userInfos {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1em 0.4em;
    background-color: #fafafa;
    margin-top: -0.6em;
    margin-bottom: 0.6em;

    img {
        width: 60px;
        height: 60px;
        border-radius: 3em;
        object-fit: cover;
        background-color: $midGrey;
        margin-right: 0.5em;
    }

    .infos {
        display: flex;
        flex-direction: column;

        strong {
            font-size: 1.1em;
        }
        span {
            color: #d0d0d0;
            font-weight: 400;
        }
    }
}

.add_to_project {
    background-color: $primary !important;
    width: 200px;
    margin-left: 40px;
    font-size: 1em !important;
    padding: 5px 0 !important;

    @media all and (max-width: 1450px) {
        width: 220px;
    }

    @media all and (max-width: 991px) {
        width: 180px;
        font-size: 0.9em !important;
        padding: 5px 0 !important;
    }

    &.Mui-disabled {
        background-color: #e2e2e2 !important;
    }
}

.add_to_project_min {
    background-color: $primary !important;
    color: #fff !important;
    font-size: 0.9em !important;
    &.Mui-disabled {
        background-color: #e2e2e2 !important;
    }
}

.only_add {
    .overlay {
        background-color: rgba($secondary, 0.9) !important;
    }
}

.multi_add {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    .multi_add_button {
        display: flex;
        flex-direction: row;

        .add_to_project {
            margin-left: 15px;

            &.meeting {
                width: 250px;
            }
            &.date {
                width: 270px;
            }
        }
    }
}
