.filters {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;

    &.title_actions {
        margin-top: 0px;
        margin-bottom: -30px;
        background-color: $lightGrey;
        position: relative;
        z-index: 3;
        padding: 8px 0px;

        .switch {
            border: none;
            display: flex;
            flex-direction: row;
            align-items: center;

            button {
                border: none;
            }
        }
    }

    &.container {
        margin-top: 5px;
        background: #fff;
        border-radius: 5px;
        padding: 5px 15px;
        height: 65px;
        align-items: center;
        justify-content: flex-end;

        box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);

        .filtersActions {
            .field {
                position: relative;
                height: 42px;
                width: 100%;
                label {
                    font-size: 0.9em;
                    text-align: left;
                    top: -33px;
                    position: absolute;
                    z-index: 1;
                    font-weight: bold;
                    background: #fff;
                    padding: 3px 15px;
                    left: -5px;
                    border-radius: 5px;
                }
            }
            .search_input {
                position: relative;
                width: 40%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }

            .search_contractors {
                width: 22.5%;
                border-right: solid 1px #ccc;
                padding: 0 15px;
            }
            .search_tags {
                width: 22.5%;
                padding: 0 15px;
            }

            .search_filters {
                width: 190px;
                border: none;
                background: transparent;
                cursor: pointer;
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border: solid 2px $colorScheme3;
                padding: 7px;
                border-radius: 4px;

                span.label {
                    min-width: 150px;
                    color: $colorScheme3;
                    font-weight: 600;
                }
                img {
                    width: 1.2em;
                    transition: 0.3s;
                }

                .amount {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    width: 20px;
                    height: 20px;
                    background-color: $primary;
                    color: #fff;
                    top: -10px;
                    right: -10px;
                }
                &.active {
                    opacity: 1;
                }
            }
        }
    }
    &.with_spacer {
        padding-top: 1em;
    }
    h3 {
        font-size: 1.5em;
        font-weight: 600 !important;
        margin-right: 1em;
    }
    .title {
        width: 30%;
        margin: 0 !important;
        &.spacer {
            flex-grow: 1;
        }
        &.compact {
            width: auto !important;
        }
        @media all and (max-width: 991px) {
            font-size: 1.2em;
        }
    }

    .shareButton {
        font-size: 0.9em;
        padding: 0.5em 1em;
        color: $colorScheme1;
        cursor: pointer;
        width: 220px;

        svg {
            font-size: 1em;
            margin-right: 0.5em;
            color: $colorScheme1;
        }
    }

    button.switchBtn {
        border: none;
        background: transparent;
        cursor: pointer;
        position: relative;
        opacity: 0.4;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: solid 2px $colorScheme3;
        padding: 7px;
        border-radius: 4px;

        span.label {
            min-width: 125px;
            color: $colorScheme3;
        }
        img {
            width: 1.5em;
            transition: 0.3s;
        }

        .amount {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            width: 20px;
            height: 20px;
            background-color: $primary;
            color: #fff;
            top: -10px;
            right: -10px;
        }
        &.active {
            opacity: 1;
        }
    }
    .switch {
        width: 150px;
        button {
            margin-right: 10px;
        }

        @media all and (max-width: 768px) {
            width: 40%;
        }
    }
    .spacer {
        width: 70%;
    }
    .filtersActions {
        width: 100%;
        text-align: right;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        @media all and (max-width: 991px) {
            margin-right: 0px;
        }

        .contractor_filters {
            width: 100%;
        }
        .contractor_filters__control {
            width: 100%;
            border: solid 1px $lightGrey;
        }

        .contractor_filters__placeholder {
            font-size: 0.9em;
        }

        .MuiAutocomplete-root {
            background: #fff;
            border: solid 1px $lightGrey;
            border-radius: 5px;

            .MuiInputBase-root {
                padding: 3px !important;
            }
            fieldset {
                border-color: $lightGrey !important;
            }
        }

        .search_event {
            position: relative;
            width: 100%;
            border: none;
            text-align: left;
            background-color: #fff;
            margin-right: 1em;
            outline: none;
            padding: 10px;
            border-right: solid 1px #ccc;
            box-sizing: border-box;
            color: $colorScheme3;
            font-weight: 400;
            font-size: 1.1em;

            .MuiInputLabel-outlined {
                top: -8px;

                &.Mui-focused,
                &.MuiInputLabel-shrink {
                    top: 0;
                }
            }

            input {
                padding: 0.5em !important;
            }
        }
        .enter_message {
            position: absolute;
            display: flex;
            align-items: center;
            font-size: 0.8em;
            z-index: 1;
            right: 25px;
            color: $midGrey;
            transition: 0.3s;

            button {
                margin-left: 5px;
                transition: 0.2s;
                border: none;
                background-color: $colorScheme4;
                font-weight: bold;
                padding: 5px;
                border-radius: 4px;
                color: #fff;
                cursor: pointer;

                &:hover {
                    background-color: $colorScheme3;
                }
            }
            svg {
                margin: 0 0.3em;
                background: $lightGrey;
                padding: 0 0.3em;
                font-size: 1.2em;
            }

            &.empty {
                opacity: 0;
                visibility: hidden;
                z-index: -1;
            }
        }

        .filtersBox {
            position: absolute;
            background: #fff;
            padding: 2em;
            right: 0;
            min-width: 30em;
            min-height: 15em;
            z-index: -1;
            transition: 0.3s;
            opacity: 0;
            top: 3.5em;
            visibility: hidden;
            transform: translateY(1em);
            box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
            border-radius: 0.3em 0 0.3em 0.3em;

            &.open {
                opacity: 1;
                visibility: visible;
                z-index: 9;
                transform: translateY(0);
            }

            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: auto;

                h3 {
                    width: 70%;
                    margin: 0;
                }
                button {
                    width: 30%;
                    transition: 0.3s;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .clear {
                border: none;
                background: transparent;
                cursor: pointer;
            }

            h3 {
                text-align: left;
                font-weight: 400;
                color: rgb(171, 171, 171);
                margin-top: 0;
            }

            .filterLabel {
                display: block;
                text-align: left;
                font-size: 0.9em;
                margin: 0.5em 0 1em 0;
                color: grey;
            }

            .dateFilter {
                display: flex;
                flex-direction: row;
                align-items: center;
                .picker {
                    margin: 0;

                    &.right {
                        margin-left: 1.2em !important;
                    }
                }
            }

            .type_filter {
                text-align: left;

                .input_select {
                    font-size: 0.8em;
                }
            }

            .select_field {
                width: 100%;
                margin: 0;

                label {
                    background-color: #fff;
                }
                div {
                    text-align: left;
                    width: 100%;
                }
            }

            .filterButton {
                margin-top: 1em;
            }
        }

        button {
            opacity: 1;
        }
    }
}
